<template>
  <footer class="footer-cug-movil">
    <div class="footer-cug-movil--inner">
      <nav class="footer-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'AboutCug' }">{{
              $t("message.menu.items[0]")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ContactCug' }">{{
              $t("message.menu.items[3]")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ArchiveCug' }">{{
              $t("message.menu.items[1]")
            }}</router-link>
          </li>
        </ul>
      </nav>
      <div class="footer-sponsors">
        <a target="_blank" href="https://www.gva.es/">
          <img
            src="@/assets/images/logo-generalitat-valenciana.svg"
            alt="Logo Generalitat Valenciana"
          />
        </a>
        <a target="_blank" href="https://www.upv.es/">
          <img src="@/assets/images/logo-upv.svg" alt="logo Upv" />
        </a>
      </div>
      <div class="footer-bottom">
        <div class="footer-copyright">
          <span>Cátedra de urbanisme i gènere DPA UPV</span>
          <span
            >Proudly baked by
            <a target="_blank" href="https://contra-architecture.com/">CONTRA</a
            >, developed by
            <a target="_blank" href="https://mdtec.es/">MDTEC</a></span
          >
          <span>©2022. All Rights Reserved</span>
        </div>
        <div class="footer-socials">
          <a target="_blank" href="https://www.instagram.com/cug_upvgva/">
            <img
              src="@/assets/icons/icon-instagram-white.svg"
              alt="logo instagram"
            />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
          >
            <img
              src="@/assets/icons/icon-youtube-white.svg"
              alt="logo youtube"
            />
          </a>
          <a target="_blank" href="https://cugnews.wordpress.com/">
            <img
              src="@/assets/icons/icon-wordpress-white.svg"
              alt="logo wordpress"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCug",
};
</script>

<style lang="scss" scoped>
.footer-cug-movil {
  background-color: rgb(var(--bodyColor));
  color: rgb(var(--bodyBg));
  padding: calc(var(--base) * 2);
  width: 100%;
  .footer-nav {
    margin-bottom: calc(var(--base) * 2);
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: calc(var(--base) * 2);
      li {
        font-size: calc(var(--base) * 2);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        &:after {
          content: "|";
          color: rgb(var(--bodyBg));
          margin: 0 calc(var(--base) * 2);
          margin-right: 0;
          font-size: calc(var(--base) * 3);
        }
        &:last-child:after {
          display: none;
        }
        a {
          color: rgb(var(--bodyBg));
          text-decoration: none;
          text-transform: uppercase;
          transition: all ease-out 0.3s;
          font-weight: 400;
        }
      }
    }
  }
  .footer-sponsors {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 2);
    a {
      max-width: 40%;
      display: block;
      width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .footer-bottom {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    column-gap: calc(var(--base) * 2);
    .footer-copyright {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // max-width:calc(var(--base) * 36);
      padding-bottom: calc(var(--base) * 0.5);
      font-size: calc(var(--base) * 1.25);
      line-height: 125.7%;
      a {
        color: rgb(var(--bodyBg));
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .footer-socials {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 2);
      align-items: center;
      a {
        display: block;
        width: calc(var(--base) * 3.75);
        height: calc(var(--base) * 3.75);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
