<template>
  <div class="page page-user-panel panel-insights">
    <user-panel-layout active="insights" :rows="0">
      <!-- Inicio de sumario de estadísticas -->
      <section class="panel-module panel--insights--summary" :style="{
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      }">
        <div class="panel-module--header">
          <h4 class="panel-module--header__title">
            {{ $t("message.admin.panels.insights") }}
          </h4>
          <div class="panel-module--header__buttons">
            <!-- vuetify selector to filter by week month or all -->
            <v-select v-model="selectedPeriod" :items="periods" item-text="text" item-value="value"
              prepend-inner-icon="mdi-calendar" placeholder="Filtrar por fecha"
              :menu-props="{ bottom: true, offsetY: true }" outlined dense :hide-details="true"
              class="panel--insights--summary--period-selector"></v-select>

            <!-- vuetify selector to filter by project -->
            <v-select :hide-details="true" v-model="selectedProject" :items="projectsMapped"
              item-text="name" item-value="id" placeholder="Filtrar por Proyecto"
              prepend-inner-icon="mdi-filter-variant" :menu-props="{ bottom: true, offsetY: true }"
              outlined dense></v-select>
          </div>
        </div>
        <div class="panel-module--body" :class="[loading ? 'overflow-hidden' : '']">
          <panel-module-loader :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light" v-if="loading"
            borderRadius="8"></panel-module-loader>
          <div class="insights--container">
            <div class="insights--item insights--visualizations" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            }">
              <h5 class="insights--item__title">
                {{ $t("message.admin.insights.visualizations") }}
              </h5>
              <div class="insights--item__value">{{ totalViewsFiltered }}</div>
            </div>
            <div class="insights--item insights--comments" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            }">
              <h5 class="insights--item__title">
                {{ $t("message.admin.insights.comments") }}
              </h5>
              <div class="insights--item__value">
                {{ totalCommentsFiltered }}
              </div>
            </div>
            <div class="insights--item insights--saved" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            }">
              <h5 class="insights--item__title">
                {{ $t("message.admin.insights.saved") }}
              </h5>
              <div class="insights--item__value">{{ totalStarsFiltered }}</div>
            </div>
            <div class="insights--item insights--likes" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            }">
              <h5 class="insights--item__title">
                {{ $t("message.admin.insights.likes") }}
              </h5>
              <div class="insights--item__value">{{ totalFavoritesFiltered }}</div>
            </div>
          </div>
          <div class="insights--graphs">
            <panel-module-loader
            :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"
            ></panel-module-loader>
            <user-chart ref="projectChart"></user-chart>
          </div>
        </div>
      </section>
      <!-- Fin de sumario de estadísticas -->
    </user-panel-layout>
  </div>
</template>

<script>
import UserPanelLayout from '@/components/Layouts/UserPanelLayout.vue';
import PanelModuleLoader from '@/components/Loaders/PanelModuleLoader.vue';
import UserChart from '@/components/Charts/ProjectChart.vue';
import projectServices from '../../services/project.services';

export default {
  name: "UserPanel-home",
  components: {
    UserPanelLayout,
    PanelModuleLoader,
    UserChart,
  },

  data: () => ({
    active: 'home',
    project: null,
    mapClass: 'map-proj-unselected',

    info: null,
    loading: true,
    errored: false,
    modal: false,

    projectSelectionClass: 'sel-proj-unselected',

    selectedPeriod: 'week',
    periods: [
      { text: 'Últimos 7 días', value: 'week' },
      { text: 'Últimos 15 días', value: 'two-week' },
      { text: 'Último mes', value: 'month' },
    ],
    selectedProject: null,

    //chart
    chartData: {
      labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      datasets: [
        {
          label: 'views',
          borderColor: '#90EA42',
          backgroundColor: '#90EA42',
          data: null,
        },
        {
          label: 'likes',
          borderColor: '#F9E02B',
          backgroundColor: '#F9E02B',
          data: null,
        },
        {
          label: 'stars',
          borderColor: '#A76DF2',
          backgroundColor: '#A76DF2',
          data: null,
        },
        {
          label: 'comments',
          borderColor: '#EF709D',
          backgroundColor: '#EF709D',
          data: null,
        }, 
      ],
    },

  }),
  mounted() {
    this.$store.dispatch('users/getUserProjects');
    this.$store.dispatch('users/getLoggedUser');
  },
  watch: {
    projects: function () {
      this.updateChart();
    },
    selectedPeriod: function () {
      this.updateChart();
    },
    selectedProject: function (val) {
      this.updateChart(val);
    },
  },
  computed: {
    totalViewsFiltered() {
      //sum all values in chartData.dataset[0].data
      if (this.chartData.datasets[0].data != null) {
        let value = this.chartData.datasets[0].data.reduce((acc, value) => {
          return acc + value;
        });
        return value;
      }
      else {
        return 0;
      }
    },
    totalFavoritesFiltered() {
      //sum all values in chartData.dataset[1].data
      if (this.chartData.datasets[1].data != null) {
        return this.chartData.datasets[1].data.reduce((acc, value) => {
          return acc + value;
        });
      }
      else {
        return 0;
      }
    },
    totalStarsFiltered() {
      //sum all values in chartData.dataset[2].data
      if (this.chartData.datasets[2].data != null) {
        return this.chartData.datasets[2].data.reduce((acc, value) => {
          return acc + value;
        });
      }
      else {
        return 0;
      }
    },
    totalCommentsFiltered() {
      //sum all values in chartData.dataset[3].data
      if (this.chartData.datasets[3].data != null) {
        return this.chartData.datasets[3].data.reduce((acc, value) => {
          return acc + value;
        });
      }
      else {
        return 0;
      }
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
    projects() {
      return this.$store.state.users.projects;
    },

    projectsFiltered() {
      if (this.selectedProject) {
        return this.projects.filter((project) => {
          return project.id == this.selectedProject;
        });
      }
      return this.projects;
    },

    projectsMapped() {
      return this.projects.map((project) => {
        return {
          name: this.$root.$i18n.locale == "es" ? project.titleCastellano : project.titleValencia,
          id: project.id,
        };
      });
    },

    selectedColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return 'default';
    },
  },
  methods: {
    async getProjectStats(id, dateStart, dateEnd, index) {
      await projectServices.getProjectStats(id, dateStart, dateEnd)
        .then((res) => {
          //update chart data
          //use $set(vm.items, indexOfItem, newValue) to update array items
          this.$set(this.chartData.datasets[0].data, index, this.chartData.datasets[0].data[index] + res.views);
          this.$set(this.chartData.datasets[1].data, index, this.chartData.datasets[1].data[index] + res.favorites);
          this.$set(this.chartData.datasets[2].data, index, this.chartData.datasets[2].data[index] + res.stars);
          this.$set(this.chartData.datasets[3].data, index, this.chartData.datasets[3].data[index] + res.comments);
        });
    },
    getLastDays(days) {
      let date = new Date();
      let labels = [];
      for (let i = 0; i < days; i++) {
        labels.push(this.getDay(date));
        date.setDate(date.getDate() - 1);
      }
      return labels.reverse();
    },
    getDay(date) {
      //get day in format 2022-12-01
      let day = date.getDate();
      day = day < 10 ? '0' + day : day;
      let month = date.getMonth() + 1;
      if (month < 10) month = '0' + month;

      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },
    getDayTomorrow() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      let day = date.getDate();
      day = day < 10 ? '0' + day : day;
      let month = date.getMonth() + 1;
      if (month < 10) month = '0' + month;
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },
    setLabels() {
      if (this.selectedPeriod === 'week') {
        //get last 7 days and add to labels
        this.chartData.labels = this.getLastDays(7);
      } else if (this.selectedPeriod === 'two-week') {
        //get last 30 days and add to labels
        this.chartData.labels = this.getLastDays(15);
      } else if (this.selectedPeriod === 'month') {
        //get last 365 days and add to labels
        this.chartData.labels = this.getLastDays(30);
      }
      //new array with same length as labels with 0s
      this.chartData.datasets[0].data = Array(this.chartData.labels.length).fill(0);
      this.chartData.datasets[1].data = Array(this.chartData.labels.length).fill(0);
      this.chartData.datasets[2].data = Array(this.chartData.labels.length).fill(0);
      this.chartData.datasets[3].data = Array(this.chartData.labels.length).fill(0);
    },
    getStats() {
      this.loading = true;
      this.setLabels();
      // create a promise that resolves when all calls to getProjectStats() are complete
      const promise = new Promise((resolve) => {
        if (this.projectsFiltered.length === 0) {
          resolve();
        }
        let completed = 0;
        // foreach project, get stats
        this.projectsFiltered.forEach((project) => {
          //for every element in chartData.labels, get stats
          for (let i = 0; i < this.chartData.labels.length; i++) {
            //get stats for each day
            if (i === this.chartData.labels.length - 1) {
              //if last day, get stats from day to tomorrow
              this.getProjectStats(project.id, this.chartData.labels[i], this.getDayTomorrow(), i).then(() => {
                completed++;
                // if all calls to getProjectStats() are complete, resolve the promise
                if (completed === this.projectsFiltered.length * this.chartData.labels.length) {
                  resolve();
                }
              });
              break;
            }
            this.getProjectStats(project.id, this.chartData.labels[i], this.chartData.labels[i + 1], i).then(() => {
              completed++;
              // if all calls to getProjectStats() are complete, resolve the promise
              if (completed === this.projectsFiltered.length * this.chartData.labels.length) {
                resolve();
              }
            });
          }

        });
      });
      // when the promise is resolved, update the chart with the new values
      promise.then(() => {
        this.loading = false;
        this.$refs.projectChart.updateValues(this.chartData);
      });
    },

    async updateChart() {
      //when this.getStats is finished, update map
      await this.getStats();
    },
    getProjectUrl(id) {
      return `/project/${id}`;
    },

  },
};


</script>

<style lang="scss" scoped>
/* Single Panels */

//Global rules
.panel-module {
  border-radius: calc(var(--base) * 2);
  display: flex;
  flex-direction: column;
  padding: calc(var(--base) * 2);
  transition: all ease-out 0.2s;

  .panel-module--header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: calc(var(--base) * 0.125);

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    // margin-bottom: calc(var(--base) * 2.625);
    &__title {
      font-size: calc(var(--base) * 2.625);
      line-height: 1;
      font-weight: 600;
      max-width: 90%;
      margin-bottom: calc(var(--base) * 2);

      @media (min-width: 1024px) {
        margin-bottom: 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      gap: calc(var(--base) * 2);
      margin-bottom: calc(var(--base) * 2);
      min-height: calc(var(--base) * 4);

    }
  }

  .panel-module--body {
    width: 100%;
    height: 100%;
    // background-color: white;
    // border-radius: calc(var(--base) * 1);
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    position: relative;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

//Sumario Estadísticas
.panel--insights--summary {
  width: 100%;

  @media (min-width: 1024px) {
    grid-column: 1 / span 10;
    grid-row: span 6;
  }

  .insights--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      display: flex;
      flex-wrap: nowrap;
    }

    .insights--item {
      border-radius: calc(var(--base) * 2);
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: calc(var(--base) * 2);
      padding-top: calc(var(--base) * 2);
      padding-bottom: calc(var(--base) * 1);
      height: 100%;
      min-height: calc(var(--base) * 13.75);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      grid-column: span 1;

      @media (min-width: 1024px) {
        width: 20%;
        min-height: calc(var(--base) * 19.5);
      }

      &__title {
        font-size: calc(var(--base) * 2);
        line-height: 1;
        font-weight: 500;
        margin: 0;

        @media (min-width: 1024px) {
          font-size: calc(var(--base) * 2.625);
        }
      }

      &__value {
        font-size: calc(var(--base) * 6);
        line-height: calc(var(--base) * 7);
        font-weight: 500;
        margin: 0;

        @media (min-width: 1024px) {
          font-size: calc(var(--base) * 8);
          line-height: calc(var(--base) * 8.75);
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        width: 45%;

        @media (min-width: 1024px) {
          width: 33%;
        }
      }
    }
  }

  .insights--graphs {
    background-color: rgb(var(--bodyBg));
    border-radius: calc(var(--base) * 2);
    height: 100%;
    padding: calc(var(--base) * 3) calc(var(--base) * 2);
  }
}
</style>