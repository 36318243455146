<template>
  <div class="form-step--wrapper">
    <div class="form--content form-creator--step-3--content"
      :class="[isCompany ? 'columns' : 'rows']">
      <div class="form--column">
        <!-- V-input text for full name -->
        <v-text-field v-model="user.name" :label="$t('message.forms.nameAndSurname')"
          :placeholder="$t('message.forms.nameAndSurname')"  required outlined
          :dense="this.$vuetify.breakpoint.width < 1024"></v-text-field>
        <!-- V-input text for company -->
        <v-text-field v-model="user.companyName" :label="$t('message.forms.companyName')"
          :placeholder="$t('message.forms.companyName')"  required outlined
          :dense="this.$vuetify.breakpoint.width < 1024" color="primary"
          v-if="isCompany"></v-text-field>
      </div>
      <div class="form--column">
        <!-- V-selector for pronouns -->
        <v-select v-model="user.pronouns" :items="pronounsItems"
          :label="$t('message.forms.pronouns')" :placeholder="$t('message.forms.pronouns')"
           required outlined
          :dense="this.$vuetify.breakpoint.width < 1024"></v-select>
        <!-- V-input text for email -->
        <v-text-field v-model="user.email" :label="$t('message.forms.email')"
          :placeholder="$t('message.forms.email')"
          :rules="[$v.user.email.required, $v.user.email.email]" required outlined
          :dense="this.$vuetify.breakpoint.width < 1024"></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: "FormCreatorStep3",
  mixins: [validationMixin],
  components: {

  },
  props: {
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.updateData();
  },
  data() {
    return {
      pronounsItems: [
                { text: this.$t('message.forms.pronounHer'), value: "she/her" },
                { text: this.$t('message.forms.pronounHim'), value: "he/him" },
                { text: this.$t('message.forms.pronounThey'), value: "they/them" },
            ],
      user: {
        name: null,
        companyName: null,
        pronouns: null,
        email: null,
      },

    };
  },
  validations() {
    const valObj = {
      user: {
        name: { required },
        email: { required, email },
        pronouns: { required },
      },
    };
    return valObj;
  },
  watch: {
    user: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
  },
  methods: {
    updateData() {
      this.$emit('updateData', this.user);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3149287169);
  }

  .form--content {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3.835978836vw;
    padding: 0 9.126984127vw;
    height: 100%;

    &.columns,
    &.rows {
      flex-direction: column;
    }

    @media (min-width: 1024px) {
      &.columns {
        flex-direction: row;
      }

      &.rows {
        flex-direction: column;
      }
    }

    .form--column {
      width: 100%;

      @media (min-width: 1024px) {
        width: 50%;
      }
    }
  }
}
</style>