<template>
  <button
    class="notification-card"
    :class="[isRead ? 'read' : '']"
    @click="navigateTo(projectId)"
    :style="[
      isRead
        ? {
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).medium + ')',
          }
        : {
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).light + ')',
          },
    ]"
  >
    <div class="notification-card--inner">
      <div class="notification-card--avatar" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.color).dark + ')',
            }">
        <img :src="avatar" alt="" v-if="avatar" />
        <span v-else>{{ getInitials() }}</span>
      </div>
      <div class="notification-card--info">
        <div class="notification-card--info--text" v-if="type === 'favorite'">
          {{ $t("message.admin.notifications.liked.prepend") }}
          <span class="user">{{ user }}</span>
          {{ $t("message.admin.notifications.liked.append") }}
          <span class="project"
            >&#x22;{{
              this.$root.$i18n.locale == "es"
                ? this.trimString(this.projectNameEs, 20)
                : this.trimString(this.projectNameCat, 20)
            }}&#x22;</span
          >
        </div>
        <div class="notification-card--info--text" v-if="type === 'comment'">
          <span class="user">{{ user }}</span>
          {{ $t("message.admin.notifications.commented") }}
          <span class="project"
            >&#x22;{{
              this.$root.$i18n.locale == "es"
                ? this.trimString(this.projectNameEs, 20)
                : this.trimString(this.projectNameCat, 20)
            }}&#x22;</span
          >
        </div>
        <div class="notification-card--info--text" v-if="type === 'chat'">
          <span class="user">{{ user }}</span>
          {{ $t("message.admin.notifications.saved") }}
          <span class="project"
            >&#x22;{{
              this.$root.$i18n.locale == "es"
                ? this.trimString(this.projectNameEs, 20)
                : this.trimString(this.projectNameCat, 20)
            }}&#x22;</span
          >
        </div>
        <div class="notification-card--info--text" v-if="type === 'approved'">
          <span class="user">{{ user }}</span>
          {{ $t("message.admin.notifications.approved") }}
          <span class="project"
            >&#x22;{{
              this.$root.$i18n.locale == "es"
                ? this.trimString(this.projectNameEs, 20)
                : this.trimString(this.projectNameCat, 20)
            }}&#x22;</span
          >
        </div>
        <div class="notification-card--info--text" v-if="type === 'rejected'">
          <span class="user">{{ user }}</span>
          {{ $t("message.admin.notifications.rejected") }}
          <span class="project"
            >&#x22;{{
              this.$root.$i18n.locale == "es"
                ? this.trimString(this.projectNameEs, 20)
                : this.trimString(this.projectNameCat, 20)
            }}&#x22;</span
          >
        </div>
        <div class="notification-card--timestamp">
          {{ timeAgo(timestamp) }}
        </div>
      </div>

      <div class="notification-card--icon">
        <img
          src="@/assets/icons/icon-notification-saved.svg"
          alt=""
          v-if="type === 'chat'"
        />
        <img
          src="@/assets/icons/icon-notification-liked.svg"
          alt=""
          v-if="type === 'favorite'"
        />
        <img
          src="@/assets/icons/icon-notification-commented.svg"
          alt=""
          v-if="type === 'comment'"
        />
        <img
          src="@/assets/icons/icon-notification-approved.svg"
          alt=""
          v-if="type === 'approved'"
        />
        <img
          src="@/assets/icons/icon-notification-rejected.svg"
          alt=""
          v-if="type === 'rejected'"
        />
      </div>
    </div>
  </button>
</template>

<script>
import projectServices from '@/services/project.services';
import userServices from '@/services/user.service';

export default {
    name: 'NotificationCard',
    props: {
        type: {
        type: String,
        required: true,
        },
        user: {
        type: String,
        required: true,
        },
        avatar:{
        type: String,
        required: false,
        },
        projectNameEs:{
        type: String,
        required: true,
        },
        projectNameCat:{
        type: String,
        required: true,
        },
        id:{
        type: Number,
        required: true,
        },
        projectId:{
        type: Number,
        required: true,
        },
        timestamp: {
        type: Number,
        required: true,
        },
        read: {
        type: Boolean,
        required: true,
        },
        color:{
        type: String,
        required: true,
        },
        
    },
    data() {
        return {
            isRead: false,
        };
    },
    mounted() {
        this.isRead = this.read;
    },
    methods: {
        timeAgo(timestamp) {
            let date = new Date(timestamp);
            let seconds = Math.floor((new Date() - date) / 1000);
            let interval = Math.floor(seconds / 31536000);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.years');
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.months');
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.days');
            }
            interval = Math.floor(seconds / 3600);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.hours');
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.minutes');
            }
            return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.seconds');
        },
         // a method that returns the initials of the user prop
         getInitials() {
            let initials = this.user.match(/\b\w/g) || [];
            initials = (
                (initials.shift() || "") + (initials.pop() || "")
            ).toUpperCase();
            return initials;
        },
        // a method that trims the string to a certain length
        trimString(string, length) {
            return string.length > length
            ? string.substring(0, length - 3) + "..."
            : string;
        },
        navigateTo(id) {
          this.isRead = true;
          userServices.readNotification(this.id);
          this.$router.push({ name: 'ProjectSingle', params: { id: id, slug: projectServices.getSlugFromTitle(this.projectNameCat)  } });
        },
    },
    };


</script>

<style lang="scss" scoped>
.notification-card {
  position: relative;
  width: 100%;
  background: red;
  border-radius: calc(var(--base) * 1);
  min-height: calc(var(--base) * 12);
  padding: calc(var(--base) * 1.375);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  .notification-card--inner {
    display: flex;
    flex-wrap: nowrap;
    gap: calc(var(--base) * 2);
    width: 100%;
    height: 100%;
    .notification-card--avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--base) * 100);
      width: calc(var(--base) * 3);
      height: calc(var(--base) * 3);
      min-width: calc(var(--base) * 3);
      min-height: calc(var(--base) * 3);
      max-width: calc(var(--base) * 3);
      max-height: calc(var(--base) * 3);
      background: orange;
      font-size: calc(var(--base) * 1.375);
      line-height: 1;
      text-align: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .notification-card--info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: calc(var(--base) * 1);
      & .notification-card--info--text {
        font-size: calc(var(--base) * 2);
        line-height: calc(var(--base) * 3);
        color: rgba(var(--bodyColor), 1);
        font-weight: 300;
        text-align: left;
        span {
          font-weight: 600;
        }
      }
    }
    & .notification-card--timestamp {
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 2);
      color: rgba(var(--bodyColor), 0.6);
      font-weight: 300;
      text-align: left;
    }
    .notification-card--icon {
      position: absolute;
      bottom: calc(var(--base) * 1);
      left: calc(var(--base) * 1.375);
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  &.read .notification-card--icon {
    opacity: 0.4;
  }
}
</style>