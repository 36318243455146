var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-collapsable-card",class:[_vm.isCollapsed ? '' : 'open']},[_c('div',{staticClass:"user-collapsable-card--inner",style:({
      backgroundColor: 'rgb(' + this.$getUserColor(this.color).light + ')',
    })},[_c('a',{staticClass:"user-collapsable-card--label",attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleCollapsable()}}},[_c('div',{staticClass:"user-collapsable-card--avatar",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).dark + ')',
          })},[(_vm.avatar)?_c('img',{attrs:{"src":_vm.avatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.user)))])]),_c('div',{staticClass:"user-collapsable-card--name"},[_vm._v(" "+_vm._s(_vm.trimString(_vm.user, 40))+" ")]),_c('div',{staticClass:"user-collapsable-card--icon",class:[_vm.isCollapsed ? '' : 'open']},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-down.svg"),"alt":""}})])]),_c('div',{staticClass:"user-collapsable-card--content",class:[_vm.isCollapsed ? '' : 'open'],style:({
        backgroundColor: 'rgb(' + this.$getUserColor(this.color).medium + ')',
      })},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }