<template>
  <a
    href="#"
    class="new-conversation-card"
    :style="{
      backgroundColor: 'rgb(' + this.$getUserColor(this.color).light + ')',
    }"
    @click="toggleConversation()"
  >
    <div class="new-conversation-card--inner">
      <div
        class="new-conversation-card--inner__avatar"
        :style="{
          backgroundColor: 'rgb(' + this.$getUserColor(this.color).dark + ')',
        }"
      >
        <img :src="avatar" alt="" v-if="avatar" />
        <span v-else>{{ getInitials(user) }}</span>
      </div>
      <div class="new-conversation-card--inner__name">{{ user }}</div>
      <div class="new-conversation-card--inner__checkbox">
        <v-checkbox
          v-model="isSelected"
          color="black"
          hide-details
          class="new-conversation-card--inner__checkbox__input"
        ></v-checkbox>
      </div>
    </div>
  </a>
</template>

<script>

export default {
    props: {
        user: {
            type: String,
            required: true,
        },
        avatar: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    methods: {
        getInitials(value) {
            if (value) {
                return value.match(/\b(\w)/g).join('');
            } else {
                return 'XX';
            }
        },
        toggleConversation() {
            this.isSelected = !this.isSelected;
            this.$emit('toggle', this.id);
        },
    },

}
</script>

<style lang="scss" scoped>
.new-conversation-card {
  width: 100%;
  height: calc(var(--base) * 8.5);
  & .new-conversation-card--inner {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--base) * 1.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 1.375);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    &__avatar {
      width: calc(var(--base) * 6.125);
      height: calc(var(--base) * 6.125);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      margin-right: auto;
      font-size: calc(var(--base) * 2);
      font-weight: 500;
    }
  }
}
</style>
<style>
.v-input.new-conversation-card--inner__checkbox__input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 0;
}
</style>