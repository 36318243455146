var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page-user-panel panel-insights"},[_c('user-panel-layout',{attrs:{"active":"insights","rows":0}},[_c('section',{staticClass:"panel-module panel--insights--summary",style:({
      backgroundColor:
        'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
    })},[_c('div',{staticClass:"panel-module--header"},[_c('h4',{staticClass:"panel-module--header__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.insights"))+" ")]),_c('div',{staticClass:"panel-module--header__buttons"},[_c('v-select',{staticClass:"panel--insights--summary--period-selector",attrs:{"items":_vm.periods,"item-text":"text","item-value":"value","prepend-inner-icon":"mdi-calendar","placeholder":"Filtrar por fecha","menu-props":{ bottom: true, offsetY: true },"outlined":"","dense":"","hide-details":true},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}}),_c('v-select',{attrs:{"hide-details":true,"items":_vm.projectsMapped,"item-text":"name","item-value":"id","placeholder":"Filtrar por Proyecto","prepend-inner-icon":"mdi-filter-variant","menu-props":{ bottom: true, offsetY: true },"outlined":"","dense":""},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}})],1)]),_c('div',{staticClass:"panel-module--body",class:[_vm.loading ? 'overflow-hidden' : '']},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light,"borderRadius":"8"}}):_vm._e(),_c('div',{staticClass:"insights--container"},[_c('div',{staticClass:"insights--item insights--visualizations",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          })},[_c('h5',{staticClass:"insights--item__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.insights.visualizations"))+" ")]),_c('div',{staticClass:"insights--item__value"},[_vm._v(_vm._s(_vm.totalViewsFiltered))])]),_c('div',{staticClass:"insights--item insights--comments",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          })},[_c('h5',{staticClass:"insights--item__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.insights.comments"))+" ")]),_c('div',{staticClass:"insights--item__value"},[_vm._v(" "+_vm._s(_vm.totalCommentsFiltered)+" ")])]),_c('div',{staticClass:"insights--item insights--saved",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          })},[_c('h5',{staticClass:"insights--item__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.insights.saved"))+" ")]),_c('div',{staticClass:"insights--item__value"},[_vm._v(_vm._s(_vm.totalStarsFiltered))])]),_c('div',{staticClass:"insights--item insights--likes",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          })},[_c('h5',{staticClass:"insights--item__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.insights.likes"))+" ")]),_c('div',{staticClass:"insights--item__value"},[_vm._v(_vm._s(_vm.totalFavoritesFiltered))])])]),_c('div',{staticClass:"insights--graphs"},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light}}):_vm._e(),_c('user-chart',{ref:"projectChart"})],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }