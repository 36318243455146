<template>
  <div class="page page-upload">
    <section class="form-step--container">
      <div class="form-titles">
        <h2 class="form-step--title" v-if="activeStep < maxSteps">
          {{ $t(`message.forms.questions.editAdmin.titles.${activeStep}`) }}
        </h2>

        <h2 class="form-step--title" v-else>
          <template v-if="isApproved">
            {{
              $t(
                `message.forms.questions.editAdmin.titles.${activeStep}.approved`
              )
            }}
          </template>
          <template v-else-if="isDenied">
            {{
              $t(
                `message.forms.questions.editAdmin.titles.${activeStep}.denied`
              )
            }}
          </template>
          <template v-else>
            {{
              $t(
                `message.forms.questions.editAdmin.titles.${activeStep}.pending`
              )
            }}
          </template>
        </h2>
        <h3
          class="form-step--subtitle"
          v-if="this.$vuetify.breakpoint.width >= 1024"
        >
          {{ $t(`message.forms.questions.editAdmin.subtitles.${activeStep}`) }}
        </h3>
      </div>
      <v-form>
        <form-edit-step-1
          v-show="activeStep === 1"
          @toggleModal="toggleModal()"
          @updateData="updateStep1"
          ref="step1"
        >
        </form-edit-step-1>
        <form-edit-step-images v-show="activeStep === 2" ref="stepImages">
        </form-edit-step-images>
        <form-edit-step-2
          v-show="activeStep === 3"
          @updateData="updateStep2"
          ref="step2"
        ></form-edit-step-2>
        <form-edit-step-3
          v-show="activeStep === 4"
          @updateData="updateStep3"
          ref="step3"
        ></form-edit-step-3>
        <form-edit-admin-step-4
          v-show="activeStep === 5"
          @updateData="updateStep4"
          ref="step4"
        ></form-edit-admin-step-4>
        <form-edit-admin-step-5-approved
          v-show="activeStep === 6 && isApproved"
        ></form-edit-admin-step-5-approved>
        <form-edit-admin-step-5-denied
          v-show="activeStep === 6 && isDenied"
        ></form-edit-admin-step-5-denied>
        <form-edit-admin-step-5-pending
          v-show="activeStep === 6 && !isApproved && !isDenied"
        ></form-edit-admin-step-5-pending>
      </v-form>

      <div class="form-nav-buttons" v-if="activeStep < maxSteps">
        <button
          class="form-nav-buttons--button"
          :disabled="!isPreviousButtonEnabled"
          @click="previousStep()"
          :class="[!isPreviousButtonEnabled ? 'disabled' : '']"
        >
          {{ $t("message.forms.previous") }}
        </button>
        |
        <button
          class="form-nav-buttons--button"
          :disabled="!isNextButtonEnabled"
          @click="nextStep()"
          :class="[!isNextButtonEnabled ? 'disabled' : '']"
        >
          {{ $t("message.forms.next") }}
        </button>
      </div>
    </section>
    <div class="coordinates-modal" v-if="coordinatesModal">
      <div class="coordinates-modal--inner">
        <div class="coordinates-modal--header">
          <div class="modal--header--title">
            {{ $t("message.forms.coordinatesHelpQuestion") }}
          </div>
          <button class="modal--header--close" @click="toggleModal()">
            <a href="javascript:;">
              <img src="@/assets/icons/icon-close-white.svg" alt="Tancar" />
            </a>
          </button>
        </div>
        <div class="coordinates-modal--body">
          <div class="modal--body--item">
            <div class="modal--body--item__icon">
              <img src="@/assets/icons/icon-help-coordinates-1.svg" alt="" />
            </div>
            <div class="modal--body--item__text">
              {{ $t("message.forms.coordinatesHelpText1") }}
            </div>
          </div>
          <div class="modal--body--item">
            <div class="modal--body--item__icon">
              <img src="@/assets/icons/icon-help-coordinates-2.svg" alt="" />
            </div>
            <div class="modal--body--item__text">
              {{ $t("message.forms.coordinatesHelpText2") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectService from "@/services/project.services.js";
import FormEditStep1 from "@/components/Forms/Edit/FormEditStep1.vue";
import FormEditStep2 from "@/components/Forms/Edit/FormEditStep2.vue";
import FormEditStepImages from "@/components/Forms/Edit/FormEditStepImages.vue";
import FormEditStep3 from "@/components/Forms/Edit/FormEditStep3.vue";
import FormEditAdminStep4 from "@/components/Forms/EditAdmin/FormEditAdminStep4.vue";
import FormEditAdminStep5Approved from "@/components/Forms/EditAdmin/FormEditAdminStep5Approved.vue";
import FormEditAdminStep5Denied from "@/components/Forms/EditAdmin/FormEditAdminStep5Denied.vue";

export default {
  name: "RegisterPage",
  components: {
    FormEditStep1,
    FormEditStep2,
    FormEditStep3,
    FormEditAdminStep4,
    FormEditStepImages,
    FormEditAdminStep5Approved,
    FormEditAdminStep5Denied,
  },
  methods: {
    previousStep() {
      if (this.activeStep > 1) {
        this.activeStep -= 1;
      } else if (this.activeStep === 1) {
        history.back();
      } else {
        this.activeStep = 1;
      }
    },
    nextStep() {
      if (this.activeStep === 4) {
        this.updateProject();
      }
      if (this.activeStep >= this.maxSteps) {
        this.activeStep = 1;
      } else {
        this.activeStep += 1;
      }
    },
    toggleModal() {
      this.coordinatesModal = !this.coordinatesModal;
    },
    updateStep1(data) {
      //update project data
      this.project.titleCastellano = data.titleCastellano;
      this.project.titleValencia = data.titleValencia;
      this.project.year = data.year;
      this.project.type = data.type;
      this.project.currentStatus = data.currentStatus;
      this.project.authors = data.authors;
      this.project.descriptionShortCastellano = data.descriptionShortCastellano;
      this.project.descriptionShortValencia = data.descriptionShortValencia;
      this.project.latitude = data.latitude;
      this.project.longitude = data.longitude;
    },

    updateStep2(data) {
      //update project data
      this.project.edition = data.edition;
      this.project.prize = data.prize;
    },
    updateStep3(data, images) {
      //update project data
      this.project.descriptionLongCastellano = data.descriptionLongCastellano;
      this.project.descriptionLongValencia = data.descriptionLongValencia;
      this.project.projectLinks = data.projectLinks;
      this.projectImages = images;
    },
    updateStep4(data) {
      this.isApproved = data.published;
      this.isDenied = data.denied; // Añadir esta línea para manejar el estado denegado
      this.nextStep();
    },
    updateProject() {
      // Crear una copia del proyecto
      let project = Object.assign({}, this.project);
      // Eliminar las imágenes del proyecto
      delete project.projectImages;
      delete project.projectMainImages;

      if (this.isDenied) {
        // Llamar al servicio para actualizar el estado a denegado
        projectService.updateDenied(this.$route.params.id).then(
          (response) => {
            console.log("Proyecto denegado:", response.id);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        // Llamar al servicio para actualizar el proyecto
        projectService.updateProject(project, this.$route.params.id).then(
          (response) => {
            console.log("Proyecto actualizado:", response.id);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },

    getProject() {
      projectService
        .getAdminProject(this.$route.params.id)
        .then((response) => {
          this.project = response;
          this.$refs.step1.updateProject(this.project);
          this.$refs.stepImages.updateProject(this.project);
          this.$refs.step2.updateProject(this.project);
          this.$refs.step3.updateProject(this.project);
          this.$refs.step4.updatePublished(this.project.published);
          this.isApproved = this.project.published;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      activeStep: 1,
      isPreviousButtonEnabled: true,
      isNextButtonEnabled: true,
      maxSteps: 6,
      coordinatesModal: false,

      //create project
      project: {
        category: null,
        edition: null,
        latitude: null,
        longitude: null,
        prize: null,
        titleCastellano: null,
        titleValencia: null,
        type: null,
        year: null,
        projectLinks: null,
        placeName: null,
        descriptionShortCastellano: null,
        descriptionShortValencia: null,
        currentStatus: null,
        descriptionLongCastellano: null,
        descriptionLongValencia: null,
      },
      //project images
      projectMainImages: null,
      projectImages: null,
      isApproved: false,
      isDenied: false, // Añadir esta propiedad para manejar el estado denegado
    };
  },
  mounted() {
    this.maxSteps = 6;
    this.getProject();
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
}

.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.05);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;

  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.15);
  }

  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;
    pointer-events: all;
    cursor: pointer;
    opacity: 1;

    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}

.form-titles {
  position: absolute;
  top: calc(var(--app-height) * 0.15);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2057841141);
  }

  .form-step--title {
    font-size: calc(var(--base) * 3);
    font-weight: 700;
    text-align: center;
    padding: 0 calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 0.5);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 4);
    }
  }

  .form-step--subtitle {
    width: 100%;
    font-size: calc(var(--base) * 1.375);
    font-weight: 400;
    text-align: center;
    padding: 0 calc(var(--base) * 2);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 1.875);
    }
  }
}

.coordinates-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
  width: 100vw;
  height: var(--app-height);
  background-color: rgba(var(--bodyColor), 0.37);
  display: flex;
  justify-content: center;
  align-items: center;

  .coordinates-modal--inner {
    width: 81.06666667vw;
    height: auto;
    background: rgb(var(--bodyBg));
    border: solid 1px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 3.75);
    padding: calc(var(--base) * 2.625) calc(var(--base) * 2.625);
    padding-bottom: calc(var(--base) * 6);
    padding-right: calc(var(--base) * 2.625);

    @media (min-width: 1024px) {
      width: 30.15873016vw;
      height: auto;
      background: rgb(var(--bodyBg));
      border: solid 1px rgb(var(--bodyColor));
      border-radius: calc(var(--base) * 3.75);
      padding: calc(var(--base) * 3.75) calc(var(--base) * 4.75);
      padding-bottom: calc(var(--base) * 6);
      padding-right: calc(var(--base) * 3.125);
    }

    .coordinates-modal--header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: calc(var(--base) * 4);

      & .modal--header--title {
        font-size: calc(var(--base) * 3.5);
        line-height: calc(var(--base) * 3.75);
        font-weight: 700;
        color: rgb(var(--bodyColor));
        text-decoration: underline;
        max-width: 90%;
      }

      & .modal--header--close {
        background: rgb(var(--bodyColor));
        width: calc(var(--base) * 3.25);
        height: calc(var(--base) * 3.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--base) * 0.625);

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: calc(var(--base) * 0.875);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .coordinates-modal--body {
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 5.625);
      padding-right: calc(var(--base) * 2);

      .modal--body--item {
        display: flex;
        flex-wrap: nowrap;
        gap: calc(var(--base) * 3.5);

        &__icon {
          width: calc(var(--base) * 8);
          height: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}
</style>
