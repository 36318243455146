<template>
  <div class="form-step--wrapper form-upload-edit--step-2">
    <div class="form--content form-upload-edit--step-2--content">
      <v-radio-group v-model="isRecognized" row>
        <v-radio :label="$t('message.forms.yes')" :value="true" color="primary"
          class="form--radio"></v-radio>
        <v-radio :label="$t('message.forms.no')" :value="false" color="primary"
          class="form--radio"></v-radio>
      </v-radio-group>
      <div class="form--grid" v-if="isRecognized">
        <!-- vuetify yes/no input -->

        <!-- vuetify selector for type of edition -->
        <v-select :menu-props="{ bottom: true, offsetY: true }" v-model="project.edition"
          :items="editionOptions" :label="$t('message.forms.edition')"
          :placeholder="$t('message.forms.edition')" outlined dense></v-select>
        <v-select :menu-props="{ bottom: true, offsetY: true }" v-model="project.prize"
          :items="recognitionOptions" :label="$t('message.forms.recognition')"
          :placeholder="$t('message.forms.recognition')" outlined dense></v-select>
        <!-- vuetify text-field for url -->
        <v-text-field v-model="project.projectLinks[0].url"
          :label="$t('message.forms.recognitionLink')"
          :placeholder="$t('message.forms.recognitionLink')" outlined dense></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      edition: null,
      recognition: null,
      url: null,
      recognitionOptions: [
        { text: 'Premio', value: 'Premio' },
        { text: 'Mención', value: 'Mención' },
      ],
      isRecognized: false,
      editionOptions: [
        { text: 'I', value: 'I' },
        { text: 'II', value: 'II' },
        { text: 'III', value: 'III' },
        { text: 'IV', value: 'IV' },
        { text: 'V', value: 'V' },
        { text: 'VI', value: 'VI' },
        { text: 'VII', value: 'VII' },
        { text: 'VIII', value: 'VIII' },
        { text: 'IX', value: 'IX' },
        { text: 'X', value: 'X' },
        { text: 'XI', value: 'XI' },
        { text: 'XII', value: 'XII' },
        { text: 'XIII', value: 'XIII' },
        { text: 'XIV', value: 'XIV' },
        { text: 'XV', value: 'XV' },
        { text: 'XVI', value: 'XVI' },
        { text: 'XVII', value: 'XVII' },
        { text: 'XVIII', value: 'XVIII' },
        { text: 'XIX', value: 'XIX' },
        { text: 'XX', value: 'XX' },
      ],
      project: {
        edition: null,
        prize: null,
        projectLinks: [
          {
            url: null,
            title: null
          }
        ],
      }

    }
  },
  watch: {
    project: {
      handler: function () {
        this.updateData();
      },
      deep: true,
    },
  },
  methods: {
    updateData() {
      this.$emit("updateData", this.project);
    },
    updateProject(project) {
      this.project = project;
      if(this.project.edition || this.project.prize) {
        this.isRecognized = true;
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    .form--grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        column-gap: 3.835978836vw;
      }
    }
  }
}

.v-input {
  width: 100%;
  flex: none;

  @media (min-width: 1024px) {
    width: calc(33% - 3.835978836vw / 2);
  }
}
</style>
<style>
.v-input--radio-group--row .v-input--radio-group__input {
  justify-content: center;
}
</style>