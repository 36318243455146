/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

const swatches = [
  ['#90EA42'],
  ['#EF709D'],
  ['#5DC9DB'],
  ['#F9E02B'],
  ['#A76DF2'],
];

class UserService {
  getLoggedUser() {
    return axios
      .get(`${API_URL}user-profile`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getAllNotifications() {
    return axios
      .get(`${API_URL}notifications/all`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  readNotification(id) {
    return axios
      .post(`${API_URL}notifications/read/${id}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getUnreadNotification() {
    return axios
      .get(`${API_URL}notifications/unread`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  userRegister(user) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}user-profile/new-register`,
        {
          name: user.name,
          email: user.email,
          job: user.job,
          companyName: user.companyName,
          inscriptionType: user.type, 
          pronoun: user.pronouns,
          province: user.province,
          city: user.city,
          color: user.type === 1 ? 'green' : 'yellow',       
        },
      )
      .then((response) => response.data)
      .catch((error) =>  error );
  }

  validateUser(password,cod,id) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}users`,
        {
          password: password,
          validationCode: cod,
          userProfileId: id,
        },
      )
      .then((response) => response)
      .catch((error) =>  error );
  }

  validateUserResetPassword(password,cod) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}recover-password-confirm`,
        {
          password: password,
          validationCode: cod,
        },
      )
      .then((response) => response)
      .catch((error) =>  error );
  }


  newContactMessage(contact) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}contact/message`,
        {
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          subject: contact.subject,
          message: contact.message,
        },
      )
      .then((response) => response.data)
      .catch((error) =>  error );
  }

  // User color

  getSwatches() {
    return swatches;
  }

  getStringFromColor(color) {
    // switch case for colors
    switch (color) {
      case '#90EA42' : return 'green';
      case '#EF709D' : return 'pink';
      case '#5DC9DB' : return 'blue';
      case '#F9E02B' : return 'yellow';
      case '#A76DF2' : return 'purple';
      default: return 'green';
    }
  }

  updateUserColor(color) {
    let colorString = this.getStringFromColor(color);
    return axios
      .patch(
        `${process.env.VUE_APP_API_URL}user-profile/update-color/${colorString}`, {},
        {
          headers: authHeader(),
        },
      )
      .then((response) => response.data)
      .catch((error) =>  error );
  }

  
}

export default new UserService();
