// add constant with color properties
const Options = {
  projectCategory: {
    SOSTENIBILIDAD: {
      id: 1,
      number: "1",
      key: "SOSTENIBILIDAD",
      name: "Sostenibilidad y cambio climático",
      nameCat: "Sostenibilitat i canvi climàtic",
      description:
        "El cuidado del medio ambiente es una <b>condición indispensable</b> para mantener un estado de salud adecuado de manera colectiva. La sobreexplotación y agotamiento de recursos medioambientales produce efectos físicos constatables. Esta modificación del ecosistema global es percibido y sufrido de manera diferente por cada una de las personas afectadas, empeorando las situaciones de precariedad o vulnerabilidad preexistentes, y en ese sentido el cambio climático tiene <b>consecuencias espacialmente desfavorables</b> en mujeres y niñas y niños.",
      color: "144, 234, 66",
      colorLight: "202, 248, 162",
      hexColor: "#90EA42",
      icon: {
        black: "./images/icons/cat-icon-1-black.png",
        white: "./images/icons/cat-icon-1-white.png",
        color: "./images/icons/cat-icon-1-color.png",
      },
    },
    INSTALACIONES: {
      id: 2,
      number: "2",
      key: "INSTALACIONES",
      name: "Equipamientos e infraestructuras",
      nameCat: "Equipaments i infraestructures",
      description:
        "Entendiendo la noción de infraestructuras ensambladas, no como nueva infraestructura sino como una mirada de <b>análisis de género sobre las infraestructuras ya existentes.</b> Se persigue no solo desarrollar o incorporar aquellas infraestructuras que se vinculan al cuidado y a la proximidad habitualmente, necesarias en todo caso, sino hacer una <b>lectura transversal</b> de todas las infraestructuras desde esta perspectiva, incluido el espacio público entendido también como infraestructura.",
      color: "249, 224, 43",
      colorLight: "255, 242, 150",
      hexColor: "#F9E02B",
      icon: {
        black: "./images/icons/cat-icon-2-black.png",
        white: "./images/icons/cat-icon-2-white.png",
        color: "./images/icons/cat-icon-2-color.png",
      },
    },
    ESPACIOS_COMUNES: {
      id: 3,
      number: "3",
      key: "ESPACIOS_COMUNES",
      name: "Espacios comunes",
      nameCat: "Espais comuns",
      description:
        "Los espacios comunes y de proximidad como aquellos <b>espacios interconectados</b> por una sección continua, transición paulatina o <b>continuum espacial</b> que acompaña a las personas desde la vivienda hasta el territorio. Estos están <b>interconectados</b> con el resto de redes, vertebrando y relacionando a su vez el conjunto del territorio. Se propone una <b>transición enlazada</b> que acompañe a las personas en todas las escalas de la movilidad territorial, pero que se define por la vinculación clara de proximidad entre los espacios asociados al edificio y los asociados a escala de barrio.",
      color: "167, 109, 242",
      colorLight: "200, 171, 238",
      hexColor: "#A76DF2",
      icon: {
        black: "./images/icons/cat-icon-3-black.png",
        white: "./images/icons/cat-icon-3-white.png",
        color: "./images/icons/cat-icon-3-color.png",
      },
    },
    SEGURIDAD: {
      id: 4,
      number: "4",
      key: "SEGURIDAD",
      name: "Seguridad, confort y calidad urbana",
      nameCat: "Seguretat, confort i qualitat urbana",
      description:
        "La <b>seguridad</b> es un requisito esencial que debe estar incorporado en todas las actuaciones, para todas las personas, en todos los ámbitos. La red de espacios públicos, en particular las calles, se presenta como una <b>infraestructura de espacio social natural, accesible y asequible</b> para todos.  Sin embargo, en la mayoría de los procesos participativos surge con frecuencia la cuestión de la calidad del entorno urbano, caracterizado mediante aspectos parciales como el mantenimiento y cuidado de las condiciones materiales de dichos espacios.",
      color: "239, 112, 157",
      colorLight: "255, 189, 212",
      hexColor: "#EF709D",
      icon: {
        black: "./images/icons/cat-icon-4-black.png",
        white: "./images/icons/cat-icon-4-white.png",
        color: "./images/icons/cat-icon-4-color.png",
      },
    },
    SOCIAL: {
      id: 5,
      number: "5",
      key: "SOCIAL",
      name: "Gestión social, economía y política",
      nameCat: "Gestió social, economia i política",
      description:
        "El planeamiento urbano expone soluciones en el espacio físico que afectan al conjunto de la población y que, necesariamente, van acompañadas de procesos de gestión tanto <b>política</b> como <b>administrativa y económica</b>. Esta perspectiva puede requerir la intervención de profesionales de otras áreas de conocimiento que procuren una aproximación <b>multidisciplinar</b>, así como la organización de procesos participativos. Con carácter general, se sugiere tender a la formación de equipos paritarios, así como, también a título general, procurar equipos de trabajo diversos.",
      color: "93, 201, 219",
      colorLight: "198, 247, 255",
      hexColor: "#5DC9DB",
      icon: {
        black: "./images/icons/cat-icon-5-black.png",
        white: "./images/icons/cat-icon-5-white.png",
        color: "./images/icons/cat-icon-5-color.png",
      },
    },
  },
  userColor: {
    yellow: {
      normal: "249, 224, 43",
      medium: "249,224,43",
      light: "255, 231, 58",
      dark: "244, 219, 32",
    },
    purple: {
      normal: "167, 109, 242",
      medium: "167, 109, 242",
      light: "178, 119, 255",
      dark: "153, 74, 255",
    },
    pink: {
      normal: "239, 112, 157",
      medium: "239, 112, 157",
      light: "238, 121, 162",
      dark: "232, 96, 144",
    },
    blue: {
      normal: "93, 201, 219",
      medium: "93, 201, 219",
      light: "127, 211, 225",
      dark: "58, 193, 216",
    },
    green: {
      normal: "144, 234, 66",
      medium: "144, 234, 66",
      light: "167, 237, 107",
      dark: "133, 224, 53",
    },
    default: {
      normal: "189,189,189",
      medium:  "189,189,189",
      light: "202,202,202",
      dark: "178,178,178",
    },
  },
  pronouns: {
    'she/her': {
      name: "She/her",
      nameCat: "Ella",
      nameCastellano: "Ella",
    },
    'he/him': {
      name: "He/him",
      nameCat: "Ell",
      nameCastellano: "Él",
    },
    'they/them': {
      name: "They/them",
      nameCat: "Elle",
      nameCastellano: "Elle",
  },
  },
};
// eslint-disable-next-line func-names
Options.install = function (Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getProjectCategory = (key) => Options.projectCategory[key];
  Vue.prototype.$getProjectCategoryById = (id) => {
    const category = Object.values(Options.projectCategory).find(
      (cat) => cat.id === id
    );
    return category;
  };
  Vue.prototype.$getUserColor = (color) => Options.userColor[color];
  Vue.prototype.$getPronouns = (pronouns) => Options.pronouns[pronouns];
};

export default Options;


