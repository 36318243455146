<template>
  <div class="burger-nav" :class="[navOpen ? 'open' : '']">
    <div class="burger-nav--inner">
      <button class="burger-nav--toggle" @click="toggleNav">
        <div class="burger-nav--toggle--inner">
          <span class="burger-nav--toggle--inner__line"></span>
          <span class="burger-nav--toggle--inner__line"></span>
          <span class="burger-nav--toggle--inner__line"></span>
        </div>
      </button>
      <nav class="burger-nav--inner--menu">
        <ul class="flex flex-col menu_ul">
          <li class="flex">
            <router-link
              :to="{ name: 'AboutCug' }"
              @click.native="toggleNav()"
              >{{ $t("message.menu.items[0]") }}</router-link
            >
          </li>
          <li class="flex">
            <router-link
              :to="{ name: 'ArchiveCug' }"
              @click.native="toggleNav()"
              >{{ $t("message.menu.items[1]") }}</router-link
            >
          </li>
          <li class="flex">
            <router-link :to="{ name: 'MapCug' }" @click.native="toggleNav()">{{
              $t("message.menu.items[2]")
            }}</router-link>
          </li>
          <li class="flex">
            <router-link
              :to="{ name: 'ContactCug' }"
              @click.native="toggleNav()"
              >{{ $t("message.menu.items[3]") }}</router-link
            >
          </li>
          <ul class="flex gap-4 list-none px-4">
            <li class="flex">
              <button
                @click="downloadGuide"
                class="flex justify-center items-center gap-2 bg-black text-white w-[6.25rem] h-9 px-4 py-2 rounded-lg text-center text-lg font-medium leading-[normal] whitespace-nowrap"
              >
                <img src="~@/assets/icons/icon-download-white.svg" alt="" />
                {{ $t("message.home.guide.guide") }}
              </button>
            </li>
            <li class="flex">
              <a
                href="https://cugnews.wordpress.com/"
                target="_blank"
                class="flex justify-center items-center gap-2 bg-black text-white w-[3.25rem] h-9 px-4 py-2 rounded-lg text-center text-lg font-medium leading-[normal] whitespace-nowrap"
              >
                <img src="~@/assets/icons/icon-wordpress-white.svg" alt="" />
              </a>
            </li>
            <li class="flex">
              <a
                href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
                target="_blank"
                class="flex justify-center items-center gap-2 bg-black text-white w-[3.25rem] h-9 px-4 py-2 rounded-lg text-center text-lg font-medium leading-[normal] whitespace-nowrap"
              >
                <img src="~@/assets/icons/icon-youtube-white.svg" alt="" />
              </a>
            </li>
          </ul>
          <li class="flex">
            <router-link
              :to="{ name: 'LoginCug' }"
              @click.native="toggleNav()"
              >{{ $t("message.menu.items[4]") }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "BurgerNav",
  data() {
    return {
      navOpen: false,
      canDownload: true,
      downloadCooldown: 5000, // 5 segundos de cooldown
    };
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
      console.log(this.navOpen, "navOpen");
    },
    downloadGuide() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px blue;
// }
.burger-nav {
  position: fixed;
  right: calc(var(--base) * 11);
  top: calc(var(--base) * 8);
  top: calc(var(--app-height) * 0.06517311609);
  z-index: 400;
  .burger-nav--inner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    max-width: calc(var(--base) * 7.7);
    overflow: hidden;
    transition: all ease-out 0.5s;
    float: right;
    border: solid 1px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 1.25);
    background-color: rgb(var(--bodyColor));
  }
  .burger-nav--toggle {
    min-width: calc(var(--base) * 7.5);
    min-height: calc(var(--base) * 7.5);
    width: calc(var(--base) * 7.5);
    height: calc(var(--base) * 7.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 0.5);
    background-color: rgb(var(--bodyColor));
    .burger-nav--toggle--inner {
      position: relative;
      background-color: rgb(var(--bodyColor));
      border: solid 2px rgb(var(--bodyBg));
      border-radius: calc(var(--base) * 1.25);
      width: 100%;
      max-width: calc(var(--base) * 6.5);
      height: 100%;
      max-height: calc(var(--base) * 6.5);
      padding: calc(var(--base) * 0.3125);
      overflow: hidden;
      &__line {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: calc(var(--base) * 0.3125);
        height: calc(var(--base) * 0.6);
        width: calc(100% - calc(var(--base) * 0.625));
        background-color: rgb(var(--bodyBg));
        border-radius: calc(var(--base) * 2);
        opacity: 1;
        transition: all ease-out 0.3s;
        &:nth-child(1) {
          top: calc(var(--base) * 1.3);
        }
        &:nth-child(2) {
          top: calc(var(--base) * 2.6);
        }
        &:nth-child(3) {
          top: calc(var(--base) * 3.9);
        }
      }
    }
  }
  &:hover {
    .burger-nav--toggle--inner__line {
      &:nth-child(1) {
        top: calc(var(--base) * 1.1);
      }
      &:nth-child(2) {
        top: calc(var(--base) * 2.6);
      }
      &:nth-child(3) {
        top: calc(var(--base) * 4.1);
      }
    }
  }
  .burger-nav--inner--menu {
    max-width: 100%;
    ul.menu_ul {
      width: 100%;
      height: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      background-color: rgb(var(--bodyBg));
      & > li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-of-type {
          a {
            border-left: none;
            position: relative;
          }
        }
        &:last-of-type {
          background-color: rgb(var(--bodyColor));
          border-radius: calc(var(--base) * 2.5) calc(var(--base) * 0)
            calc(var(--base) * 0) calc(var(--base) * 2.5);
          a {
            color: rgb(var(--bodyBg));
          }
        }
        &:nth-of-type(4) {
          a {
            border-right: solid 1px rgb(var(--bodyColor));
          }
        }
        &:nth-of-type(5) {
          padding: 0 calc(var(--base) * 3);
        }
        a {
          color: rgb(var(--bodyColor));
          font-size: calc(var(--base) * 2.25);
          padding: 0 calc(var(--base) * 3);
          border-left: solid 1px rgb(var(--bodyColor));
          font-weight: 500;
          text-transform: uppercase;
          text-align: center;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }
  }
}

.burger-nav.open {
  & .burger-nav--inner {
    max-width: 100%;
    border-radius: calc(var(--base) * 2.5) calc(var(--base) * 1.25)
      calc(var(--base) * 1.25) calc(var(--base) * 2.5);
  }
  .burger-nav--toggle--inner {
    &__line {
      position: absolute;
      &:nth-child(1) {
        top: 50%;
        transform: translate(0, -50%) rotate(45deg);
      }
      &:nth-child(2) {
        left: -100%;
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }
}
</style>
