<template>
  <div class="form-step--wrapper form-upload-edit--step-2">
    <div class="form--content form-upload-edit--step-2--content">
      <div class="form--grid">
        <v-file-input v-if="!projectImage && this.$vuetify.breakpoint.width < 1024" v-model="projectImage" :label="
          $t('message.forms.projectSelectFiles') +
          ' ' +
          $t('message.forms.maxFilesOne')
        " prepend-inner-icon="mdi-cloud-upload" :counter="1" prepend-icon="" :placeholder="
  $t('message.forms.projectSelectFiles') +
  ' ' +
  $t('message.forms.maxFilesOne')
" outlined :dense="this.$vuetify.breakpoint.width < 1024" small-chips clearable>
        </v-file-input>
        <v-file-input v-if="this.$vuetify.breakpoint.width >= 1024" v-model="projectImage" :label="
          $t('message.forms.projectSelectFiles') +
          ' ' +
          $t('message.forms.maxFilesOne')
        " prepend-inner-icon="mdi-cloud-upload" :counter="1" prepend-icon="" :placeholder="
  $t('message.forms.projectSelectFiles') +
  ' ' +
  $t('message.forms.maxFilesOne')
" outlined :dense="this.$vuetify.breakpoint.width < 1024" small-chips clearable>
        </v-file-input>
        <div class="upload-buttons">
          <!-- button for upload image -->
          <v-btn v-if="projectImage" color="primary" @click="uploadMainImage" class="mr-2"
            :disabled="projectMainImages.length >= 3">
            {{ $t('message.forms.uploadMainImage') +
              ' ' +
              $t('message.forms.maxFilesThree') }}
          </v-btn>
          <v-btn v-if="projectImage" color="primary" @click="uploadImage" class="mr-2"
            :disabled="projectImages.length >= 3">
            {{ $t('message.forms.uploadImage') +
              ' ' +
              $t('message.forms.maxFilesTen') }}
          </v-btn>
        </div>
        <div class="tables-wrapper">
          <div class="table-container">
            <div class="table-container--title" v-if="projectMainImages.length > 0">
              {{ $t('message.general.mainImages') +
                ' ' +
                $t('message.forms.maxFilesThree') }}
            </div>
            <div class="table-container--inner">
              <images-table-row v-for="image in projectMainImages" :key="image.name">
                <template v-slot:preview-image>
                  <img :src="image.url" alt="" />
                </template>
                <template v-slot:name-image>
                  {{ trimString(image.name, 10) }}
                </template>
                <template v-slot:delete-image>
                  <v-btn color="#ffffff" icon @click="deleteImage(image)" :disabled="projectMainImages.length === 1"
                    class="action-button">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:open-image>
                  <v-btn color="#ffffff" icon @click="openImage(image)" class="action-button">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
              </images-table-row>
            </div>
          </div>
          <div class="table-container">

            <div class="table-container--title" v-if="projectImages.length > 0">
              {{ $t('message.general.images') +
                ' ' +
                $t('message.forms.maxFilesTen') }}
            </div>
            <div class="table-container--inner">
              <images-table-row v-for="image in projectImages" :key="image.name">
                <template v-slot:preview-image>
                  <img :src="image.url" alt="" />
                </template>
                <template v-slot:name-image>
                  {{ trimString(image.name, 10) }}
                </template>
                <template v-slot:delete-image>
                  <v-btn color="#ffffff" icon @click="deleteImage(image)" :disabled="projectMainImages.length === 1"
                    class="action-button">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:open-image>
                  <v-btn color="#ffffff" icon @click="openImage(image)" class="action-button">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
              </images-table-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectServices from '@/services/project.services';
import ImagesTableRow from '@/components/Forms/ImagesTableRow.vue';

export default {
  components: {
    ImagesTableRow,
  },
  data() {
    return {
      edition: null,
      recognition: null,
      url: null,
      editionItems: null,
      recognitionItems: null,
      isRecognized: false,

      projectImage: null,
      projectMainImages: [],
      projectImages: [],
      projectImagesRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      project: {
        projectMainImages: [
          {
            imageUrl: null,
          }
        ],
        projectImages: [
          {
            imageUrl: null,
          }
        ],
      },
      headers: [
        {
          text: 'sss',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Image', value: 'url' },
        { text: 'Actions', value: 'actions' },
      ],
      options: {
        itemsPerPage: 3,
        sortBy: ['name'],
        sortDesc: [false],
      },
    }
  },
  methods: {
    updateProject(project) {
      this.project = project;
      console.log('p', project);
      this.projectMainImages = project.projectMainImages.map(image => {
        return {
          name: image.imageUrl.split("/").pop(),
          url: image.imageUrl,
          id: image.id,
        }
      });
      this.projectImages = project.projectImages.map(image => {
        return {
          name: image.imageUrl.split("/").pop(),
          url: image.imageUrl,
          id: image.id,
        }
      });
    },
    openImage(image) {
      window.open(image.url, '_blank');
    },
    deleteImage(image) {
      projectServices.deleteImageFromProject(this.$route.params.id, image.id).then(() => {
        this.$router.go();
      });
    },
    uploadMainImage() {
      console.log(this.projectImage);
      projectServices.uploadMainImageToProject(this.$route.params.id, this.projectImage).then(
        response => {
          console.log(response);
          this.$router.go();
        },
        error => {
          console.log(error);
        }
      );
    },
    uploadImage() {
      projectServices.uploadImageToProject(this.$route.params.id, this.projectImage).then(
        response => {
          console.log(response);
          this.$router.go();
        },
        error => {
          console.log(error);
        }
      );
    },
    //a method that trims a string to a certain length but leaves the extension intact
    trimString(str, length) {
      if (str.length > length) {
        return str.substring(0, length) + '(...)' + str.substring(str.length - 4, str.length);
      }
      return str;
    },

  },
}

</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.2673463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3149287169);
  }

  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    .form--grid {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        column-gap: 3.835978836vw;
      }
    }
  }
}

.v-input {
  width: 100%;
  flex: none;

  @media (min-width: 1024px) {
    width: calc(50%);
  }
}
</style>
<style>
.v-input--radio-group--row .v-input--radio-group__input {
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.upload-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base)*2);
  justify-content: center;
  margin-bottom: calc(var(--base));

  @media(min-width: 1024px) {
    margin-bottom: calc(var(--base)*2);
  }
}

.tables-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base)*2);

  @media(min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    gap: calc(var(--base)*6);
  }
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base)*2);

  &--title {
    font-size: calc(var(--base)*2);
    font-weight: 600;
    color: var(--color-primary);
  }

  &--inner {
    display: flex;
    flex-wrap: nowrap;
    gap: calc(var(--base) * 2);
    max-width: 100vw;
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: calc(var(--base) * 5);
    margin-left: calc((100vw - 100%) / -2);
    padding-left: calc((100vw - 100%) / 2);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media(min-width: 1024px) {
      max-width: 100%;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: calc(var(--base) * 5);
      margin-left: 0;
      padding-left: 0;
    }
  }
}
</style>