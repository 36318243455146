<template>
  <div class="page page-upload">
    <section class="form-step--container">
      <div class="form-titles">
        <h1 class="form-step--title">
          {{ $t(`message.forms.questions.upload.titles.${activeStep}`) }}
        </h1>
        <h2 class="form-step--subtitle" v-if="this.$vuetify.breakpoint.width >= 1024">
          {{ $t(`message.forms.questions.upload.subtitles.${ activeStep }`) }}
        </h2>
      </div>

      <v-form>
        <form-upload-step-1 v-show="activeStep == 1" @toggleModal="toggleModal()"
          @updateData="updateStep1"></form-upload-step-1>
        <form-upload-step-2 v-show="(activeStep == 2)"
          @updateData="updateStep2"></form-upload-step-2>
        <form-upload-step-3 v-show="(activeStep == 3)"
          @updateData="updateStep3"></form-upload-step-3>
        <form-upload-step-4 v-show="(activeStep == 4)"></form-upload-step-4>
      </v-form>

      <div class="form-nav-buttons" v-if="activeStep < maxSteps">
        <button class="form-nav-buttons--button" :disabled="!isPreviousButtonEnabled"
          @click="previousStep()" :class="[!isPreviousButtonEnabled ? 'disabled' : '']">
          {{ $t("message.forms.previous") }}
        </button>
        |
        <button class="form-nav-buttons--button" :disabled="!isNextButtonEnabled"
          @click="nextStep()" :class="[!isNextButtonEnabled ? 'disabled' : '']">
          {{ $t("message.forms.next") }}
        </button>
      </div>
    </section>
    <div class="coordinates-modal" v-if="coordinatesModal">
      <div class="coordinates-modal--inner">
        <div class="coordinates-modal--header">
          <div class="modal--header--title">
            {{ $t("message.forms.coordinatesHelpQuestion") }}
          </div>
          <button class="modal--header--close" @click="toggleModal()">
            <a href="javascript:;">
              <img src="@/assets/icons/icon-close-white.svg" alt="Tancar" />
            </a>
          </button>
        </div>
        <div class="coordinates-modal--body">
          <div class="modal--body--item">
            <div class="modal--body--item__icon">
              <img src="@/assets/icons/icon-help-coordinates-1.svg" alt="" />
            </div>
            <div class="modal--body--item__text">
              {{ $t("message.forms.coordinatesHelpText1") }}
            </div>
          </div>
          <div class="modal--body--item">
            <div class="modal--body--item__icon">
              <img src="@/assets/icons/icon-help-coordinates-2.svg" alt="" />
            </div>
            <div class="modal--body--item__text">
              {{ $t("message.forms.coordinatesHelpText2") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectService from "@/services/project.services.js";
import FormUploadStep1 from "@/components/Forms/Upload/FormUploadStep1.vue";
import FormUploadStep2 from "@/components/Forms/Upload/FormUploadStep2.vue";
import FormUploadStep3 from "@/components/Forms/Upload/FormUploadStep3.vue";
import FormUploadStep4 from "@/components/Forms/Upload/FormUploadStep4.vue";

export default {
  name: "RegisterPage",
  components: {
    FormUploadStep1,
    FormUploadStep2,
    FormUploadStep3,
    FormUploadStep4,

  },
  methods: {
    previousStep() {
      if (this.activeStep > 1) {
        this.activeStep -= 1
      } else if(this.activeStep === 1){
        history.back();
      }else {
        this.activeStep = 1
      }
    },
    nextStep() {
      if (this.activeStep === 3) {
        this.registerProject();
      }
      if (this.activeStep >= this.maxSteps) {
        this.activeStep = 1
      } else {
        this.activeStep += 1;
      }
    },
    toggleModal() {
      this.coordinatesModal = !this.coordinatesModal;
    },
    updateStep1(data, mainImages) {
      //update project data
      this.project.titleCastellano = data.titleCastellano;
      this.project.titleValencia = data.titleValencia;
      this.project.year = data.year;
      this.project.type = data.type;
      this.project.currentStatus = data.currentStatus;
      this.project.authors = data.authors;
      this.project.descriptionShortCastellano = data.descriptionShortCastellano;
      this.project.descriptionShortValencia = data.descriptionShortValencia;
      this.project.latitude = data.latitude;
      this.project.longitude = data.longitude;
      this.projectMainImages = mainImages;
    },
    updateStep2(data) {
      //update project data
      this.project.edition = data.edition;
      this.project.prize = data.prize;
    },
    updateStep3(data, images) {
      //update project data
      this.project.descriptionLongCastellano = data.descriptionLongCastellano;
      this.project.descriptionLongValencia = data.descriptionLongValencia;
      this.project.projectLinks = data.projectLinks;
      this.projectImages = images;
    },
    registerProject() {
      projectService.createProject(this.project).then(
        response => {
          console.log(response);
          let createdId = response.id;
          if (this.projectMainImages.length > 0) {
            projectService.uploadMainImageToProject(createdId, this.projectMainImages[0]).then(
              response => {
                console.log(response);
                if (this.projectMainImages.length > 1) {
                  projectService.uploadMainImageToProject(createdId, this.projectMainImages[1]).then(
                    response => {
                      console.log(response);
                      if (this.projectMainImages.length > 2) {
                        projectService.uploadMainImageToProject(createdId, this.projectMainImages[2]).then(
                          response => {
                            console.log(response);
                          },
                          error => {
                            console.log(error);
                          }
                        );
                      } else {
                        this.uploadImagesToProject(createdId);
                      }
                    },
                    error => {
                      console.log(error);
                    }
                  );
                } else {
                  this.uploadImagesToProject(createdId);
                }
              },
              error => {
                console.log(error);
              }
            );
          } else {
            this.uploadImagesToProject(createdId);
          }
        },
        error => {
          console.log(error);
        }
      );
    },
    uploadImagesToProject(createdId) {
      if (this.projectImages.length > 0) {
        projectService.uploadImageToProject(createdId, this.projectImages[0]).then(
          response => {
            console.log(response);
            if (this.projectImages.length > 1) {
              projectService.uploadImageToProject(createdId, this.projectImages[1]).then(
                response => {
                  console.log(response);
                  if (this.projectImages.length > 2) {
                    projectService.uploadImageToProject(createdId, this.projectImages[2]).then(
                      response => {
                        console.log(response);
                        if (this.projectImages.length > 3) {
                          projectService.uploadImageToProject(createdId, this.projectImages[3]).then(
                            response => {
                              console.log(response);
                              if (this.projectImages.length > 4) {
                                projectService.uploadImageToProject(createdId, this.projectImages[4]).then(
                                  response => {
                                    console.log(response);
                                    if (this.projectImages.length > 5) {
                                      projectService.uploadImageToProject(createdId, this.projectImages[5]).then(
                                        response => {
                                          console.log(response);
                                          if (this.projectImages.length > 6) {
                                            projectService.uploadImageToProject(createdId, this.projectImages[6]).then(
                                              response => {
                                                console.log(response);
                                                if (this.projectImages.length > 7) {
                                                  projectService.uploadImageToProject(createdId, this.projectImages[7]).then(
                                                    response => {
                                                      console.log(response);
                                                      if (this.projectImages.length > 8) {
                                                        projectService.uploadImageToProject(createdId, this.projectImages[8]).then(
                                                          response => {
                                                            console.log(response);
                                                            if (this.projectImages.length > 9) {
                                                              projectService.uploadImageToProject(createdId, this.projectImages[9]).then(
                                                                response => {
                                                                  console.log(response);
                                                                },
                                                              );
                                                            }
                                                          },
                                                        );
                                                      }
                                                    },
                                                  );
                                                }
                                              },
                                            );
                                          }
                                        },
                                      );
                                    }
                                  },
                                );
                              }
                            },
                          );
                        }
                      },
                    );
                  }
                },
              );
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    uploadMainImagesTest() {
      projectService.uploadMainImageToProject(237, this.projectMainImages[0]).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    },
    uploadCustomImageTest() {
      projectService.uploadImageToProject(204, this.projectMainImages[0]).then(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  data() {
    return {
      activeStep: 1,
      isPreviousButtonEnabled: true,
      isNextButtonEnabled: true,
      maxSteps: 4,
      coordinatesModal: false,

      //create project
      project: {
        category: null,
        edition: null,
        latitude: null,
        longitude: null,
        prize: null,
        titleCastellano: null,
        titleValencia: null,
        type: null,
        year: null,
        projectLinks: [
          {
            url: null,
            title: null
          }
        ],
        authors: [
          {
            nameCastellano: null,
          }
        ],
        placeName: null,
        descriptionShortCastellano: null,
        descriptionShortValencia: null,
        currentStatus: null,
        descriptionLongCastellano: null,
        descriptionLongValencia: null,
      },
      //project images
      projectMainImages: null,
      projectImages: null,

    }
  },
  mounted() {
    this.maxSteps = 4;
  },

}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
}

.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.05);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;

  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.15);
  }

  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;
    pointer-events: all;
    cursor: pointer;
    opacity: 1;

    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}

.form-titles {
  position: absolute;
  top: calc(var(--app-height) * 0.15);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2057841141);
  }

  .form-step--title {
    font-size: calc(var(--base) * 3);
    font-weight: 700;
    text-align: center;
    padding: 0 calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 0.5);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 4);
    }

  }

  .form-step--subtitle {

    width: 100%;
    font-size: calc(var(--base) * 1.375);
    font-weight: 400;
    text-align: center;
    padding: 0 calc(var(--base) * 2);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 1.875);
    }
  }

}

.coordinates-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
  width: 100vw;
  height: var(--app-height);
  background-color: rgba(var(--bodyColor), 0.37);
  display: flex;
  justify-content: center;
  align-items: center;

  .coordinates-modal--inner {
    width: 81.06666667vw;
    height: auto;
    background: rgb(var(--bodyBg));
    border: solid 1px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 3.75);
    padding: calc(var(--base) * 2.625) calc(var(--base) * 2.625);
    padding-bottom: calc(var(--base) * 6);
    padding-right: calc(var(--base) * 2.625);

    @media (min-width: 1024px) {
      width: 30.15873016vw;
      height: auto;
      background: rgb(var(--bodyBg));
      border: solid 1px rgb(var(--bodyColor));
      border-radius: calc(var(--base) * 3.75);
      padding: calc(var(--base) * 3.75) calc(var(--base) * 4.75);
      padding-bottom: calc(var(--base) * 6);
      padding-right: calc(var(--base) * 3.125);
    }

    .coordinates-modal--header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: calc(var(--base) * 4);

      & .modal--header--title {
        font-size: calc(var(--base) * 3.5);
        line-height: calc(var(--base) * 3.75);
        font-weight: 700;
        color: rgb(var(--bodyColor));
        text-decoration: underline;
        max-width: 90%;
      }

      & .modal--header--close {
        background: rgb(var(--bodyColor));
        width: calc(var(--base) * 3.25);
        height: calc(var(--base) * 3.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--base) * 0.625);

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: calc(var(--base) * 0.875);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .coordinates-modal--body {
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 5.625);
      padding-right: calc(var(--base) * 2);

      .modal--body--item {
        display: flex;
        flex-wrap: nowrap;
        gap: calc(var(--base) * 3.5);

        &__icon {
          width: calc(var(--base) * 8);
          height: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}
</style>