<template>
  <div class="page page-archive" :class="[loading ? 'overflow-hidden' : '']">
    <archive-gradient-scroll></archive-gradient-scroll>
    <div class="page--header page-archive--header">
      <h1 class="page--header__title page-archive--header__title">
        {{ $t("message.archive.title") }}
      </h1>
      <h2
        v-if="this.$vuetify.breakpoint.width >= 1024"
        class="page--header__subtitle page-archive--header__subtitle whitespace-nowrap"
        v-html="this.$t('message.archive.subtitle')"
      ></h2>
    </div>
    <div class="archive--filter-container">
      <archive-filter @updateFilter="updateFilter"></archive-filter>
    </div>
    <div class="filter--cat-container">
      <filter-cat-selector-item
        :cat="$getProjectCategory('SOSTENIBILIDAD')"
        :isRouteUp="isRouteUp"
        @updateFilter="updateType"
        ref="filterSOSTENIBILIDAD"
      ></filter-cat-selector-item>
      <filter-cat-selector-item
        :cat="$getProjectCategory('INSTALACIONES')"
        :isRouteUp="isRouteUp"
        @updateFilter="updateType"
        ref="filter_INSTALACIONES"
      ></filter-cat-selector-item>
      <filter-cat-selector-item
        :cat="$getProjectCategory('ESPACIOS_COMUNES')"
        :isRouteUp="isRouteUp"
        @updateFilter="updateType"
        ref="filter_ESPACIOS_COMUNES"
      ></filter-cat-selector-item>
      <filter-cat-selector-item
        :cat="$getProjectCategory('SEGURIDAD')"
        :isRouteUp="isRouteUp"
        @updateFilter="updateType"
        ref="filter_SEGURIDAD"
      ></filter-cat-selector-item>
      <filter-cat-selector-item
        :cat="$getProjectCategory('SOCIAL')"
        :isRouteUp="isRouteUp"
        @updateFilter="updateType"
        ref="filter_SOCIAL"
      ></filter-cat-selector-item>
    </div>
    <div
      class="archive--grid relative"
      v-if="projects && this.$vuetify.breakpoint.width >= 1024"
    >
      <page-loader
        :color="this.color"
        :bgColor="this.bgColor"
        v-if="loading"
      ></page-loader>
      <div
        class="no-results absolute top-0 left-0 flex justify-center items-center w-full"
        v-if="projects.length === 0 && !loading"
      >
        <p
          class="no-results__title text-xl font-bold"
          v-text="this.$t('message.archive.noResults')"
        ></p>
      </div>
      <project-card
        v-for="project in projects"
        :key="project.id"
        :title="project.titleCastellano"
        :titleCat="project.titleValencia"
        :author="
          project.authors[0].nameCastellano
            ? project.authors[0].nameCastellano
            : project.authors[0].nameValencia
        "
        :projectId="project.id"
        :authorCat="
          project.authors[0].nameValencia
            ? project.authors[0].nameValencia
            : project.authors[0].nameCastellano
        "
        :cat="$getProjectCategory(project.type)"
        :img="
          project.projectMainImages[0]
            ? project.projectMainImages[0].imageUrl
            : project.projectMainImages[0]
        "
        :stars="project.starsNumber"
        :likes="project.favoritesNumber"
        :views="project.views"
        :isRouteUp="isRouteUp"
      ></project-card>
    </div>
    <lazy-loader :color="this.color" v-if="isFetching"></lazy-loader>
    <div
      class="archive--grid"
      v-if="projects && this.$vuetify.breakpoint.width < 1024"
    >
      <page-loader
        :color="this.color"
        :bgColor="this.bgColor"
        v-if="loading"
      ></page-loader>
      <project-card-movil
        v-for="project in projects"
        :key="project.id"
        :title="project.titleCastellano"
        :titleCat="project.titleValencia"
        :author="
          project.authors[0].nameCastellano
            ? project.authors[0].nameCastellano
            : project.authors[0].nameValencia
        "
        :projectId="project.id"
        :authorCat="
          project.authors[0].nameValencia
            ? project.authors[0].nameValencia
            : project.authors[0].nameCastellano
        "
        :cat="$getProjectCategory(project.type)"
        :img="
          project.projectMainImages[0]
            ? project.projectMainImages[0].imageUrl
            : project.projectMainImages[0]
        "
        :stars="project.starsNumber"
        :likes="project.favoritesNumber"
        :views="project.views"
        :isRouteUp="isRouteUp"
      >
      </project-card-movil>
    </div>
    <lazy-loader :color="this.color" v-if="isFetching"></lazy-loader>
  </div>
</template>

<script lang="js">
import ProjectService from '@/services/project.services';
import ProjectCard from '@/components/Cards/ProjectCard.vue';
import ProjectCardMovil from '@/components/Cards/ProjectCardMovil.vue';
import ArchiveFilter from '@/components/Filters/ArchiveFilter.vue';
import FilterCatSelectorItem from '@/components/Filters/FilterCatSelectorItem.vue';
import ArchiveGradientScroll from '@/components/Decoration/ArchiveGradientScroll.vue';
import PageLoader from '@/components/Loaders/PageLoader.vue';
import LazyLoader from '@/components/Loaders/LazyLoader.vue';

export default {
  name: 'ArchiveCug',
  components: {
    ProjectCard,
    ProjectCardMovil,
    ArchiveFilter,
    FilterCatSelectorItem,
    ArchiveGradientScroll,
    PageLoader,
    LazyLoader,
  },
  data() {
    return {
      projects: [],
      totalProjects: 0, // Nueva propiedad para el total de proyectos
      filter: {
        year: null,
        status: null,
        title: null,
      },
      page: 0,
      size: 12,
      isFetching: false, // Nuevo estado para el loader
      loading:true,
      filterType: null,
      filterTypeArray: [],
      color: '0,0,0',
      bgColor: '255,255,255',
    };
  },
  computed: {
    isRouteUp() {
      return !!(this.$route.params.id);
    },
  },
  methods: {
    updateType(val) {
      // const templateFilterTypeArray = "SOCIAL,INSTALACIONES,ESPACIOS_COMUNES,SEGURIDAD,SOSTENIBILIDAD";
      // if val is in filterTypeArray, remove it
      // else add it
      if (this.filterTypeArray.includes(val)) {
        this.filterTypeArray.splice(this.filterTypeArray.indexOf(val), 1);
      } else {
        this.filterTypeArray.push(val);
      }
      // transform array to string
      this.filterType = this.filterTypeArray.join(',');
      this.getProjectsFiltered(this.page, this.size);
    },
    async getAllProjects() {
      this.loading = true;
      const response = await ProjectService.getAllPublic(this.page, this.size);
      this.projects = response.content;
      this.totalProjects = response.totalElements; // Asigna el total de proyectos
      this.loading = false;
    },
    async loadMoreProjects() {
      if (this.isFetching || this.loading || this.projects.length >= this.totalProjects) return;
      this.isFetching = true;
      this.page += 1;
      const response = await ProjectService.getPublicFiltered(
        this.filter.title,
        this.filter.year,
        this.filter.status,
        this.filterType,
        this.page,
        this.size
      );
      this.projects = [...this.projects, ...response.content];
      this.isFetching = false;
    },
    async getProjectsFiltered() {
      this.loading = true;
      this.page = 0; // Reinicia la página
      this.projects = []; // Limpia los proyectos actuales
      console.log('Filtros:', this.filter, 'Tipo de filtro:', this.filterType);
      try {
        const response = await ProjectService.getPublicFiltered(
          this.filter.title,
          this.filter.year,
          this.filter.status,
          this.filterType,
          this.page,
          this.size
        );
        console.log('Respuesta de la API:', response);
        this.projects = response.content;
        this.totalProjects = response.totalElements;

        // Desplazar hacia arriba después de aplicar el filtro
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        console.error('Error al obtener proyectos filtrados:', error);
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      if (this.isFetching || this.loading) return;
      const bottomOfWindow =
        window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100;
      if (bottomOfWindow) {
        this.loadMoreProjects();
      }
    },
    updateFilter(val) {
      this.filter = val;
      this.getProjectsFiltered();
    },
    initTypeFilter(val) {
      val = parseInt(val);
      let cat = this.$getProjectCategoryById(val);
      console.log('Categoría obtenida:', cat);
      if (cat) {
        this.updateType(cat.key);
        this.setFilterType(cat.key);
      } else {
        console.error('No se encontró la categoría para el ID:', val);
      }
    },
    setFilterType(val) {
      console.log('Estableciendo tipo de filtro:', val);
      if (val === 'SOSTENIBILIDAD') {
        this.$refs.filterSOSTENIBILIDAD.updateIsSelected(true);
      } else if (val === 'INSTALACIONES') {
        this.$refs.filter_INSTALACIONES.updateIsSelected(true);
      } else if (val === 'SOCIAL') {
        this.$refs.filter_SOCIAL.updateIsSelected(true);
      } else if (val === 'ESPACIOS_COMUNES') {
        this.$refs.filter_ESPACIOS_COMUNES.updateIsSelected(true);
      } else if (val === 'SEGURIDAD') {
        this.$refs.filter_SEGURIDAD.updateIsSelected(true);
      } else {
        console.error('Tipo de filtro desconocido:', val);
      }
    },
  },
  mounted() {
    //if has route params, set filter
    if (this.$route.params.id) {
      this.initTypeFilter(this.$route.params.id);
    }else{
      this.getAllProjects();
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>
.page-archive {
  min-height: 100%;
  // padding-bottom: calc(var(--base) * 5);
  @media (min-width: 1024px) {
    padding-bottom: calc(var(--base) * 10);
  }
}
.page--header {
  position: fixed;
  left: 50%;
  top: calc(var(--app-height) * 0.143928036);
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: calc(var(--base) * 2.875);
  z-index: 200;

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.07026476578);
    text-align: left;
  }

  &__title {
    font-size: calc(var(--base) * 4.25);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    display: block;

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 12);
      padding-right: calc(var(--base) * 2.875);
      border-right: solid 3px rgb(var(--bodyColor));
    }
  }

  .page--header__subtitle {
    font-size: calc(var(--base) * 3);
    line-height: calc(var(--base) * 3.5);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.archive--grid {
  width: 100%;
  height: 100%;
  padding: 0 calc(var(--base) * 2);
  margin-top: calc(var(--app-height) * 0.4);
  // margin-bottom: calc(var(--base) * 16);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-auto-rows: calc(var(--base) * 28.5);
  // grid-auto-rows: min-content;
  // column-gap: calc(var(--base) * 2.5);
  // column-gap: 0;
  gap: calc(var(--base) * 2);
  padding-bottom: 40%;

  @media (min-width: 1024px) {
    margin: 0 auto;
    margin-top: calc(var(--app-height) * 0.35);
    // margin-bottom: calc(var(--app-height) * 0.284114053);
    max-width: 77.51322751vw;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    // gap: 5%;
    // row-gap: calc(var(--base) * 10);
    padding-bottom: 4%;
    gap: calc(var(--base) * 4);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
  }
}

.filter--cat-container {
  position: fixed;
  left: 50%;
  top: calc(var(--app-height) * 0.2818590705);
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 450px;
  z-index: 150;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: calc(var(--base) * 2);
  row-gap: calc(var(--base) * 2);

  @media (min-width: 1024px) {
    width: auto;
    max-width: 100%;
    flex-direction: column;
    top: calc(var(--app-height) * 0.2861507128);
    top: 50%;
    left: calc(var(--app-height) * 0.03373015873);
    row-gap: calc(var(--base) * 3.625);
    transform: translate(0%, 0);
    transform: translate(0, -50%);
  }
}

.archive--filter-container {
  position: fixed;
  z-index: 200;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  top: calc(var(--app-height) * 0.2203898051 + calc(var(--base) * 2));
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.1975560081 + calc(var(--base) * 2));
  }
}
.v-main {
  height: 100%;
}
</style>
<style lang="scss">
.page-archive--header__subtitle span {
  font-size: calc(var(--base) * 2);
  line-height: calc(var(--base) * 2.5);
}
</style>
