<template>
  <div class="form-step--wrapper">
    <div class="form-creator-step-final form--content">
      <p class="greetings--text">{{ $t("message.forms.thanksRegister2") }}</p>
    </div>
    <router-link to="/" class="back-to-home--anchor">{{
      $t("message.forms.backToHome")
    }}</router-link>
  </div>
</template>

<script>
export default {
    name: "FormCreatorStep4",
    components: {
       
    },
    data() {
        return {
            isProfessional: true,
            fullName: "",
            email: "",
            phone: "",
            company: "",
            fullNameRules: [
                (v) => !!v || this.$t("message.forms.fullNameRequired"),
                (v) => v.length <= 50 || this.$t("message.forms.fullNameMaxLength"),
            ],
            emailRules: [
                (v) => !!v || this.$t("message.forms.emailRequired"),
                (v) => /.+@.+\..+/.test(v) || this.$t("message.forms.emailInvalid"),
            ],
            companyRules: [
                (v) => !!v || this.$t("message.forms.companyRequired"),
                (v) => v.length <= 50 || this.$t("message.forms.companyMaxLength"),
            ],
            pronounsItems: [
                { text: this.$t('message.forms.pronounHer'), value: "she/her" },
                { text: this.$t('message.forms.pronounHim'), value: "he/him" },
                { text: this.$t('message.forms.pronounThey'), value: "they/them" },
            ],
            pronouns: "",
            pronounsRules: [
                (v) => !!v || this.$t("message.forms.pronounsRequired"),
            ],
        };

    },
    };
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.2871690428);
  // max-height: calc(var(--app-height) * 0.2871690428);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3849287169);
  left: 50%;
  transform: translateX(-50%);
  .form--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 3.835978836vw;
    padding: 0 9.126984127vw;
    height: 100%;
  }
}
.greetings--text {
  font-size: calc(var(--base) * 2);
  line-height: calc(var(--base) * 3);
  font-weight: 500;
  text-align: center;
  max-width: 100%;
  width: 95vw;
  margin: 0 auto;
  @media (min-width: 1024px) {
    font-size: calc(var(--base) * 2.5);
    max-width: 47vw;
  }
}
.back-to-home--anchor {
  margin: 0 auto;
  text-align: center;
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * -0.2);
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * -0.08095952024);
  }
  &:hover{
    text-decoration: underline;
    font-weight: 700;
  }
}
</style>