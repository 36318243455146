var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-step--wrapper form-upload--step-3"},[_c('div',{staticClass:"form--content form-upload--step-3--content"},[_c('div',{staticClass:"lang-form-selector"},[_c('a',{staticClass:"lang-form-selector--item",class:_vm.activeLang === 'valenciano' ? 'selected' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.activeLang = 'valenciano'}}},[_vm._v(" Valencià ")]),_vm._v(" | "),_c('a',{staticClass:"lang-form-selector--item",class:_vm.activeLang === 'castellano' ? 'selected' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.activeLang = 'castellano'}}},[_vm._v(" Castellano ")])]),_c('div',{staticClass:"form--grid"},[_c('div',{staticClass:"form--column left-col"},[_c('div',{staticClass:"form--row"},[(_vm.activeLang === 'valenciano')?_c('v-textarea',{attrs:{"label":_vm.$t('message.forms.projectDescriptionCat') +
              ' ' +
              _vm.$t('message.forms.maxCharacters2000'),"placeholder":_vm.$t('message.forms.projectDescriptionCat') +
  ' ' +
  _vm.$t('message.forms.maxCharacters2000'),"rules":_vm.projectDescriptionRules,"required":"","outlined":"","dense":this.$vuetify.breakpoint.width < 1024,"counter":"","height":this.$vuetify.breakpoint.width < 1024 ? 'auto' : '228'},model:{value:(_vm.project.descriptionLongValencia),callback:function ($$v) {_vm.$set(_vm.project, "descriptionLongValencia", $$v)},expression:"project.descriptionLongValencia"}}):_c('v-textarea',{attrs:{"label":_vm.$t('message.forms.projectDescriptionEs') +
              ' ' +
              _vm.$t('message.forms.maxCharacters2000'),"placeholder":_vm.$t('message.forms.projectDescriptionEs') +
  ' ' +
  _vm.$t('message.forms.maxCharacters2000'),"rules":_vm.projectDescriptionRules,"required":"","outlined":"","dense":this.$vuetify.breakpoint.width < 1024,"height":this.$vuetify.breakpoint.width < 1024 ? 'auto' : '228',"counter":""},model:{value:(_vm.project.descriptionLongCastellano),callback:function ($$v) {_vm.$set(_vm.project, "descriptionLongCastellano", $$v)},expression:"project.descriptionLongCastellano"}})],1)]),_c('div',{staticClass:"form--column right-col"},[_c('v-file-input',{attrs:{"label":_vm.$t('message.forms.projectSelectFiles') +
            ' ' +
            _vm.$t('message.forms.maxFilesTen'),"prepend-inner-icon":"mdi-cloud-upload","counter":10,"prepend-icon":"","placeholder":_vm.$t('message.forms.projectSelectFiles') +
  ' ' +
  _vm.$t('message.forms.maxFilesTen'),"rules":_vm.projectImagesRules,"required":"","outlined":"","dense":this.$vuetify.breakpoint.width < 1024,"multiple":"","small-chips":"","clearable":""},model:{value:(_vm.projectImages),callback:function ($$v) {_vm.projectImages=$$v},expression:"projectImages"}}),_c('v-text-field',{attrs:{"label":_vm.$t('message.forms.projectLink'),"placeholder":_vm.$t('message.forms.projectLink'),"outlined":"","dense":this.$vuetify.breakpoint.width < 1024,"hint":this.$t('message.contact.optional'),"persistent-hint":""},model:{value:(_vm.projectLink1),callback:function ($$v) {_vm.projectLink1=$$v},expression:"projectLink1"}}),_c('v-text-field',{attrs:{"label":_vm.$t('message.forms.projectLink'),"placeholder":_vm.$t('message.forms.projectLink'),"outlined":"","dense":this.$vuetify.breakpoint.width < 1024,"hint":this.$t('message.contact.optional'),"persistent-hint":""},model:{value:(_vm.projectLink2),callback:function ($$v) {_vm.projectLink2=$$v},expression:"projectLink2"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }