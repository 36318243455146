<template>
  <button class="form-text-selector">
    {{ text }}
  </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
    },

}
</script>

<style lang="scss" scoped>
.form-text-selector {
  background: transparent;
  border: solid 1px rgb(var(--bodyColor));
  border-radius: calc(var(--base) * 1.25);
  color: rgba(var(--bodyColor), 0.5);
  font-size: calc(var(--base) * 2);
  line-height: calc(var(--base) * 3.874);
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  @media (min-width: 1024px) {
    font-size: calc(var(--base) * 3);
    border-radius: calc(var(--base) * 1.875);
    line-height: calc(var(--base) * 7.125);
    box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.23);
  }
  &:hover, &:focus {
    font-weight: 700;
    color: rgba(var(--bodyColor), 1);
  }
}
</style>