var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"panel-module--conversation",class:[
    _vm.isOpen && this.$vuetify.breakpoint.width < 1024 ? 'slide-left' : '',
  ],style:(this.cssVars)},[(this.$vuetify.breakpoint.width >= 1024 && this.isSuperAdmin)?_c('div',{staticClass:"admin-switch--wrapper"},[_c('div',{staticClass:"admin-switch user-panel--button",style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
      })},[_c('button',{staticClass:"admin-switch__button",class:[!_vm.isAdmin ? 'selected' : ''],on:{"click":function($event){return _vm.toggleAdmin()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.yourMessages"))+" ")]),_vm._v(" / "),_c('button',{staticClass:"admin-switch__button",class:[_vm.isAdmin ? 'selected' : ''],on:{"click":function($event){return _vm.toggleAdmin()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.administrative"))+" ")])])]):_vm._e(),_c('div',{staticClass:"panel-module--conversation--inner",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
          })},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light,"borderRadius":"16"}}):_vm._e(),_c('div',{staticClass:"conversation--header user-panel--header"},[(this.$vuetify.breakpoint.width < 1024)?_c('a',{staticClass:"conversation--header--back-button",attrs:{"href":"#"},on:{"click":function($event){return _vm.closeConversation()}}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-back.svg"),"alt":"back"}})]):_vm._e(),(_vm.isAdmin)?_c('div',{staticClass:"conversation--header--avatars"},[_c('div',{staticClass:"conversation--header--avatars__item",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
          })},[(_vm.mainUserAvatar)?_c('img',{attrs:{"src":_vm.mainUserAvatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.mainUserName)))])]),_c('div',{staticClass:"conversation--header--avatars__item",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
          })},[(_vm.secondaryUserAvatar)?_c('img',{attrs:{"src":_vm.secondaryUserAvatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.secondaryUserName)))])])]):_c('div',{staticClass:"conversation--header--avatar",style:({
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
        })},[(_vm.mainUserAvatar)?_c('img',{attrs:{"src":_vm.mainUserAvatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.mainUserName)))])]),(_vm.isAdmin)?_c('div',{staticClass:"conversation--header--title"},[_vm._v(" "+_vm._s(_vm.mainUserName)+" - "+_vm._s(_vm.secondaryUserName)+" ")]):_c('div',{staticClass:"conversation--header--title"},[_vm._v(" "+_vm._s(_vm.mainUserName)+" ")])]),_c('div',{staticClass:"conversation--content user-panel--content",style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      })},[_c('div',{staticClass:"conversation--content--inner"},[_c('div',{staticClass:"conversation--content--inner__messages",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          })},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"conversation--content--inner__messages__item",class:[
              message.user == _vm.mainUserId ? 'main-user' : 'secondary-user',
            ]},[_c('div',{staticClass:"conversation--content--inner__messages__item__text"},[_vm._v(" "+_vm._s(message.message)+" ")]),_c('div',{staticClass:"conversation--content--inner__messages__item__timestamp"},[_vm._v(" "+_vm._s(_vm.timeAgo(message.timestamp))+" ")])])}),0),_c('div',{staticClass:"conversation--content--inner__input"},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('message.admin.writeAMessage'),"label":_vm.$t('message.admin.writeAMessage'),"solo":"","rounded":"","flat":"","dense":true,"hide-details":true,"single-line":true,"clearable":true},on:{"click:append":function($event){return _vm.sendMessage()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('button',{staticClass:"message--submit-btn"},[_vm._v(" "+_vm._s(_vm.$t("message.contact.send"))+" ")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }