<template>
  <div class="form-step--wrapper form-upload--step-3">
    <div class="form--content form-upload--step-3--content">
      <div class="lang-form-selector">
        <a href="javascript:;" class="lang-form-selector--item"
          :class="activeLang === 'valenciano' ? 'selected' : ''" @click="activeLang = 'valenciano'">
          Valencià
        </a>
        |
        <a href="javascript:;" class="lang-form-selector--item"
          :class="activeLang === 'castellano' ? 'selected' : ''" @click="activeLang = 'castellano'">
          Castellano
        </a>
      </div>
      <div class="form--grid">
        <div class="form--column left-col">
          <div class="form--row">
            <!-- vuetify text area -->
            <v-textarea v-model="project.descriptionLongValencia" :label="
              $t('message.forms.projectDescriptionCat') +
              ' ' +
              $t('message.forms.maxCharacters2000')
            " :placeholder="
  $t('message.forms.projectDescriptionCat') +
  ' ' +
  $t('message.forms.maxCharacters2000')
" :rules="projectDescriptionRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024" v-if="activeLang === 'valenciano'"
              counter :height="this.$vuetify.breakpoint.width < 1024 ? 'auto' : '228'"></v-textarea>
            <v-textarea v-model="project.descriptionLongCastellano" :label="
              $t('message.forms.projectDescriptionEs') +
              ' ' +
              $t('message.forms.maxCharacters2000')
            " :placeholder="
  $t('message.forms.projectDescriptionEs') +
  ' ' +
  $t('message.forms.maxCharacters2000')
" :rules="projectDescriptionRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"
              :height="this.$vuetify.breakpoint.width < 1024 ? 'auto' : '228'" counter
              v-else></v-textarea>
          </div>
        </div>
        <div class="form--column right-col">
           <v-file-input v-model="projectImages" :label="
            $t('message.forms.projectSelectFiles') +
            ' ' +
            $t('message.forms.maxFilesTen')
          " prepend-inner-icon="mdi-cloud-upload" :counter="10" prepend-icon="" :placeholder="
  $t('message.forms.projectSelectFiles') +
  ' ' +
  $t('message.forms.maxFilesTen')
" :rules="projectImagesRules" required outlined
            :dense="this.$vuetify.breakpoint.width < 1024" multiple small-chips clearable>
          </v-file-input>
          <!-- vuetify text field for link -->
          <v-text-field v-model="projectLink1" :label="$t('message.forms.projectLink')"
            :placeholder="$t('message.forms.projectLink')" outlined
            :dense="this.$vuetify.breakpoint.width < 1024"
            :hint="this.$t('message.contact.optional')" persistent-hint></v-text-field>
          <v-text-field v-model="projectLink2" :label="$t('message.forms.projectLink')"
            :placeholder="$t('message.forms.projectLink')" outlined
            :dense="this.$vuetify.breakpoint.width < 1024"
            :hint="this.$t('message.contact.optional')" persistent-hint></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLang: "valenciano",
      projectImages: [],
      projectLink1: "",
      projectLink2: "",
      projectDescriptionRules: [
        (v) => !!v || this.$t("message.forms.requiredField"),
        (v) => !!v && v.length <= 2000 || this.$t("message.forms.maxCharacters2000"),
      ],
      projectImagesRules: [
        (v) => !!v || this.$t("message.forms.requiredField"),
        (v) => !!v && v.length <= 10 || this.$t("message.forms.maxFilesTen"),
      ],

      project: {
        descriptionLongCastellano: null,
        descriptionLongValencia: null,
        projectLinks: [
          {
            url: null,
            title: null
          }
        ],
      }


    };
  },
  watch: {
    // watch project.descriptionLongCastellano and project.descriptionLongValencia
    'project.descriptionLongCastellano': function (){
      this.updateData();
     },
    'project.descriptionLongValencia': function (){
      this.updateData();
     },
    projectImages: {
      handler: function () {
        this.updateData();
      },
      deep: true,
    },
    projectLink1: function () {
      this.updateData();
    },
    projectLink2: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.setProjectLinks();
      this.$emit("updateData", this.project, this.projectImages);
    },
    setProjectLinks() {
      this.project.projectLinks = [
        {
          url: this.projectLink1,
          title: this.projectLink1
        },
        {
          url: this.projectLink2,
          title: this.projectLink2
        }
      ];
    },
    updateProject(project) {
      this.project = project;
      this.projectImages = project.projectImages.map (image => {
        return {
          name: image.imageUrl.split("/").pop() ,
          url: image.imageUrl,
        }
      });
    },
  },
}

</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;

    .lang-form-selector {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 0.5);
      margin-bottom: calc(var(--base) * 2);

      a {
        font-size: calc(var(--base) * 2.25);

        &.selected {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .form--grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.835978836vw;
      }
    }

    .v-input__append-inner {
      .text-field--custom-icon {
        width: calc(var(--base) * 3.5);
        height: calc(var(--base) * 3.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .form--column {
      width: 100%;

      @media (min-width: 1024px) {
        width: 50%;
      }

      .form--row {
        display: flex;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 2.25);
        width: 100%;

        .form-input--year {
          width: 100%;
          max-width: 30%;
        }
      }
    }
  }
}
</style>