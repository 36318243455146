<template>
  <div class="page page-validation">
    <section class="form-step--container">
      <h1 class="form-step--title">
        {{ $t(`message.forms.resetPassword`) }}
      </h1>
        <div class="form-step--wrapper">
          <div class="form--content">
            <!-- Two v-text-fields for password confirmation -->
            <v-text-field
              v-model="email"
              :label="$t('message.forms.email')"
              :placeholder="$t('message.forms.email')"
              required
              outlined
              :dense="this.$vuetify.breakpoint.width < 1024"
              type='text'
            ></v-text-field>
          </div>
        </div>
      <div class="form-nav-buttons">
        <button
          class="form-nav-buttons--button"
          :disabled="false"
          type="submit"
          @click="handleEmailRecovery"
        >
          {{ $t("message.forms.next") }}
        </button>
      </div>
    </section>
  </div>
</template>
<script>

export default{
    name: 'RecoverPassword',

    methods:{
      handleEmailRecovery() {
      this.sending = true;
      this.loading = true;
      this.$store
        .dispatch('auth/recoverPassword', this.email)
        .then(
          
          () => {
            this.loading = false;
            this.message = 'Email with recovery link was sent';
            this.sending = false;
            //wait 1 sec and router push to name LoginCug
            setTimeout(() => {
              this.$router.push({ name: 'RecoverPasswordGreetings' });
            }, 100);
            
          },
          (error) => {
            this.loading = false;
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
            this.sending = false;
          },
        );
    },
    },
    data(){
        return{
            email: null,
            loading: false,
            sending: false,
            message: '',
        }
    },

}</script>
<style lang="scss" scoped>
.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.08095952024);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.2474541752);
  }
  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;

    cursor: pointer;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}
.form-step--title {
  position: absolute;
  top: calc(var(--app-height) * 0.1709145427);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: calc(var(--base) * 3);
  font-weight: 700;
  text-align: center;
  padding: 0 calc(var(--base) * 2);
  // margin-bottom: calc(var(--app-height) * 0.06446776612);
  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2657841141);
    font-size: calc(var(--base) * 4);
    // margin-bottom: calc(var(--app-height) * 0.08350305499);
  }
}
.form-step--wrapper {
  height: 100%;
  min-height: calc(var(--app-height) * 0.5247376312);
  max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
    justify-content: center;
  @media (min-width: 1024px) {
    min-height: calc(var(--app-height) * 0.2871690428);
    max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }
  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 0;
    row-gap: calc(var(--base) * 2);
    .v-input {
      width: 100%;
      max-width: 86.93333333vw;
    }
    @media (min-width: 1024px) {
      .v-input {
        max-width: 39.48412698vw;
      }
    }
  }
}
</style>
