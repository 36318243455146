<template>
  <div class="gradient-scroll">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.gradient-scroll {
  // background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  width: 100%;
  height: calc(var(--app-height) * 0.4);
  // backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  z-index: 140;
  z-index: 1;
  display: none;

  @media (min-width: 1024px) {
    height: calc(var(--app-height) * 0.40);
    // background:red;
    display: block;
    transform: translateY(calc(var(--app-height) * -0.1));
    div {
      position: absolute;
      inset: 0;
    }
    div:nth-of-type(1) {
      z-index: 2;
      backdrop-filter: blur(1px);
      mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 12.5%,
        rgb(255, 255, 255) 37.5%,
        rgba(255, 255, 255, 0) 50%
      );
      -webkit-mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 12.5%,
        rgb(255, 255, 255) 37.5%,
        rgba(255, 255, 255, 0) 50%
      );
    }
    div:nth-of-type(2) {
      z-index: 3;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 12.5%,
        rgb(255, 255, 255) 37.5%,
        rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0) 62.5%
      );
      -webkit-mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 12.5%,
        rgb(255, 255, 255) 37.5%,
        rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0) 62.5%
      );
    }
    div:nth-of-type(3) {
      backdrop-filter: blur(4px);
      z-index: 4;
      mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 37.5%,
        rgb(255, 255, 255) 50%,
        rgb(255, 255, 255) 62.5%,
        rgba(255, 255, 255, 0) 75%
      );
      -webkit-mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 37.5%,
        rgb(255, 255, 255) 50%,
        rgb(255, 255, 255) 62.5%,
        rgba(255, 255, 255, 0) 75%
      );
    }
    div:nth-of-type(4) {
      z-index: 5;
      backdrop-filter: blur(8px);
      mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 50%,
        rgb(255, 255, 255) 62.5%,
        rgb(255, 255, 255) 75%,
        rgba(255, 255, 255, 0) 87.5%
      );
      -webkit-mask: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 50%,
        rgb(255, 255, 255) 62.5%,
        rgb(255, 255, 255) 75%,
        rgba(255, 255, 255, 0) 87.5%
      );
    }
  }
}
</style>
