<template>
  <div class="burger-nav" :class="[navOpen ? 'open' : '']">
    <button class="burger-nav--toggle" @click="toggleNav()">
      <div class="burger-nav--toggle--inner">
        <span class="burger-nav--toggle--inner__line"></span>
        <span class="burger-nav--toggle--inner__line"></span>
        <span class="burger-nav--toggle--inner__line"></span>
      </div>
    </button>
    <nav class="burger-nav--overlay">
      <button class="burger-nav--overlay--toggle" @click="toggleNav()">
        <span class="burger-nav--overlay--toggle__icon">
          <img src="@/assets/icons/icon-cross.svg" alt="" />
        </span>
        <span class="burger-nav--overlay--toggle__text">{{
          $t("message.general.close")
        }}</span>
      </button>
      <ul class="burger-nav--overlay--menu">
        <li>
          <button @click="navigate('AboutCug')">
            {{ $t("message.menu.items[0]") }}
          </button>
        </li>
        <li>
          <button @click="navigate('ArchiveCug')">
            {{ $t("message.menu.items[1]") }}
          </button>
        </li>
        <li>
          <button @click="navigate('MapCug')">
            {{ $t("message.menu.items[2]") }}
          </button>
        </li>
        <li>
          <button @click="navigate('ContactCug')">
            {{ $t("message.menu.items[3]") }}
          </button>
        </li>
        <li>
          <button @click="navigate('LoginCug')">
            {{ $t("message.menu.items[4]") }}
          </button>
        </li>
        <li>
          <a
            @click="downloadGuide"
            class="flex justify-center items-center gap-2 bg-white !text-black px-4 py-2 rounded-lg text-center text-lg font-medium leading-none"
          >
            <img src="~@/assets/icons/icon-download-black.svg" alt="" />
            {{ $t("message.home.guide.button") }}
          </a>
        </li>
        <li>
          <a
           href="https://cugnews.wordpress.com/"
            class="flex justify-center items-center gap-2 bg-white !text-black px-4 py-2 rounded-lg text-center text-lg font-medium leading-none"
          >
            <img src="~@/assets/icons/icon-wordpress-black.svg" alt="" />
            {{ $t("message.about.blog.button") }}
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
            target="_blank"
            class="flex justify-center items-center gap-2 bg-white !text-black px-4 py-2 rounded-lg text-center text-lg font-medium leading-none"
          >
            <img src="~@/assets/icons/icon-youtube-black.svg" alt="" />
            Youtube
          </a>
        </li>
      </ul>
      <change-language-button></change-language-button>
    </nav>
  </div>
</template>

<script>
import ChangeLanguageButton from "@/components/Languages/ChangeLanguageButton.vue";

export default {
  name: "BurgerNavMovil",
  components: {
    ChangeLanguageButton,
  },
  data() {
    return {
      navOpen: false,
      canDownload: true,
      downloadCooldown: 5000, // 5 segundos de cooldown
    };
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
      if (this.navOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    navigate(route) {
      this.toggleNav();
      this.$router.push({ name: route });
    },
    downloadGuide() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px blue;
// }
.burger-nav {
  //boton toggle
  .burger-nav--toggle {
    position: fixed;
    transform: scale(0.8);
    transform-origin: right top;
    right: calc(var(--base) * 2);
    top: calc(var(--base) * 3.375);
    z-index: 200;
    min-width: calc(var(--base) * 7.5);
    min-height: calc(var(--base) * 7.5);
    width: calc(var(--base) * 7.5);
    height: calc(var(--base) * 7.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 0.5);
    background-color: rgb(var(--bodyColor));
    .burger-nav--toggle--inner {
      position: relative;
      background-color: rgb(var(--bodyColor));
      border: solid 2px rgb(var(--bodyBg));
      border-radius: calc(var(--base) * 1.25);
      width: 100%;
      max-width: calc(var(--base) * 6.5);
      height: 100%;
      max-height: calc(var(--base) * 6.5);
      padding: calc(var(--base) * 0.3125);
      overflow: hidden;
      &__line {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: calc(var(--base) * 0.3125);
        height: calc(var(--base) * 0.6);
        width: calc(100% - calc(var(--base) * 0.625));
        background-color: rgb(var(--bodyBg));
        border-radius: calc(var(--base) * 2);
        opacity: 1;
        transition: all ease-out 0.3s;
        &:nth-child(1) {
          top: calc(var(--base) * 1.3);
        }
        &:nth-child(2) {
          top: calc(var(--base) * 2.6);
        }
        &:nth-child(3) {
          top: calc(var(--base) * 3.9);
        }
      }
    }
  }
  //overlay menu
  .burger-nav--overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100vw;
    height: var(--app-height);
    background-color: rgb(var(--bodyColor));
    background-image: url(@/assets/images/bg-nav-movil.svg);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    // align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all ease-out 0.3s;
    padding-top:12vh;
    .burger-nav--overlay--toggle {
      position: absolute;
      top: calc(var(--base) * 4.5);
      right: calc(var(--base) * 2.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(var(--bodyBg));
      text-transform: uppercase;
      font-size: calc(var(--base) * 1.25);
      line-height: calc(var(--base) * 2.5);
      &__icon {
        margin-bottom: calc(var(--base) * 0.5);
      }
    }
    .burger-nav--overlay--menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: calc(var(--base) * 2);
      padding: 0 calc(var(--base) * 8);
      & li {
        button {
          font-size: calc(var(--base) * 3);
          line-height: calc(var(--base) * 5);
          color: rgb(var(--bodyBg));
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }
  }
  &:hover {
    .burger-nav--toggle--inner__line {
      &:nth-child(1) {
        top: calc(var(--base) * 1.1);
      }
      &:nth-child(2) {
        top: calc(var(--base) * 2.6);
      }
      &:nth-child(3) {
        top: calc(var(--base) * 4.1);
      }
    }
  }
}
.burger-nav.open {
  .burger-nav--overlay {
    opacity: 1;
    visibility: visible;
  }
  .burger-nav--toggle--inner {
    &__line {
      position: absolute;
      &:nth-child(1) {
        top: 50%;
        transform: translate(0, -50%) rotate(45deg);
      }
      &:nth-child(2) {
        left: -100%;
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }
}
</style>
