<template>
  <footer class="footer-cug" :class="bg">
    <div class="footer-cug--inner">
      <div class="footer-cug--brand-container">
        <div class="footer-cug--brand-container__logo">
          <img src="@/assets/images/logo-footer.svg" alt="logo cug" />
        </div>
        <div class="footer-cug--brand-container__text">
          <span>Cátedra de urbanisme i gènere DPA UPV</span>
          <span
            >Proudly baked by
            <a target="_blank" href="https://contra-architecture.com/">CONTRA</a
            >, developed by
            <a target="_blank" href="https://mdtec.es/">MDTEC</a>.</span
          >
          <span>©2022. All Rights Reserved</span>
        </div>
      </div>
      <div class="footer-cug--main-container">
        <div class="footer-cug--main-container--inner h-full">
          <div class="left-column w-[60%] min-h-full flex flex-col justify-end">
            <div class="socials flex w-full flex-col items-start gap-6 my-12">
              <div
                class="socials--text text-white text-xl font-normal leading-5"
              >
                {{ $t("message.footer.socials") }}
              </div>
              <div
                class="socials--buttons flex items-start content-start gap-4 self-stretch flex-wrap"
              >
                <a
                  href="https://www.instagram.com/cug_upvgva/"
                  target="_blank"
                  class="flex justify-center items-center gap-2 border bg-white !px-4 py-2 rounded-[0.625rem] border-solid border-white !text-black text-justify text-xl font-normal leading-[normal]"
                >
                  <img src="@/assets/icons/icon-instagram-black.svg" alt="" />
                  Instagram
                </a>
                <a
                  href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
                  target="_blank"
                  class="flex justify-center items-center gap-2 border bg-white !px-4 py-2 rounded-[0.625rem] border-solid border-white !text-black text-justify text-xl font-normal leading-[normal]"
                >
                  <img src="@/assets/icons/icon-youtube-black.svg" alt="" />
                  Youtube
                </a>
                <a
                  href="https://cugnews.wordpress.com/"
                  target="_blank"
                  class="flex justify-center items-center gap-2 border bg-white !px-4 py-2 rounded-[0.625rem] border-solid border-white !text-black text-justify text-xl font-normal leading-[normal]"
                >
                  <img src="@/assets/icons/icon-wordpress-black.svg" alt="" />
                  Blog
                </a>
              </div>
            </div>
          </div>
          <div class="right-column">
            <nav class="footer-nav">
              <ul class="flex justify-end">
                <li>
                  <router-link :to="{ name: 'AboutCug' }">{{
                    $t("message.menu.items[0]")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ContactCug' }">{{
                    $t("message.menu.items[3]")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ArchiveCug' }">{{
                    $t("message.menu.items[1]")
                  }}</router-link>
                </li>
                <li>
                  <a @click="downloadClick">
                    {{ $t("message.home.guide.button") }}</a
                  >
                </li>
              </ul>
            </nav>

            <div class="footer-sponsors mt-auto">
              <a target="_blank" href="https://www.gva.es/">
                <img
                  src="@/assets/images/logo-generalitat-valenciana.svg"
                  alt="Logo Generalitat Valenciana"
                />
              </a>
              <a target="_blank" href="https://www.upv.es/">
                <img src="@/assets/images/logo-upv.svg" alt="logo Upv" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCug",
  props: {
    bg: {
      type: String,
      default: function () {
        return "bg-white";
      },
      required: false,
    },
  },
  methods: {
    downloadClick() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-cug {
  position: relative;

  padding-top: calc(var(--base) * 4.125);
  &--inner {
    width: 87.3%;
    margin: 0 auto;
  }
  .footer-cug--brand-container {
    position: absolute;
    top: 0;
    left: calc(var(--base) * 6.875);
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    color: rgb(var(--bodyBg));
    column-gap: calc(var(--base) * 1.5);
    &__logo {
      width: calc(var(--base) * 35);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      // max-width:calc(var(--base) * 36);
      padding-bottom: calc(var(--base) * 2.4);
      font-size: calc(var(--base) * 1.625);
      line-height: 125.7%;
      a {
        color: rgb(var(--bodyBg));
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .footer-cug--main-container {
    background-color: rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 6.25) calc(var(--base) * 6.25) 0 0;
    padding: calc(var(--base) * 0.875);
    padding-bottom: 0;
    .footer-cug--main-container--inner {
      border-radius: calc(var(--base) * 5.625) calc(var(--base) * 5.625) 0 0;
      border: solid 2px rgb(var(--bodyBg));
      border-bottom: none;
      display: flex;
      flex-wrap: nowrap;
      min-height: calc(var(--base) * 35.75);

      .left-column {
        width: 60%;

        padding-left: calc(var(--base) * 6);
        .newsletter--wrapper {
          margin-top: calc(var(--base) * 17.38);
          color: rgb(var(--bodyBg));
          font-size: calc(var(--base) * 2.5);
          max-width: calc(var(--base) * 47.75);
          &__text {
            margin-bottom: calc(var(--base) * 2.5);
          }
          &__form {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            column-gap: calc(var(--base) * 1.5);
            .v-input--dense > .v-input__control > .v-input__slot {
              margin-bottom: none;
            }
            .submit-button {
              margin-top: -2px;
              background-color: rgb(var(--bodyColor));
              color: rgb(var(--bodyBg));
              border: solid 1px rgb(var(--bodyBg));
              border-radius: calc(var(--base) * 1.25);
              padding: calc(var(--base) * 0.625) calc(var(--base) * 1.25);
              font-size: calc(var(--base) * 2.5);
              cursor: pointer;
              transition: all 0.3s ease;
              &:hover {
                background-color: rgb(var(--bodyBg));
                color: rgb(var(--bodyColor));
              }
            }
          }
        }
      }
      .right-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
        padding-right: calc(var(--base) * 6);
        padding-top: calc(var(--base) * 2);
        .footer-nav {
          margin-bottom: calc(var(--base) * 2.5);
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: calc(var(--base) * 2);
            li {
              font-size: calc(var(--base) * 3);
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              &:after {
                content: "|";
                color: rgb(var(--bodyBg));
                margin: 0 calc(var(--base) * 2);
                margin-right: 0;
                font-size: calc(var(--base) * 5);
              }
              &:last-child:after {
                display: none;
              }
              a {
                color: rgb(var(--bodyBg));
                text-decoration: none;
                text-transform: uppercase;
                transition: all ease-out 0.3s;
                font-weight: 400;
                &:hover {
                  font-weight: 600;
                }
              }
            }
          }
        }
        .footer-socials {
          display: flex;
          flex-wrap: nowrap;
          column-gap: calc(var(--base) * 2);
          margin-bottom: calc(var(--base) * 3.75);
          a {
            max-width: calc(var(--base) * 6);
            img {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }
        .footer-sponsors {
          display: flex;
          justify-content: flex-end;
          flex-wrap: nowrap;
          column-gap: calc(var(--base) * 4);
          margin-bottom: calc(var(--base) * 6.25);

          a {
            max-width: 50%;
            img {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
