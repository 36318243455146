<template>
  <div
    class="page page-about"
    v-if="backgroundImage()"
    :style="{ backgroundImage: `${backgroundImage()}` }"
  >
    <section class="about-cug-section">
      <div class="blob-card left">
        <div class="blob-card--inner">
          <div class="blob-card--inner__header">
            <img
              src="@/assets/images/blob-left-movil.svg"
              alt=""
              v-if="this.$vuetify.breakpoint.width < 1024"
            />
            <img src="@/assets/images/blob-left.svg" alt="" v-else />
            <h4 class="blob-card--inner__header--title">
              {{ $t("message.home.info.1.title") }}
            </h4>
          </div>
          <div class="blob-card--inner__content">
            <p v-html="this.$t('message.home.info.1.description1')"></p>
            <p
              v-if="this.$vuetify.breakpoint.width >= 1024"
              v-html="this.$t('message.home.info.1.description2')"
            ></p>
            
          </div>
        </div>
      </div>
      <div class="blob-card right">
        <div class="blob-card--inner">
          <div class="blob-card--inner__header">
            <img src="@/assets/images/blob-right.svg" alt="" />
            <h4>{{ $t("message.home.info.2.title") }}</h4>
          </div>
          <div
            class="blob-card--inner__content"
            v-html="this.$t('message.home.info.2.description')"
          ></div>
        </div>
      </div>
    </section>
    <section
      class="guide-section flex w-full flex-col lg:flex-row items-start gap-16 shrink-0 bg-cug-green p-6 py-12 lg:py-16 lg:px-20 text-black overflow-hidden"
    >
      <div
        class="guide__copy flex flex-col items-start gap-6 w-full lg:max-w-[500px] lg:w-[33%] lg:min-w-[300px]"
      >
        <h3
          class="guide__copy--title text-[24px] lg:text-[32px] font-semibold leading-[normal]"
        >
          {{ $t("message.home.guide.title") }}
        </h3>
        <p
          class="guide__copy--description text-justify text-base font-semibold leading-6"
          v-html="$t('message.home.guide.description')"
        ></p>
        <div
          class="guide__buttons w-full lg:w-auto flex flex-col items-center lg:items-start gap-4 lg:gap-6"
        >
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-white px-4 py-2 rounded-[0.625rem] border-solid border-white text-black text-xl leading-[normal]"
            @click="downloadClick"
          >
            <img src="~@/assets/icons/icon-download-black.svg" alt="" />
            {{ $t("message.home.guide.button") }}
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-white px-4 py-2 rounded-[0.625rem] border-solid border-white text-black text-xl leading-[normal]"
            @click="downloadEnglish"
          >
            <img src="~@/assets/icons/icon-download-black.svg" alt="" />
            Download Guide
          </button>
        </div>
      </div>
      <div class="guide__images flex-1 h-full relative hidden lg:flex">
        <div class="img-holder absolute w-full min-h-full top-0 left-0">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide01.webp"
            alt=""
          />
        </div>
        <div class="img-holder absolute w-full min-h-full top-0 left-48">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide02.webp"
            alt=""
          />
        </div>
        <div class="img-holder absolute w-full min-h-full top-0 left-96">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide03.webp"
            alt=""
          />
        </div>
      </div>
    </section>
    <section
      class="youtube-section flex lg:flex-row-reverse w-full items-start gap-[63px] shrink-0 bg-cug-yellow p-6 py-12 lg:py-16 lg:px-20"
    >
      <div
        class="youtube-copy flex flex-col items-start gap-6 lg:max-w-[400px]"
      >
        <h3
          class="youtube-copy--title text-black text-2xl lg:text-[32px] font-semibold leading-[normal]"
        >
          {{ $t("message.about.youtube.title") }}
        </h3>
        <p
          class="youtube-copy--description self-stretch text-black text-justify text-base font-normal leading-6"
        >
          {{ $t("message.about.youtube.description") }}
        </p>
        <a
          href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
          target="_blank"
          class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
        >
          <img src="@/assets/icons/icon-youtube-white.svg" alt="" />
          Youtube
        </a>
      </div>
      <div class="youtube-embed flex-1 aspect-video hidden lg:flex">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/6bSUfSbfMO8?si=0UmGAXnpY0D5a4f3"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section
      class="blog-section flex flex-row w-full items-start gap-[63px] shrink-0 bg-cug-purple p-6 py-12 lg:py-16 lg:px-20"
    >
      <div class="blog-copy flex flex-col items-start gap-6 lg:max-w-[400px]">
        <h3
          class="blog-copy--title text-black text-2xl lg:text-[32px] font-semibold leading-[normal]"
        >
          {{ $t("message.about.blog.title") }}
        </h3>
        <p
          class="blog-copy--description self-stretch text-black text-justify text-base font-normal leading-6"
        >
          {{ $t("message.about.blog.description") }}
        </p>
        <a
          href="https://cugnews.wordpress.com/"
          target="_blank"
          class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
        >
          <img src="@/assets/icons/icon-wordpress-white.svg" alt="" />
          {{ $t("message.about.blog.button") }}
        </a>
      </div>
      <div class="blog-image flex-[1_0_0] self-stretch rounded-4xl">
        <img
          class="w-full h-full object-cover"
          src="@/assets/images/blogImage.png"
          alt=""
        />
      </div>
    </section>
    <section
      class="contact-section flex flex-col lg:flex-row w-full items-start gap-8 lg:gap-16 shrink-0 bg-cug-pink p-6 py-12 lg:py-16 lg:px-20"
    >
      <div class="contact-title flex flex-col items-start gap-6 flex-1">
        <h3 class="text-black text-4xl lg:text-5xl font-normal leading-none">
          {{ $t("message.about.contact.title") }}
        </h3>
      </div>
      <div class="contact-copy flex flex-col gap-10 lg:max-w-[400px]">
        <div class="w-full flex flex-col gap-2">
          <h4
            class="blog-copy--title text-black text-2xl lg:text-[32px] font-semibold leading-[normal]"
          >
            {{ $t("message.home.contact.socials.title") }}
          </h4>
          <p
            class="blog-copy--description self-stretch text-black text-justify text-base font-normal leading-6"
          >
            {{ $t("message.home.contact.socials.description") }}
          </p>
          <a
            href="https://www.instagram.com/cug_upvgva/"
            target="_blank"
            class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
          >
            <img src="@/assets/icons/icon-instagram-white.svg" alt="" />
            Instagram
          </a>
        </div>
        <div class="w-full flex flex-col gap-2">
          <h4
            class="blog-copy--title text-black text-2xl lg:text-[32px] font-semibold leading-[normal]"
          >
            {{ $t("message.home.contact.mail.title") }}
          </h4>
          <p
            class="blog-copy--description self-stretch text-black text-justify text-base font-normal leading-6"
          >
            {{ $t("message.home.contact.mail.description") }}
          </p>
          <router-link
            to="/contacte"
            class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
          >
            <img src="@/assets/icons/icon-send-white.svg" alt="" />
            {{ $t("message.home.contact.mail.button") }}
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlImageDesktop: require("@/assets/images/bg-about-desktop.svg"),
      urlImageMovil: require("@/assets/images/bg-about-movil.svg"),
    };
  },
  methods: {
    backgroundImage() {
      if (this.$vuetify.breakpoint.width >= 1024) {
        return "url(" + this.urlImageDesktop + ")";
      }
      return "url(" + this.urlImageMovil + ")";
    },
    downloadClick() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
    downloadEnglish() {
      window.open(
        "https://politicaterritorial.gva.es/documents/20551182/174929742/4.+GUIA+PERSPECTIVA+DE+GENERO+EN+ACTUACIONES+URBANAS_Ingles.pdf/ebcc94b7-706a-3b53-1611-735459548c9b?t=1675064927039",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-about {
  padding-top: calc(var(--app-height) * 0.203898051);

  @media (min-width: 1024px) {
    padding-top: calc(var(--app-height) * 0.02);
  }
}

.donwload-dossier-button {
  font-size: calc(var(--base) * 2);
  font-weight: 400;
  background-color: rgb(var(--bodyColor));
  color: rgb(var(--bodyBg)) !important;
  padding: calc(var(--base) * 0.2) calc(var(--base) * 2);
  border-radius: calc(var(--base) * 1.25);
  width: 100%;
  display: block;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: calc(var(--base) * 3);
    width: auto;
    display: inline;
  }
}

//About section
.about-cug-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(var(--base) * 5);
  margin-bottom: calc(var(--base) * 7.5);

  @media (min-width: 1024px) {
    position: relative;
    display: block;
    min-height: calc(var(--base) * 112.75);
    margin-top: calc(var(--base) * 10);
    width: 100vw;
    height: 100%;
  }

  .blob-card {
    @media (min-width: 1024px) {
      position: absolute;
    }

    &.left {
      width: 100%;

      @media (min-width: 1024px) {
        left: calc(var(--base) * 12);
        top: calc(var(--base) * 9);
        width: 42vw;
      }

      .blob-card--inner {
        &__header {
          img {
            object-fit: contain;
          }
        }

        &__content {
          left: 54%;
        }
      }
    }

    &.right {
      width: 100%;

      @media (min-width: 1024px) {
        right: calc(var(--base) * 12);
        top: calc(var(--base) * 37);
        width: 49vw;

        .blob-card--inner {
          &__header {
            img {
              object-fit: contain;
            }
          }

          &__content {
            padding: calc(var(--base) * 4.5) calc(var(--base) * 8.25);
            padding-right: calc(var(--base) * 7.88);
            width: 83%;
            left: 50%;
          }
        }
      }
    }

    .blob-card--inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      width: 100%;

      &__header {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100%;
        min-height: calc(var(--base) * 12);
        // max-height: calc(var(--base) * 12);
        padding: 0 calc(var(--base) * 2);

        @media (min-width: 1024px) {
          min-height: calc(var(--base) * 24);
          max-height: calc(var(--base) * 24);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          @media (min-width: 1024px) {
            object-fit: cover;
          }
        }

        h4 {
          width: 100%;
          text-align: center;
          font-size: calc(var(--base) * 3.75);
          line-height: 1;
          font-weight: 600;
          position: absolute;
          left: 50%;
          top: 45%;
          transform: translate(-50%, -50%);
          z-index: 10;

          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 8);
            top: 43%;
          }

          @media (min-width: 1440px) {
            font-size: calc(var(--base) * 8);
            top: 50%;
          }
        }
      }

      &__content {
        background-color: rgb(var(--bodyBg));
        border-radius: 0 0 calc(var(--base) * 3.75) calc(var(--base) * 3.75);
        padding: calc(var(--base) * 4.5) calc(var(--base) * 2.375);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 70%;
        margin-top: calc(var(--base) * -6);
        text-align: justify;
        padding-bottom: calc(var(--base) * 2.375);

        @media (min-width: 1024px) {
          margin-top: 0;
          position: absolute;
          top: calc(var(--base) * 16);
          transform: translateX(-50%);
          width: 78%;
          padding: calc(var(--base) * 4.5) calc(var(--base) * 2.5);
          padding-right: calc(var(--base) * 8);
          text-align: left;
        }

        @media (min-width: 1440px) {
          top: calc(var(--base) * 18);
        }

        p {
          font-size: calc(var(--base) * 2);
          line-height: calc(var(--base) * 3);
        }
      }
    }
  }
}
</style>
