import { render, staticRenderFns } from "./FormTextSelector.vue?vue&type=template&id=3f147157&scoped=true"
import script from "./FormTextSelector.vue?vue&type=script&lang=js"
export * from "./FormTextSelector.vue?vue&type=script&lang=js"
import style0 from "./FormTextSelector.vue?vue&type=style&index=0&id=3f147157&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f147157",
  null
  
)

export default component.exports