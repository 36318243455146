import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/css/vuetify-variables.scss"; // Importa las variables de Vuetify

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#b0bec5",
        accent: "#000000",
        error: "#b71c1c",
        black: "#090909",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  // Añade esta línea para desactivar las utility classes
  options: {
    customProperties: true, // Habilita custom properties si es necesario
    // Desactiva las utility classes
    minifyTheme: (css) => css.replace(/[\s|\r\n|\r|\n]/g, ""),
  },
};

export default new Vuetify(opts);
