<template>
    <div class="images-table-item">
        <div class="images-table-item--preview">
            <slot name="preview-image"></slot>
        </div>
        <div class="images-table-item--name">
            <slot name="name-image"></slot>
        </div>
        <div class="images-table-item--actions">
            <div class="images-table-item--actions--delete">
                <slot name="delete-image"></slot>
            </div>
            <div class="images-table-item--actions--open">
                <slot name="open-image"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.images-table-item {
    position: relative;
    width:100%;
    height:100%;
    // max-width: calc(var(--base)* 17);
    min-width: 40%;
    max-height:15vh;
    // max-height: calc(var(--base)* 17);
    aspect-ratio: 1/1;
    // @media(min-width:1024px){
    //     min-width: %;
    //     max-height:15vh;
    // }

    .images-table-item--preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: calc(var(--base)* 1);
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .images-table-item--name {
        position: absolute;
        bottom: calc(var(--base)* -3);
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
        visibility: 1;
        text-align: center;
        width:150%;
    }

    .images-table-item--actions {
        mix-blend-mode: difference;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap:calc(var(--base)* 1);
    }


}</style>