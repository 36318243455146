<template>
  <button
    class="feedback-btn"
    :class="[isSelected ? 'selected' : '']"
    @click="updateInsight()"
  >
    <div class="feedback-btn--inner">
      <div class="feedback-btn--inner__icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="type === 'stars'"
        >
          <path
            d="M11.0746 2.633C11.3946 1.789 12.6056 1.789 12.9266 2.633L14.9966 8.367C15.0688 8.55379 15.1959 8.71428 15.3613 8.8273C15.5266 8.94031 15.7223 9.00053 15.9226 9H21.0096C21.9496 9 22.3596 10.17 21.6206 10.743L18.0006 14C17.8384 14.1247 17.7199 14.2975 17.6621 14.4937C17.6042 14.6898 17.61 14.8993 17.6786 15.092L19.0006 20.695C19.3226 21.595 18.2806 22.368 17.4926 21.814L12.5756 18.694C12.4072 18.5757 12.2064 18.5122 12.0006 18.5122C11.7948 18.5122 11.594 18.5757 11.4256 18.694L6.50856 21.814C5.72156 22.368 4.67856 21.594 5.00056 20.695L6.32257 15.092C6.39114 14.8993 6.39692 14.6898 6.33907 14.4937C6.28122 14.2975 6.16272 14.1247 6.00057 14L2.38056 10.743C1.64056 10.17 2.05256 9 2.99056 9H8.07756C8.27786 9.00067 8.47363 8.9405 8.63898 8.82747C8.80433 8.71444 8.93147 8.55387 9.00357 8.367L11.0736 2.633H11.0746Z"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-else
        >
          <path
            d="M7 3C4.239 3 2 5.216 2 7.95C2 10.157 2.875 15.395 11.488 20.69C11.6423 20.7839 11.8194 20.8335 12 20.8335C12.1806 20.8335 12.3577 20.7839 12.512 20.69C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3C14.239 3 12 6 12 6C12 6 9.761 3 7 3Z"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            default: 'likes',
        },
        initialSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    mounted() {
      if(this.initialSelected){
        this.isSelected = this.initialSelected;
      }
    },
    methods: {
        updateInsight() {
            this.isSelected = !this.isSelected;
        },
    },

}
</script>

<style lang="scss" scoped>
.feedback-btn {
  cursor: pointer;
  width: calc(var(--base) * 5.375);
  height: calc(var(--base) * 5.375);
  border: solid 1px rgb(var(--bodyColor));
  border-radius: calc(var(--base) * 1.25);
  overflow: hidden;
  box-shadow: 4px 2px 8px 1px rgba(0, 0, 0, 0.2);
  .feedback-btn--inner {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        height:100%;
      svg {
        path {
          stroke: rgb(var(--bodyColor));
        }
      }
    }
  }
  &.selected {
    svg {
      path {
        fill: rgb(var(--bodyColor));
      }
    }
  }
}
</style>