var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page-map"},[_c('div',{staticClass:"map-container"},[_c('div',{staticClass:"map--search-filter"},[(_vm.projects.length === 0)?_c('div',{staticClass:"no-results"},[_c('h2',{staticClass:"no-results__title",domProps:{"textContent":_vm._s(this.$t('message.archive.noResults'))}})]):_vm._e(),_c('archive-filter',{on:{"updateFilter":_vm.updateFilter}})],1),_c('div',{staticClass:"map--cat-filter"},[_c('filter-cat-selector-item',{attrs:{"cat":_vm.$getProjectCategory('SOSTENIBILIDAD'),"isRouteUp":_vm.isRouteUp},on:{"updateFilter":_vm.updateType}}),_c('filter-cat-selector-item',{attrs:{"cat":_vm.$getProjectCategory('INSTALACIONES'),"isRouteUp":_vm.isRouteUp},on:{"updateFilter":_vm.updateType}}),_c('filter-cat-selector-item',{attrs:{"cat":_vm.$getProjectCategory('ESPACIOS_COMUNES'),"isRouteUp":_vm.isRouteUp},on:{"updateFilter":_vm.updateType}}),_c('filter-cat-selector-item',{attrs:{"cat":_vm.$getProjectCategory('SEGURIDAD'),"isRouteUp":_vm.isRouteUp},on:{"updateFilter":_vm.updateType}}),_c('filter-cat-selector-item',{attrs:{"cat":_vm.$getProjectCategory('SOCIAL'),"isRouteUp":_vm.isRouteUp},on:{"updateFilter":_vm.updateType}})],1),(this.$vuetify.breakpoint.width >= 1024)?[(_vm.selected)?_c('div',{staticClass:"map--tooltip"},[_c('div',{staticClass:"map--tooltip--inner"},[_c('div',{staticClass:"left-col"},[_c('img',{attrs:{"src":_vm.selected.image,"alt":""}})]),_c('div',{staticClass:"right-col"},[_c('h4',{staticClass:"tooltip--title",domProps:{"innerHTML":_vm._s(
              this.$root.$i18n.locale == 'es'
                ? this.trimString(_vm.selected.titleCastellano, 60)
                : this.trimString(_vm.selected.titleValencia, 60)
            )}}),_c('div',{staticClass:"tooltip--author-date"},[_vm._v(" "+_vm._s(this.$root.$i18n.locale == "es" ? _vm.selected.authorCastellano : _vm.selected.authorValencia)+", "+_vm._s(_vm.selected.year)+" ")]),_c('div',{staticClass:"tooltip--description"},[_vm._v(" "+_vm._s(_vm.selected.description)+" "+_vm._s(this.$root.$i18n.locale == "es" ? this.trimString(_vm.selected.descriptionCastellano, 160) : this.trimString(_vm.selected.descriptionValencia, 160))+" ")]),_c('router-link',{staticClass:"tooltip--link",attrs:{"to":{
              name: 'ProjectSingle',
              params: {
                id: _vm.selected.id,
                slug: this.getSlugFromTitle(_vm.selected.titleValencia),
              },
            }}},[_vm._v(_vm._s(_vm.$t("message.map.seeProject"))+" "),_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/icon-arrow-long-right.svg"),"alt":""}})])])],1)])]):_vm._e()]:[(_vm.selected)?_c('div',{staticClass:"map--tooltip--mobile"},[_c('router-link',{staticClass:"map--tooltip--inner",attrs:{"to":{
          name: 'ProjectSingle',
          params: {
            id: _vm.selected.id,
            slug: this.getSlugFromTitle(_vm.selected.titleValencia),
          },
        }}},[_c('div',{staticClass:"tooltip--image"},[_c('img',{attrs:{"src":_vm.selected.image,"alt":""}})]),_c('h4',{staticClass:"tooltip--title",domProps:{"innerHTML":_vm._s(
            this.$root.$i18n.locale == 'es'
              ? this.trimString(_vm.selected.titleCastellano, 80)
              : this.trimString(_vm.selected.titleValencia, 80)
          )}}),_c('div',{staticClass:"tooltip--author-date"},[_vm._v(" "+_vm._s(this.$root.$i18n.locale == "es" ? _vm.selected.authorCastellano : _vm.selected.authorValencia)+", "+_vm._s(_vm.selected.year)+" ")])])],1):_vm._e()],_c('div',{staticClass:"map--buttons"},[_c('a',{staticClass:"help-button",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openModal()}}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-map-question.svg"),"alt":"Help"}})]),_c('a',{staticClass:"zoom-button zoom-in",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.newsMap.zoomIn(1.4)}}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-zoom-in.svg"),"alt":"Zoom in"}})]),_c('a',{staticClass:"zoom-button zoom-out",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.newsMap.zoomIn(0.6)}}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-zoom-out.svg"),"alt":"Zoom out"}})])]),(_vm.modal)?_c('div',{staticClass:"map--modal"},[_c('div',{staticClass:"map--modal--inner"},[_c('div',{staticClass:"map--modal--header"},[_c('div',{staticClass:"modal--header--title"},[_vm._v(" "+_vm._s(_vm.$t("message.map.modal.helpTitle"))+" ")]),_c('div',{staticClass:"modal--header--close",on:{"click":_vm.closeModal}},[_vm._m(0)])]),_c('div',{staticClass:"map--modal--body"},[_c('div',{staticClass:"modal--body--item"},[_vm._m(1),_c('div',{staticClass:"modal--body--item__text"},[_vm._v(" "+_vm._s(_vm.$t("message.map.modal.help1"))+" ")])]),_c('div',{staticClass:"modal--body--item"},[_vm._m(2),_c('div',{staticClass:"modal--body--item__text"},[_vm._v(" "+_vm._s(_vm.$t("message.map.modal.help2"))+" ")])])])])]):_vm._e(),_c('div',{staticClass:"map--wrapper map-box"},[_c('div',{class:_vm.mapClass},[_c('ProjectsMap',{ref:"newsMap",on:{"updateParent":_vm.updateStats,"updateChartLoaded":function($event){return _vm.getProjectsFiltered()},"selectedItem":_vm.updateTooltip}})],1)])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-close-white.svg"),"alt":"Tancar"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal--body--item__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-map-marker-big.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal--body--item__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-map-zoom.svg"),"alt":""}})])
}]

export { render, staticRenderFns }