/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class UserService {

  getAllUsers() {
    return axios
      .get(`${API_URL}users`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateUserStatus(id, status) {
    return axios
      .post(
        `${API_URL}update-user-enabled/${id}`,
        { enabled: status, },
        { headers: authHeader() },
      )
      .then((response) => response.data);
  }
  
}

export default new UserService();
