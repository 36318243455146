<template>
  <div class="chart-wrapper">
    <canvas id="container-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'standard-chart',

  data() {
    return {
      labels: null,
      data: {
        labels: null,
        datasets: [],
      },
      config: {
        type: 'line',
        data: null,
        options: {
          maintainAspectRatio:false,
          responsive: true,
          autoPadding: false,
          layout: {
            padding: {
              left: 24,
              right: 48,
              top: 48,
              bottom: 24,
            },
          },
          plugins: {
            legend: {
              position: "bottom",
              labels:{
                padding: 16,
              }
            },
            title: {
              display: false,
              text: 'Chart'
            },
          }
        },
      },

    };
  },
  methods: {
    initChart() {
      const ctx = document.getElementById('container-chart');
      this.data.labels = this.labels;
      this.config.data = this.data;
      
      this.chart = new Chart(ctx, this.config);
      // this.chart.Legend.prototype.afterFit = function() {
      // this.height = this.height + 50;
      //};
    },
    updateValues(data) {
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = document.getElementById('container-chart');
      this.data.labels = data.labels;
      this.data.datasets = data.datasets;
      this.config.data = this.data;
      this.chart = new Chart(ctx, this.config);
      //delay 2 seconds
      setTimeout(() => {
        this.chart.update();
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-wrapper {
  max-height: 100%;
  width: 100%;
  height: 100%;

  // padding:calc(var(--base) * 2);
  #container-chart {
    display: flex;
    align-items: stretch;
    // min-width:100%;
    height: 100%;
    width: 100%;
  }
}
</style>
