<template>
  <div>
    <!-- Add v-select button to change language -->
    <div class="lang-selector">
      <div class="lang-selector--switch">
        <span
          class="lang-selector--switch__head"
          :class="[lang === 'ca' ? 'ca' : 'es']"
        ></span>
      </div>
      <div class="lang-selector--buttons">
        <button
          @click="changeLang('ca')"
          class="lang-selector--buttons__link"
          :class="[lang === 'ca' ? 'active' : '']"
        >
          Val
        </button>
        <button
          @click="changeLang('es')"
          class="lang-selector--buttons__link"
          :class="[lang === 'es' ? 'active' : '']"
        >
          Cas
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChangeLanguageButton',
  data() {
    return {
      lang: 'ca',
      languageOptions: [
        { value: 'ca', text: 'Valenciá' },
        { value: 'es', text: 'Castellano' },
      ],
      previousScroll: 0,
    };
  },
  methods: {
    changeLang(lang) {
      this.lang = lang;
      this.$root.$i18n.locale = lang;
    },
    // add 'hidden' class to lang-selector if scroll down 300px but remove it if scroll up
    handleScroll() {
      const langSelector = document.querySelector('.lang-selector');
      const scrollPosition = window.scrollY;
      if (scrollPosition > this.previousScroll && scrollPosition > 300) {
        langSelector.classList.add('hidden');
      } else {
        langSelector.classList.remove('hidden');
      }
      this.previousScroll = scrollPosition;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.lang-selector {
  position: fixed;
  left: calc(var(--base) * 8);
  bottom: calc(var(--base) * 8);
  display: flex;
  column-gap: calc(var(--base) * 2);
  flex-wrap: nowrap;
  mix-blend-mode: difference;
  transition: all 0.3s ease-out;
  @media (min-width: 1024px) {
    left: calc(var(--base) * 9);
    bottom: calc(var(--base) * 3);
    z-index: 200;
    opacity: 1;
    visibility: visible;
    &.hidden {
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
    }
  }

  .lang-selector--switch {
    left: 0;
    position: relative;
    // width: calc(var(--base) * 4);
    height: 100%;
    min-height: calc(var(--base) * 5);
    @media (min-width: 1024px) {
      min-height: calc(var(--base) * 7);
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2px;
      content: "";
      background-color: rgb(var(--bodyBg));
    }

    &__head {
      position: absolute;
      left: calc(var(--base) * -0.2);
      display: block;
      border-radius: calc(var(--base) * 8);
      height: calc(var(--base) * 3);
      width: calc(var(--base) * 0.8);
      background-color: rgb(var(--bodyBg));
      transition: all 0.3s ease;

      &.ca {
        top: 0;
        bottom: auto;
      }

      &.es {
        top: 100%;
        transform: translateY(-100%);
        // bottom: 0;
      }
    }
  }

  .lang-selector--buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    color: rgb(var(--bodyBg));

    &__link {
      font-size: calc(var(--base) * 2);
      text-transform: uppercase;
      transition: all 0.3s ease;
      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 2.5);
        transform:translateX(calc(var(--base) * -8.5));
      }

      &.active {
        font-weight: 600;
      }
    }
  }
}
</style>
