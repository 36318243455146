<template>
    <div
      class="panel-module--loader"
      
    >
      <div class="loader" :style="cssVars">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: String,
        required: true
      },
     
      borderRadius:{
        type: String,
        required: false,
        default: "8",
      },
    },
  
    computed: {
      cssVars() {
        return {
          '--loader-color': this.color,
        }
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .panel-module--loader {
    position:absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    --loader-size: 100px;
    --loader-speed: 500ms;
  
    .loader {
      --loader-height: calc(var(--loader-size) / 7);
      display: flex;
      position: relative;
    }
  
    .loader::before {
      content: "";
      position: absolute;
    }
  
    .loader::before,
    .loader > span {
      display: block;
      width: var(--loader-height);
      height: var(--loader-height);
      border-radius: 50%;
      background: rgb(var(--loader-color));
  
      animation-name: to-right;
      animation-duration: var(--loader-speed);
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  
    .loader > span:nth-child(even) {
      visibility: hidden;
    }
  
    .loader > span:first-child {
      animation-name: scale-up;
    }
  
    .loader > span:last-child {
      animation-name: scale-up;
      animation-direction: reverse;
    }
  
    @keyframes to-right {
      to {
        transform: translateX(200%);
      }
    }
  
    @keyframes scale-up {
      from {
        transform: scale(0);
      }
  
      to {
        transform: scale(1);
      }
    }
  }
  </style>