<template>
  <div class="form-step--wrapper form-upload--step-4">
    <div class="form--content form-upload--step-4--content">
      <form-icon-selector
        :title="this.$t('message.forms.questions.upload.goToMyProfile')"
        icon="icon-selector-ver-perfil.svg"
        v-on:click.native="goToMyProfile"
      ></form-icon-selector>
    </div>
  </div>
</template>

<script>
import FormIconSelector from "@/components/Forms/Buttons/FormIconSelector.vue";
export default {
  components:{
    FormIconSelector,
  },
    data() {
        return {
            activeLang: "valenciano",
            projectDescriptionCat: "",
            projectDescriptionEs: "",
            projectImages: [],
            projectLink1: "",
            projectLink2: "",
            projectDescriptionRules: [
                (v) => !!v || this.$t("message.forms.requiredField"),
                (v) => v.length <= 2000 || this.$t("message.forms.maxCharacters2000"),
            ],
            projectImagesRules: [
                (v) => !!v || this.$t("message.forms.requiredField"),
                (v) => v.length <= 10 || this.$t("message.forms.maxFilesTen"),
            ],


        };
    },
    methods: {
        goToMyProfile() {
            this.$router.push({ name: "UserPanelProyectos" });
        },
    },
}

</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:5%;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;

    .lang-form-selector {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 0.5);
      margin-bottom: calc(var(--base) * 2);

      a {
        font-size: calc(var(--base) * 2.25);

        &.selected {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .form--grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.835978836vw;
      }
    }

    .v-input__append-inner {
      .text-field--custom-icon {
        width: calc(var(--base) * 3.5);
        height: calc(var(--base) * 3.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .form--column {
      width: 100%;

      @media (min-width: 1024px) {
        width: 50%;
      }

      .form--row {
        display: flex;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 2.25);
        width: 100%;

        .form-input--year {
          width: 100%;
          max-width: 30%;
        }
      }
    }
  }
}
</style>