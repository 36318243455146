import UserService from '@/services/user.service';
import ProjectSevice from '@/services/project.services';

const initialState = {
  loggedUser: {},
  projects: [],
};

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    getLoggedUser({ commit }) {
      return UserService.getLoggedUser().then(
        (response) => {
          commit('setLoggedUser', response);
        },
        (error) => Promise.reject(error),
      );
    },
    getUserProjects({ commit }) {
      return ProjectSevice.getMyProjects().then(
        (response) => {
          commit('setUserProjects', response);
        },
        (error) => Promise.reject(error),
      );
    },
    
  },
  mutations: {
    setLoggedUser(state, userObj) {
      state.loggedUser = userObj;
    },
    setUserProjects(state, projects) {
      state.projects = projects;
    },
  },
};
