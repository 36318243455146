<template>
    <div class="map-container">
      <div class="map--tooltip" v-if="selected">
        <div class="map--tooltip--inner">
          <div class="left-col">
            <img :src="selected.image" alt="" />
          </div>
          <div class="right-col">
            <div class="tooltip--author-date" v-html="this.$root.$i18n.locale == 'es' ? selected.titleCastellano :selected.titleValencia">
            </div>
            <div class="tooltip--description">
              {{ this.$root.$i18n.locale == 'es' ? selected.authorCastellano : selected.authorValencia }}, {{ selected.year }}
            </div>
            <router-link class="tooltip--link" :to="{ name: 'ProjectSingle', params: { id: selected.id, slug: this.getSlugFromTitle(selected.titleValencia)  } }"
              >{{ $t("message.map.seeProject") }}
              <span
                ><img
                  src="@/assets/icons/icon-arrow-long-right.svg"
                  alt="" /></span
            ></router-link>
          </div>
        </div>
      </div>
      <div class="map--buttons">
        <a href="javascript:;" class="help-button" @click="openModal()">
          <img src="@/assets/icons/icon-map-question.svg" alt="Help" />
        </a>
        <a
          href="javascript:;"
          class="zoom-button zoom-in"
          @click="$refs.newsMap.zoomIn(1.4)"
        >
          <img src="@/assets/icons/icon-zoom-in.svg" alt="Zoom in" />
        </a>
        <a
          href="javascript:;"
          class="zoom-button zoom-out"
          @click="$refs.newsMap.zoomIn(0.6)"
        >
          <img src="@/assets/icons/icon-zoom-out.svg" alt="Zoom out" />
        </a>
      </div>
      <div class="map--modal" v-if="modal">
        <div class="map--modal--inner">
          <div class="map--modal--header">
            <div class="modal--header--title">
              {{ $t("message.map.modal.helpTitle") }}
            </div>
            <div class="modal--header--close" @click="closeModal">
              <a href="javascript:;">
                <img src="@/assets/icons/icon-close-white.svg" alt="Tancar" />
              </a>
            </div>
          </div>
          <div class="map--modal--body">
            <div class="modal--body--item">
              <div class="modal--body--item__icon">
                <img src="@/assets/icons/icon-map-marker-big.svg" alt="" />
              </div>
              <div class="modal--body--item__text">
                {{ $t("message.map.modal.help1") }}
              </div>
            </div>
            <div class="modal--body--item">
              <div class="modal--body--item__icon">
                <img src="@/assets/icons/icon-map-zoom.svg" alt="" />
              </div>
              <div class="modal--body--item__text">
                {{ $t("message.map.modal.help2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map--wrapper map-box">
        <div :class="mapClass">
          <ProjectsMap
            ref="newsMap"
            @updateParent="updateStats"
            @updateChartLoaded="getProjectsFiltered()"
            @selectedItem="updateTooltip"
          >
          </ProjectsMap>
        </div>
      </div>
    </div>
</template>

<script>
import ProjectService from '@/services/project.services';
import ProjectsMap from '@/components/Map/StandardMap.vue';

export default {
  name: 'MapView',
  data() {
    return {
      project: null,
    mapClass: 'map-proj-unselected',

    info: null,
    loading: true,
    errored: false,
    modal:false,
    page: 0,
    size: 20,

    projectSelectionClass: 'sel-proj-unselected',
    catSelected: null,

    stats: {
      totalValue: 0,
      percentLabeled: 0.0,
    },
    selected: null,
    items: [],

    // Filter
    projects: [],
    filter: {
      year: null,
      status: null,
      title: null,
    },
    filterType: null,
    filterTypeArray: [],
    };
  },
  components: {
    ProjectsMap,
  },
  mounted() {
    // this.loadItems();
    // this.getAllProjects();

    if(this.$route.params.id) {
      const slug = this.$route.params.slug;
      // get 3 first words from slug and replace - with space
      this.filter.title =  slug.split('-').slice(0, 3).join(' ');
    }
  },
  methods: {

    getSlugFromTitle(title) {
      return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    async getAllProjects() {
      const projects = await ProjectService.getAllPublic(this.page, this.size);
      this.projects = projects.content;
      this.$refs.newsMap.updateData(this.projects);
    },

    async getProjectsFiltered() {
      const projects = await ProjectService.getPublicFiltered(this.filter.title, this.filter.year, this.filter.status, this.filterType, this.page, this.size);
      //map projects content to projects
      this.projects = projects.content.map((project) => ({
        id: project.id,
        titleValencia: project.titleValencia,
        titleCastellano: project.titleCastellano,
        category: project.category,
        categoryName: this.$getProjectCategory(project.type).name,
        descriptionValencia: project.descriptionShortValencia,
        descriptionCastellano: project.descriptionShortCastellano,
        value: 1,
        color: this.$getProjectCategory(project.type).hexColor,
        year: project.year,
        latitude: project.latitude,
        longitude: project.longitude,
        authorCastellano: project.authors ? project.authors[0].nameCastellano : null,
        authorValencia: project.authors ? project.authors[0].nameValencia : null,
        image: project.projectMainImages[0] ? project.projectMainImages[0].imageUrl : project.projectMainImages[0],
        url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg',
      }));

      // this.projects = projects.content;
      this.$refs.newsMap.updateData(this.projects);
    },

    updateType(val) {
      if (this.filterTypeArray.includes(val)) {
        this.filterTypeArray.splice(this.filterTypeArray.indexOf(val), 1);
      } else {
        this.filterTypeArray.push(val);
      }
      // transform array to string
      this.filterType = this.filterTypeArray.join(',');
      this.getProjectsFiltered();
    },

    updateFilter(val) {
      this.filter = val;
      this.getProjectsFiltered();
      this.$refs.newsMap.updateData(this.projects);
    },
    loadItems() {
      this.items = [
        {
          id: 1,
          title: 'Project 1',
          category: 1,
          categoryName: this.$getProjectCategory("SOSTENIBILIDAD").name,
          description: 'This is the description of the project 1',
          author: 'Author 1',
          latitud: 39.5390256043137,
          longitud: -0.39419687338430326,
          value: 1,
          color: this.$getProjectCategory("SOSTENIBILIDAD").hexColor,
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg",
          url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg',
        },
        {
          id: 2,
          title: 'Project 2',
          category: 2,
          categoryName: this.$getProjectCategory("INSTALACIONES").name,
          description: 'This is the description of the project 2',
          author: 'Author 2',
          latitud: 39.64514835499148,
          longitud: -0.5525210305745163,
          value: 1,
          color: this.$getProjectCategory("INSTALACIONES").hexColor,
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg",
          url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg'
        },
        {
          id: 3,
          title: 'Project 3',
          category: 3,
          categoryName: this.$getProjectCategory("ESPACIOS_COMUNES").name,
          description: 'This is the description of the project 3',
          author: 'Author 3',
          latitud: 39.18743663347988,
          longitud: -0.916258239585435,
          value: 1,
          color: this.$getProjectCategory("ESPACIOS_COMUNES").hexColor,
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg",
          url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg'
        },

        {
          id: 4,
          title: 'Project 4',
          category: 4,
          categoryName: this.$getProjectCategory("SEGURIDAD").name,
          description: 'This is the description of the project 4',
          author: 'Author 4',
          latitud: 40.46117877957635,
          longitud: -0.051755989743290064,
          value: 1,
          color: this.$getProjectCategory("SEGURIDAD").hexColor,
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg",
          url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg'
        },
        {
          id: 5,
          title: 'Project 5',
          category: 5,
          categoryName: this.$getProjectCategory("SOCIAL").name,
          description: 'This is the description of the project 5',
          author: 'Author 5',
          latitud: 38.32579821763623,
          longitud: -0.6298675710601955,
          value: 1,
          color: this.$getProjectCategory("SOCIAL").hexColor,
          image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg",
          url:'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg'
        },
      ];
    },
    updateStats(val) {
      this.stats.totalValue = val;
    },
    updateTooltip(val) {
      this.selected = val;
    },
    setFilter(cat) {
      this.catSelected = cat;
    },
    resetFilter() {
      this.catSelected = null;
      this.loadItems();
      this.$refs.newsMap.updateData(this.items);
    },
    updateFilterOld() {
      // filter items by category
      this.items = this.items.filter((item) => {
        if (this.catSelected === null) {
          return true;
        }
        return item.category === this.catSelected;
      });
      this.$refs.newsMap.updateData(this.items);
    },
    setProjectFilter(cat) {
      if (this.catSelected === cat) {
        this.resetFilter();
      } else if (this.catSelected !== cat) {
        this.loadItems();
        this.setFilter(cat);
        this.updateFilter();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
  .map-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      .map-box {
        position: relative;
        height: 100% !important;
        border-radius: calc(var(--base) * 1);

        #map-container {
          width: 100%;
          height: 100%;

          svg {
            width: 100%;
            height: auto;
          }
        }
      }

      & .map--search-filter {
        position: absolute;
        top: calc(var(--app-height) * 0.1);
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
      }

      & .map--buttons {
        position: absolute;
        bottom: calc(var(--base) * 2);
        right: calc(var(--base) * 2);
        display: flex;
        flex-direction: column;
        z-index: 100;

        .help-button {
          width: calc(var(--base) * 4.25);
          height: calc(var(--base) * 4.25);
          background: rgb(var(--bodyBg));
          border: solid 1px rgb(var(--bodyColor));
          border-radius: calc(var(--base) * 0.625);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: calc(var(--base) * 1.875);
          box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .zoom-button {
          width: calc(var(--base) * 4.25);
          height: calc(var(--base) * 6.75);
          background: rgb(var(--bodyBg));
          border: solid 1px rgb(var(--bodyColor));
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);

          &.zoom-in {
            border-radius: calc(var(--base) * 0.625) calc(var(--base) * 0.625) 0
              0;
          }

          &.zoom-out {
            margin-top: -1px;
            border-radius: 0 0 calc(var(--base) * 0.625)
              calc(var(--base) * 0.625);
          }
        }
      }

      & .map--tooltip {
        position: absolute;
        bottom: calc(var(--base) * 2);
        left: calc(var(--base) * 2);
        z-index: 200;
        background-color: rgb(var(--bodyBg));
        border: dashed 1px rgb(var(--bodyColor));
        border-radius: calc(var(--base) * 3.125);
        padding: calc(var(--base) * 2.125);
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);

        .map--tooltip--inner {
          height: 100%;
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          gap: calc(var(--base) * 2.875);

          .left-col {
            height: 100%;
            width: 50%;
            border: solid 1px rgb(var(--bodyColor));
            border-radius: calc(var(--base) * 2.5);
            overflow: hidden;
            display: none;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .right-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            .tooltip--title {
              font-size: calc(var(--base) * 2.5);
              font-weight: 500;
              color: rgb(var(--bodyColor));
              letter-spacing: 0.01em;
              max-width: 100%;
              min-width: 60%;
              width: auto;
              border-bottom: solid 1px rgb(var(--bodyColor));
              padding-bottom: calc(var(--base) * 0.5);
              margin-bottom: calc(var(--base) * 0.5);
            }

            .tooltip--author-date {
              font-size: calc(var(--base) * 1.625);
              margin-bottom: calc(var(--base) * 1.5);
            }

            .tooltip--description {
              font-size: calc(var(--base) * 1.5);
              line-height: calc(var(--base) * 1.875);
              width: 100%;
              margin-bottom: calc(var(--base) * 2);
            }

            .tooltip--link {
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              width: auto;
              gap: calc(var(--base) * 0.5);
              margin-top: auto;
              font-size: calc(var(--base) * 1.75);
              font-weight: 700;
              text-decoration: underline;
              margin-bottom: calc(var(--base) * 2);

              span {
                width: calc(var(--base) * 4);
                height: calc(var(--base) * 0.75);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }

      & .map--modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 800;
        width: 100vw;
        height: var(--app-height);
        background-color: rgba(var(--bodyColor), 0.37);
        display: flex;
        justify-content: center;
        align-items: center;

        .map--modal--inner {
          width: 30.15873016vw;
          height: auto;
          background: rgb(var(--bodyBg));
          border: solid 1px rgb(var(--bodyColor));
          border-radius: calc(var(--base) * 3.75);
          padding: calc(var(--base) * 3.75) calc(var(--base) * 4.75);
          padding-bottom: calc(var(--base) * 6);
          padding-right: calc(var(--base) * 3.125);

          .map--modal--header {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: calc(var(--base) * 4);

            & .modal--header--title {
              font-size: calc(var(--base) * 3.5);
              line-height: calc(var(--base) * 3.75);
              font-weight: 700;
              color: rgb(var(--bodyColor));
              text-decoration: underline;
            }

            & .modal--header--close {
              background: rgb(var(--bodyColor));
              width: calc(var(--base) * 3.25);
              height: calc(var(--base) * 3.25);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: calc(var(--base) * 0.625);
              padding: calc(var(--base) * 0.875);

              a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
            }
          }

          .map--modal--body {
            display: flex;
            flex-direction: column;
            gap: calc(var(--base) * 5.625);
            padding-right: calc(var(--base) * 2);

            .modal--body--item {
              display: flex;
              flex-wrap: nowrap;
              gap: calc(var(--base) * 3.5);

              &__icon {
                width: calc(var(--base) * 6);
                height: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                }
              }
            }
          }
        }
      }

      & .map--wrapper {
        min-height: var(--app-height);
        min-height: 200px;
        height: 100%;
        height: 100vh;
        width: 100%;
        z-index: 1;

        .standard-map {
          width: 100%;
          min-height: var(--app-height);
          height: 100vh;

          #map-container {
            width: 100%;
            height: 100%;
            min-height: var(--app-height);
            height: 100vh;
          }
        }
      }
    }
</style>