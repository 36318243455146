<template>
  <v-app
    id="app"
    :class="this.$route.name != 'ArchiveCug' ? 'flex-column' : ''"
    class="w-screen overflow-x-hidden"
  >
    <main-logo></main-logo>
    <change-language-button
      v-if="
        this.$vuetify.breakpoint.width >= 1024 && this.$route.meta.langSelector
      "
    ></change-language-button>
    <burger-nav-movil
      v-if="this.$vuetify.breakpoint.width < 1024"
    ></burger-nav-movil>
    <burger-nav v-else></burger-nav>
    <v-main>
      <!-- <UnderConstruction/> -->
      <v-container fluid class="public-app-content container--fluid">
        <router-view></router-view>
      </v-container>
    </v-main>
    <footer-cug
      v-if="this.$vuetify.breakpoint.width >= 1024 && this.$route.meta.footer"
      :bg="footerBg"
      :key="$route.fullPath"
    ></footer-cug>
    <footer-cug-movil
      v-if="this.$vuetify.breakpoint.width < 1024 && this.$route.meta.footer"
    ></footer-cug-movil>
  </v-app>
</template>

<script>
//import UnderConstruction from './components/UnderConstruction.vue'
import MainLogo from "@/components/Logos/MainLogo.vue";
import ChangeLanguageButton from "@/components/Languages/ChangeLanguageButton.vue";
import BurgerNav from "@/components/Nav/BurgerNav.vue";
import BurgerNavMovil from "@/components/Nav/BurgerNavMovil.vue";
import FooterCug from "@/components/Sections/FooterCug.vue";
import FooterCugMovil from "@/components/Sections/FooterCugMovil.vue";

export default {
  name: "App",
  components: {
    // UnderConstruction
    MainLogo,
    ChangeLanguageButton,
    BurgerNav,
    BurgerNavMovil,
    FooterCug,
    FooterCugMovil,
  },
  mounted() {
    // eslint-disable-next-line func-names
    this.onResize();

    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.setDocumentHeightVariable();
    },
    setDocumentHeightVariable() {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
    },
  },
  computed: {
    footerBg() {
      return this.$route.name === "AboutCug" ? "bg-cug-pink" : "bg-white";
    },
  },
};
</script>
<style lang="scss">
// * {
//   border: solid 1px red;
// }

.v-main {
  height: auto;
  // @media (min-width: 1024px) {
  //   height: 100%;
  // }
}
#app.flex-column {
  // height: 100%;
  display: flex;
  flex-direction: column;
}
.container--fluid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  max-width: 100%;
}
.container {
  max-width: 100% !important;
}
</style>
