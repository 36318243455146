import axios from "@/services/http-common";
import axiosSource from "axios";
import authHeader from "@/services/auth-header";

const API_URL = `${process.env.VUE_APP_API_URL}`;

const projectSample = {
  category: null,
  edition: null,
  latitude: null,
  longitude: null,
  prize: null,
  titleCastellano: null,
  titleValencia: null,
  type: null,
  year: null,
  projectLinks: [
    {
      url: null,
      title: null,
    },
  ],
  authors: [
    {
      nameCastellano: null,
    },
  ],
  placeName: null,
  descriptionShortCastellano: null,
  descriptionShortValencia: null,
  currentStatus: null,
  descriptionLongCastellano: null,
  descriptionLongValencia: null,
};

class ProjectService {
  getProject(id) {
    return axios
      .get(`${API_URL}project/public/${id}`)
      .then((response) => response.data);
  }

  getAdminProject(id) {
    return axios
      .get(`${API_URL}project/${id}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getAllPublic(page, size) {
    const params = {
      page: page,
      size: size,
    };
    return axios
      .get(`${API_URL}project/public/`, { params })
      .then((response) => response.data);
  }

  getPublicFiltered(title, year, status, type, page, size) {
    const params = {
      page: page,
      size: size,
      title: title,
      year: year,
      status: status,
      types: type ? type : null,
    };
    return axios
      .get(`${API_URL}project/public/`, { params })
      .then((response) => response.data);
  }

  likeProject(id) {
    return axios
      .post(
        `${API_URL}project/favorite/${id}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  unlikeProject(id) {
    return axios
      .post(
        `${API_URL}project/unfavorite/${id}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  commentProject(id, comment) {
    return axios
      .post(
        `${API_URL}project/comment/${id}`,
        { text: comment },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  requestChat(id) {
    return axios
      .post(
        `${API_URL}project/request-chat/${id}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  unrequestChat(id) {
    return axios
      .post(
        `${API_URL}project/unrequest-chat/${id}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  getSlugFromTitle(title) {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  goToSingleProjectView(id) {
    //get project with id
    this.getProject(id).then((project) => {
      //get slug from title
      const slug = this.getSlugFromTitle(project.titleValencia);
      //go to project view
      this.$router.push({
        name: "ProjectSingle",
        params: { id: id, slug: slug },
      });
    });
  }

  getMyProjects(page = 0, size = 20) {
    const params = {
      page: page,
      size: size,
    };
    return axios
      .get(`${API_URL}project/my-projects`, {
        params: params,
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getMyFavoritesProjects(page, size) {
    const params = {
      page: page,
      size: size,
    };
    return axios
      .get(`${API_URL}project/my-favorites-projects`, {
        params: params,
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getMyFavoritesProjectsNoCommon(page, size) {
    const params = {
      page: page,
      size: size,
    };
    return axiosSource
      .get(`${API_URL}project/my-favorites-projects`, {
        params: params,
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getLastProjects(page, size) {
    const params = {
      page: page,
      size: size,
      sort: "id",
      order: "desc",
    };
    return axios
      .get(`${API_URL}project/public/`, { params })
      .then((response) => response.data);
  }

  getRecommendedProjects(page, size) {
    const params = {
      page: page,
      size: size,
      sort: "views",
      order: "desc",
    };
    return axios
      .get(`${API_URL}project/public/`, { params })
      .then((response) => response.data);
  }

  getProjectStats(id, dateStart, dateEnd) {
    const params = {
      from: dateStart,
      to: dateEnd,
    };
    return axios
      .get(`${API_URL}project/stats/${id}`, {
        params: params,
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getAllProjects(page, size) {
    const params = {
      page: page,
      size: size,
    };
    return axios
      .get(`${API_URL}project/all/`, { params: params, headers: authHeader() })
      .then((response) => response.data);
  }

  //PROJECT UPLOAD && EDIT

  getProjectSample() {
    return projectSample;
  }

  createProject(project) {
    return axios
      .post(`${API_URL}project/create`, project, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateProject(project, id) {
    return axios
      .post(`${API_URL}project/update/${id}`, project, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updatePublished(id, published) {
    const params = {
      published: published,
    };
    return axios
      .post(`${API_URL}project/update-published/${id}`, params, {
        headers: authHeader(),
      })
      .then((response) => response);
  }
  updateDenied(id) {
    return axios
      .post(`${API_URL}project/update-denied/${id}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response);
  }

  uploadImageToProject(id, image) {
    const formData = new FormData();
    formData.append("file", image);
    const header = authHeader();
    header["Content-Type"] = "multipart/form-data";
    return axios.post(`${API_URL}project/upload-image/${id}`, formData, {
      headers: header,
    });
  }

  uploadMainImageToProject(projectId, image) {
    const formData = new FormData();
    formData.append("file", image); // Asegúrate de que el nombre del campo sea 'file'
    console.log(formData);
    return axios.post(
      `${API_URL}project/upload-image-main/${projectId}`,
      formData,
      {
        headers: {
        ...authHeader(),
        // No es necesario establecer 'Content-Type' aquí
      },
    });
  }

  deleteImageFromProject(id, imageId) {
    return axios.delete(`${API_URL}project/delete-image/${id}/${imageId}`, {
      headers: authHeader(),
    });
}
}

export default new ProjectService();
