var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"user-card",class:[_vm.isRead ? 'read' : ''],style:([
    _vm.isRead
      ? {
          backgroundColor:
            'rgb(' + this.$getUserColor(this.color).medium + ')',
        }
      : {
          backgroundColor:
            'rgb(' + this.$getUserColor(this.color).light + ')',
        },
  ]),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.updateConversation()}}},[_c('div',{staticClass:"user-card--inner"},[_c('div',{staticClass:"user-card--avatar",style:({
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).dark + ')',
          })},[(_vm.avatar)?_c('img',{attrs:{"src":_vm.avatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.user)))])]),_c('div',{staticClass:"user-card--content"},[_c('div',{staticClass:"user-card--content__name"},[_vm._v(" "+_vm._s(_vm.trimString(_vm.user, 40))+" ")]),_c('div',{staticClass:"user-card--content__message"},[_vm._v(" "+_vm._s(_vm.trimString(_vm.message, 30))+" ")]),_c('div',{staticClass:"user-card--content__timestamp"},[_vm._v(" "+_vm._s(_vm.timeAgo(_vm.timestamp))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }