<template>
  <article class="comment-card">
    <div class="comment-card__avatar">
        <img :src="comment.user.avatar" alt="" v-if="comment.user.avatar" />
        <span v-else>{{ userChar }}</span>
    </div>
   
    <div class="comment-card__body">
        <div class="comment-card__body__user">
        {{ comment.user.name }},
        <span>{{(comment.user.pronoun)}}</span>
      </div>
      <div class="comment-card__body__text">
        {{ comment.text }}
      </div>
    </div>
  </article>
</template>

<script>
export default {
    props: {
        comment: {
            type: Object,
            required: true
        }
    },
    computed: {
        userChar() {
            if (this.comment.user && this.comment.user.name) {
                //if user.name has a space, split it and get the first letter of each word
                if (this.comment.user.name.includes(' ')) {
                    const name = this.comment.user.name.split(' ');
                    return name[0].charAt(0) + name[1].charAt(0);
                }
                //if user.name has no space, get the two first letters
                return this.comment.user.name.substring(0, 2);
            }
            return 'XX';
        },
    },
    methods:{
        timeAgo(timestamp) {
            let date = new Date(timestamp);
            let seconds = Math.floor((new Date() - date) / 1000);
            let interval = Math.floor(seconds / 31536000);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.years');
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.months');
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.days');
            }
            interval = Math.floor(seconds / 3600);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.hours');
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.minutes');
            }
            return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.seconds');
        },
    }

}
</script>

<style lang="scss" scoped>

.comment-card{
    display: flex;
    flex-wrap: nowrap;
    width:100%;
    gap:calc(var(--base) * 2);
    border-top: solid 1px rgb(var(--bodyColor));
    padding: calc(var(--base) * 2.25) 0;
    &:first-of-type{
        border-top: none;
    }
    &__avatar{
        display: flex;
        flex-wrap:nowrap;
        align-items: center;
        justify-content: center;
        border-radius:50%;
        width:calc(var(--base) * 6);
        height:calc(var(--base) * 6);
        overflow: hidden;
        flex-shrink: 0;
    }
    &__body{
        display: flex;
        flex-direction: column;
        &__user{
            font-size: calc(var(--base) * 2);
            margin-bottom: calc(var(--base) * 0.5);
            span{
                font-size: calc(var(--base) * 1.75);
                font-style: italic;
            }
        }
        &__text{
            font-size: calc(var(--base) * 2);
            line-height: calc(var(--base) * 3);
            font-weight: 300;
        }
    }
}
</style>