import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=552230e5&scoped=true"
import script from "./ProjectCard.vue?vue&type=script&lang=js"
export * from "./ProjectCard.vue?vue&type=script&lang=js"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=552230e5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552230e5",
  null
  
)

export default component.exports