<template>
  <button
    class="project-mention-button"
    @click="clickMention()"
    :class="[isSelected ? 'selected' : '']"
    :style="{
      backgroundColor: `rgba(${$getProjectCategory(this.catId).color}, 1)`,
    }"
  >
    <div class="project-mention-button__icon" :style="{
      backgroundColor: `rgba(${$getProjectCategory(this.catId).color}, 1)`,
    }">
      <img src="@/assets/icons/icon-project-mention.png" alt="" />
    </div>
    <div class="project-mention-button__text">
      <span class="first-line"
        >{{ this.mention.edition }}
        {{ $t("message.components.projectSingle.edition") }}</span
      >
      <span class="second-line" v-if="this.mention.type === 'Mención'">{{ $t("message.components.projectSingle.mention") }}</span>
      <span class="second-line" v-else>{{ $t("message.components.projectSingle.prize") }}</span>
    </div>
  </button>
</template>

<script>
export default {
    props:{
       mention: {
         type: Object,
         required: true,
       },
       catId: {
         type: String,
         required: true,
       },
    },
    data() {
      return {
        isSelected: false,
      };
    },
    methods:{
        clickMention(){
          if(this.isSelected === false){
            this.isSelected = true}
            else{
              // this.$router.push({ path: this.mention.url });
              window.open(this.mention.link, '_blank');
              setTimeout(() => {
                this.isSelected = false;
              }, 1000);
            }
        }
    }

}
</script>

<style lang="scss">
.project-mention-button {
  border: solid 1px rgba(var(--bodyColor), 1);
  border-radius: calc(var(--base) * 1.875);
  position: relative;
  width: 100%;
  height: calc(var(--base) * 7.5);
  min-height: calc(var(--base) * 7.5);
  max-height: calc(var(--base) * 7.5);
  min-width: calc(var(--base) * 7.5);
  max-width: calc(var(--base) * 7.5);
  transition: all 0.1s ease-out 0.1s;
  &__icon {
    position: absolute;
    top: 50%;
    left: calc(var(--base) * 1.1);
    transform: translateY(-50%);
    z-index: 10;
  }
  &__text {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-50%);
    color: rgba(var(--bodyColor), 1);
    min-width:calc(var(--base) * 20);
    visibility: hidden;
    opacity:0;
    transition: all 0.1s ease-out;
    .first-line {
      font-size: calc(var(--base) * 1.5);
      line-height:1;
      font-style: italic;
      font-weight: 500;
      color: rgba(var(--bodyColor), 1);
    }
    .second-line {
      font-size: calc(var(--base) * 2.25);
      line-height:1;
      font-weight: 700;
      color: rgba(var(--bodyColor), 1);
    }
  }
  &.selected {
    min-width: calc(var(--base) * 30);
    max-width: calc(var(--base) * 30);
    transition: all 0.3s ease-out;
    .project-mention-button__text {
      visibility: visible;
      opacity:1;
      left: calc(var(--base) * 8);
    transition: all 0.3s ease-out 0.1s;

    }
  }
}
</style>