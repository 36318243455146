<template>
  <div class="form-step--wrapper">
    <div class="form--content form-creator--step-2--content">
      <form-icon-selector :title="this.$t('message.forms.student')"
        icon="icon-selector-estudiante.svg" v-on:click.native="setJob(1)"></form-icon-selector>
      <form-icon-selector :title="this.$t('message.forms.professional')"
        icon="icon-selector-profesional.svg" v-on:click.native="setJob(2)"></form-icon-selector>
      <form-icon-selector :title="this.$t('message.forms.company')" icon="icon-selector-empresa.svg"
      v-on:click.native="setJob(3)"></form-icon-selector>
    </div>
  </div>
</template>

<script>
import FormIconSelector from "@/components/Forms/Buttons/FormIconSelector.vue";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default {
  name: "FormCreatorStep2",
  mixins: [validationMixin],
  components: {
    FormIconSelector,
  },
  data() {
    return {
      job: null,
    };
  },
  validations() {
    const valObj = {
      job: { required },
    };
    return valObj;
  },
  mounted: function () {
    this.updateValid();
  },
  methods: {
    setJob(job) {
      this.job = job;
      this.updateData();
    },
    updateData() {
      this.$emit('updateData', this.job);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3149287169);
  }

  .form--content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      align-items: flex-start;
      justify-content: center;
      gap: 8.068783069vw;
    }
  }
}
</style>