import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";

import vuetify from "@/plugins/vuetify";

import "./assets/css/vars.scss";
import "./assets/css/style.scss";
import "./assets/css/global.scss";
import "./assets/css/global_components.scss";

import "./assets/css/index.css";

import router from "./router";
import store from './store';

import config from "./utils/config";
import i18nMessages from "./utils/i18n-messages.js";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";



const messages = i18nMessages;

// gsap
gsap.registerPlugin(ScrollTrigger);
Vue.mixin({
  created() {
    this.gsap = gsap;
  },
});

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(config);

const i18n = new VueI18n({
  locale: "ca", // set locale
  messages, // set locale messages
});

new Vue({
  router,
  vuetify,
  i18n,
  validations: {},
  store,
  render: (h) => h(App),
}).$mount("#app");
