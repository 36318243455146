<template>
  <div class="form-step--wrapper">
    <div class="form--content form-creator--step-1--content">
      <form-icon-selector
        :title="this.$t('message.forms.profileCreator')"
        icon="icon-selector-creador.svg"
      ></form-icon-selector>
      <form-icon-selector
        :title="this.$t('message.forms.profileInstitution')"
        icon="icon-selector-institucional.svg"
      ></form-icon-selector>
    </div>
  </div>
</template>

<script>
import FormIconSelector from "@/components/Forms/Buttons/FormIconSelector.vue";
export default {
    name: "FormCreatorStep1",
    components: {
        FormIconSelector,
    },
    data() {
        return {};
    },
    };
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);
  background: red;
  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3149287169);
  }
  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 0;
    row-gap: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      align-items: flex-start;
      flex-direction: row;
      column-gap: 8.068783069vw;
      row-gap: 0;
    }
  }
}
</style>