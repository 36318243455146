import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class ChatService {

    getMyChats() {
      return axios
        .get(`${API_URL}project-chat/my-chats`, {
          headers: authHeader(),
        })
        .then((response) => response.data);
    }

    addComment(id,comment) {
      return axios
        .post(`${API_URL}project-chat/add-comment/${id}`, {comment}, {
          headers: authHeader(),
        })
        .then((response) => response.data);
    }
  }
  
  export default new ChatService();