<template>
  <div class="form-step--wrapper">
    <div class="form--content form-institution--step-2--content">
      <form-text-selector :text="this.$t('message.forms.alicante')" v-on:click.native="setProvince('Alicante/Alacant')"></form-text-selector>
      <form-text-selector :text="this.$t('message.forms.castellon')" v-on:click.native="setProvince('Castellón/Castelló')"></form-text-selector>
      <form-text-selector :text="this.$t('message.forms.valencia')" v-on:click.native="setProvince('Valencia/València')"></form-text-selector>
    </div>
  </div>
</template>

<script>
import FormTextSelector from '@/components/Forms/Buttons/FormTextSelector.vue'
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default {
  name: "FormCreatorStep2",
  mixins: [validationMixin],
  components: {
    FormTextSelector,
  },
  data() {
    return {
      province: null,
    };
  },
  validations() {
    const valObj = {
      province: { required },
    };
    return valObj;
  },
  mounted: function () {
    this.updateValid();
  },
  methods: {
    setProvince(prov) {
      this.province = prov;
      this.updateData();
    },
    updateData() {
      this.$emit('updateData', this.province);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--base) * 1.25);
    height: 100%;
    padding: 0 calc(var(--base) * 3);

    @media (min-width: 1024px) {
      align-items: center;
      justify-content: center;
      max-width: 39.48412698vw;
      margin: 0 auto;
      gap: calc(var(--base) * 5);
    }
  }
}
</style>