<template>
  <div
    class="tipology-card"
    :style="{ 'background-color': 'rgb(' + cat.color + ')' }"
  >
    <div class="tipology-card--icon">
      <div class="tipology-card--icon__inner">
        <img :src="cat.icon.color" alt="" />
      </div>
    </div>
    <div class="tipology-card--text">
      <div class="tipology-card--text__number">
        0{{ cat.number }}
      </div>
      <h5 class="tipology-card--text__name">
        {{ $t(`message.categories.${cat.id}.title`) }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TipologyCardMovil',
  props: {
    cat: {
      type: Object,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.tipology-card {
  display: flex;
  flex-wrap: nowrap;
  column-gap: calc(var(--base) * 2.375);
  padding: calc(var(--base) * 2.25) calc(var(--base) * 1.875);
  width: 100%;
  height: calc(var(--base) * 17);
  .tipology-card--icon {
    background-color: rgb(var(--bodyBg));
    width:calc(var(--base) * 12.5);
    height:calc(var(--base) * 12.5);
    border-radius:1000px;
    aspect-ratio: 1/1;
    padding:calc(var(--base) * 2);
    &__inner{
      width:100%;
      height:100%;
      img{
        width:100%;
        height:100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .tipology-card--text{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: calc(var(--base) * 1);
    &__number{
      font-size: calc(var(--base) * 4);
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 1);
      &:after{
        content:'|'
      }
    }
    &__name{
      font-size: calc(var(--base) * 2.5);
      line-height: calc(var(--base) * 2.75);
    }
  }

}
</style>
