<template>
  <a
    class="form-icon-selector"
    :class="[isSelected ? 'selected' : '']"
  >
    <div class="form-icon-selector--icon">
      <div class="form-icon-selector--icon__inner">
        <img :src="require(`@/assets/icons/${icon}`)" alt="" />
      </div>
    </div>
    <div class="form-icon-selector__title">
      {{ title }}
    </div>
  </a>
</template>

<script>
export default {
    name: "FormIconSelector",
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        initialSelected: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isSelected: false,
        };
    },
    mounted(){
      if(this.initialSelected){
        this.isSelected = true;
      }

    },
    methods:{
        toggleSelected(){
            this.isSelected = !this.isSelected;
        }
    }

};
</script>

<style lang="scss" scoped>
.form-icon-selector {
  width: calc(var(--base) * 20.875);
  height: calc(var(--base) * 20.875);
  min-width: calc(var(--base) * 20.875);
  min-height: calc(var(--base) * 20.875);
  max-width: calc(var(--base) * 20.875);
  max-height: calc(var(--base) * 20.875);
  border-radius: calc(var(--base) * 1.25);
  border: solid 1px rgb(var(--bodyColor));
  position: relative;
  background-color: rgb(var(--bodyBg));
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  padding: calc(var(--base) * 0.5);
  padding-bottom: calc(var(--base) * 2.75);
  transition: all ease-out 0.3s;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.19);
  cursor: pointer;

  @media (min-width: 1024px) {
    width: calc(var(--base) * 35.25);
    height: calc(var(--base) * 35.25);
    min-width: calc(var(--base) * 35.25);
    min-height: calc(var(--base) * 35.25);
    max-width: calc(var(--base) * 35.25);
    max-height: calc(var(--base) * 35.25);
    border-radius: calc(var(--base) * 3.75);
    padding: calc(var(--base) * 3.875) calc(var(--base) * 2);
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
  }
  @media (min-width: 1920px) {
    width: calc(var(--base) * 30.875);
    height: calc(var(--base) * 30.875);
    min-width: calc(var(--base) * 30.875);
    min-height: calc(var(--base) * 30.875);
    max-width: calc(var(--base) * 30.875);
    max-height: calc(var(--base) * 30.875);
    border-radius: calc(var(--base) * 3.75);
    padding: calc(var(--base) * 3.875) calc(var(--base) * 2);
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
  }
  &:hover,
  &.selected {
    background-color: rgb(var(--bodyColor));
    .form-icon-selector--icon__inner {
      max-width: 70%;
      max-height: 70%;
    }
  }

  .form-icon-selector--icon {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    mix-blend-mode: difference;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      top: 45%;
    }

    &__inner {
      width: 100%;
      height: 100%;
      max-width: 50%;
      max-height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-out 0.3s;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__title {
    font-size: calc(var(--base) * 2);
    font-weight: 700;
    color: rgb(var(--bodyBg));
    mix-blend-mode: difference;
    text-align: center;
    letter-spacing: 2.5%;
    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 2.625);
      font-weight: 600;
    }
  }
}
</style>