<template>
  <div class="page page-contact">
    <div class="page-contact--header">
      <h1 class="page-contact--header__title">
        {{ $t("message.contact.title") }}
      </h1>
      <h2 class="page-contact--header__subtitle-1">
        {{ $t("message.contact.subtitle") }}
      </h2>
      <h3 class="page-contact--header__subtitle-2">
        {{ $t("message.contact.subtitle2") }}
      </h3>
    </div>
    <div class="page-contact--content">
      <v-form class="page-contact--content__form">
        <v-text-field v-model="nameAndSurname" :label="$t('message.contact.nameAndSurname')"
          :placeholder="$t('message.contact.nameAndSurname')" :rules="[rules.required]" required
          outlined dense></v-text-field>
        <v-text-field v-model="email" :label="$t('message.contact.email')"
          :placeholder="$t('message.contact.email')" :rules="[rules.required, rules.email]" required
          outlined dense></v-text-field>
        <v-text-field v-model="telephone" :label="$t('message.contact.phone')"
          :placeholder="$t('message.contact.phone')" outlined dense
          :hint="this.$t('message.contact.optional')" persistent-hint></v-text-field>
        <v-text-field v-model="subject" :label="$t('message.contact.subject')"
          :placeholder="$t('message.contact.subject')" :rules="[rules.required]" required outlined
          dense></v-text-field>
        <v-textarea v-model="message" :label="$t('message.contact.message')"
          :placeholder="$t('message.contact.message')" :rules="[rules.required]" required outlined
          dense></v-textarea>
        <div class="page-contact--content__form__buttons">
          <button class="page-contact--content__form__buttons__button" @click.prevent="sendEmail()">
            {{ $t("message.contact.send")
            }}<span><img src="@/assets/icons/icon-send-white.svg" alt="send" /></span>
          </button>
        </div>
      </v-form>
    </div>
    <div class="page-contact__modal" v-if="modal" @click="closeModal()">
      <div class="page-contact__modal__inner">
        <div class="page-contact__modal__content">
          <h2 class="page-contact__modal__content__title">
            {{ $t("message.contact.greetings") }}
          </h2>
          <h3 class="page-contact__modal__content__subtitle">
            {{ $t("message.contact.greetings2") }}
          </h3>
        </div>
        <button class="page-contact__modal__close-btn" @click="closeModal()">
          <img src="@/assets/icons/icon-close-white.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service.js";
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: "ContactCug",
  mixins: [validationMixin],
  data() {
    return {
      nameAndSurname: "",
      rules: {
        required: value => !!value || "Required.",
        email: value => /.+@.+\..+/.test(value) || "E-mail must be valid."
      },
      email: null,
      telephone: null,
      subject: null,
      message: null,
      modal: false,
    };
  },
  validations() {
    const valObj = {
        nameAndSurname: { required },
        email: { required, email },
        subject: { required},
        message: { required },
    };
    return valObj;
  },
  methods: {
    sendEmail() {
      if(!this.$v.$invalid){
        let contact = {
        name: this.nameAndSurname,
        email: this.email,
        phone: this.telephone,
        subject: this.subject,
        message: this.message
      }
      userService.newContactMessage(contact)
        .then(() => {
          this.modal= true;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    closeModal() {
      this.modal = false;
      //refresh page
      this.$router.go();
    },
    resetContactValues() {
      //set contact values to null
      this.nameAndSurname = null;
      this.email = null;
      this.telephone = null;
      this.subject = null;
      this.message = null;    
    }
  }
}
</script>

<style lang="scss" scoped>
.page-contact {
  height: var(--app-height);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--app-height) * 0.13);

  @media (min-width: 1024px) {
    padding-top: calc(var(--app-height) * 0.1);
  }

  .page-contact--header {
    max-width: 100vw;
    text-align: center;
    margin-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      max-width: 36.37566138vw;
    }

    &__title {
      font-size: calc(var(--base) * 4.5);
      line-height: calc(var(--base) * 6.25);
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: calc(var(--base) * 0.875);
    }

    &__subtitle-1 {
      font-size: calc(var(--base) * 4.5);
      line-height: calc(var(--base) * 5.25);
      font-weight: 400;
      margin-bottom: calc(var(--base) * 1);
      letter-spacing: 0.025em;
    }

    &__subtitle-2 {
      font-size: calc(var(--base) * 2.5);
      line-height: calc(var(--base) * 5);
      font-weight: 400;
      margin-bottom: 0;
      letter-spacing: 0.025em;
    }
  }

  .page-contact--content {
    width: 100%;
    max-width: 100vw;
    padding: 0 calc(var(--base) * 1.875);

    @media (min-width: 1024px) {
      max-width: 72.61904762vw;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 0.5);
    }

    &__form__buttons {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;

      &__button {
        font-size: calc(var(--base) * 2.5);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: calc(var(--base) * 0.875);
        border-radius: calc(var(--base) * 1.25);
        background: rgb(var(--bodyBg));
        color: rgb(var(--bodyColor));
        border: solid 1px rgb(var(--bodyColor));
        padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
        transition: all ease-out 0.3s;

        span {
          mix-blend-mode: difference;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          background: rgb(var(--bodyColor));
          color: rgb(var(--bodyBg));
        }
      }
    }
  }

  .page-contact__modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: var(--app-height);
    background: rgb(var(--bodyColor), 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__inner {
      position: relative;
      width: 35.58201058vw;
      height: calc(var(--app-height) * 0.2865853659);
      background: rgb(var(--bodyBg));
      border-radius: calc(var(--base) * 1.25);
      border: solid 2px rgb(var(--bodyColor));
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: calc(var(--base) * 3.5);
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      text-align: center;

      &__title {
        font-size: calc(var(--base) * 5);
        line-height: calc(var(--base) * 6.25);
        font-weight: 700;
        margin-bottom: calc(var(--base) * 2);
      }

      &__subtitle {
        font-size: calc(var(--base) * 2.5);
        line-height: calc(var(--base) * 3.25);
        font-weight: 300;
        letter-spacing: 0.025em;
        max-width: 80%;
      }
    }

    &__close-btn {
      position: absolute;
      top: calc(var(--base) * 2);
      right: calc(var(--base) * 2.125);
      background-color: rgb(var(--bodyColor));
      display: flex;
      justify-content: center;
      align-items: center;
      padding: calc(var(--base) * 0.875);
      border-radius: calc(var(--base) * 0.625);
    }
  }
}
</style>