<template>
  <div class="form-step--wrapper">
    <div
      class="form--content form-creator--step-5--content"
    >
      <v-textarea
        v-model="description"
        outlined
        name=""
        :placeholder="this.$t('message.forms.maxCharacters200')"
      ></v-textarea>
    </div>
  </div>
</template>

<script>
export default {
    name: "FormCreatorStep3",
    components: {
       
    },
    data() {
        return {
            description: null,
            isCompany:true,
            fullName: "",
            email: "",
            phone: "",
            company: "",
            fullNameRules: [
                (v) => !!v || this.$t("message.forms.fullNameRequired"),
                (v) => v.length <= 50 || this.$t("message.forms.fullNameMaxLength"),
            ],
            emailRules: [
                (v) => !!v || this.$t("message.forms.emailRequired"),
                (v) => /.+@.+\..+/.test(v) || this.$t("message.forms.emailInvalid"),
            ],
            companyRules: [
                (v) => !!v || this.$t("message.forms.companyRequired"),
                (v) => v.length <= 50 || this.$t("message.forms.companyMaxLength"),
            ],
            pronounsItems: [
                { text: this.$t('message.forms.pronounHer'), value: "she/her" },
                { text: this.$t('message.forms.pronounHim'), value: "he/him" },
                { text: this.$t('message.forms.pronounThey'), value: "they/them" },
            ],
            pronouns: "",
            pronounsRules: [
                (v) => !!v || this.$t("message.forms.pronounsRequired"),
            ],
        };

    },
    watch: {
      description() {
            this.updateData();
        },
    },
    methods: {
    updateData() {
      this.$emit('updateData', this.description);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
    };
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }
  .form--content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin:0 auto;
    @media (min-width: 1024px) {
      max-width: 39.48412698vw;
      align-items: center;
    }
  }
}
</style>