<template>
  <div class="form-step--wrapper">
    <div class="form--content form-institution--step-3--content">
      <!-- vuetify selector of municipality -->
      <v-select v-model="municipality" :items="municipalities"
        :label="$t('message.forms.municipalitySelect')"
        :placeholder="$t('message.forms.municipalitySelect')" :rules="municipalityRules" required
        outlined :dense="this.$vuetify.breakpoint.width < 1024"></v-select>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: "FormCreatorStep3",
  mixins: [validationMixin],
  components: {

  },
  props: {
    // data from parent
    provincia: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      // vuetify selector of municipality
      municipality: null,
      municipalityRules: [
        (v) => !!v || this.$t('message.forms.municipalityRequired'),
      ],
      municipalities: ['municipio 1', 'municipio 2', 'municipio 4', 'municipio 5', 'municipio 6',
        'municipio 7', 'municipio 8', 'municipio 9', 'municipio 10', 'municipio 11', 'municipio 12', 'municipio 13'],
      //get from public/json/municipalities.json
      municipalitiesJson: [],
    };

  },
  
  watch: {
    municipality() {
      this.updateData();
    },
  },
  validations() {
    const valObj = {
      municipality: { required },
    };
    return valObj;
  },

  mounted() {
    //get municipalities from json
    this.getMunicipalities();
    this.updateValid();
  },
  methods: {
    getMunicipalities() {
      fetch('./json/municipios_0.json')
        .then(response => response.json())
        .then(data => {
          this.municipalitiesJson = data;
          this.filterMunicipalities();

        });
    },

    // filter municipalities by province
    filterMunicipalities() {
      const municipalitiesJsonFiltered = this.municipalitiesJson.filter(municipality => {
        return municipality.provincia === this.provincia;
      });
      this.municipalities = municipalitiesJsonFiltered.map(municipality => {
        return municipality.poblacion;
      });
    },
    updateData() {
      this.$emit('updateData', this.municipality);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 3.835978836vw;
    height: 100%;
    padding: 0 calc(var(--base) * 3);

    @media (min-width: 1024px) {
      align-items: center;
      justify-content: center;
      max-width: 39.48412698vw;
      margin: 0 auto;
      gap: calc(var(--base) * 5);
    }
  }
}
</style>