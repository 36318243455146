<template>
  <section class="walkthrough-section">
    <div class="walkthrough-section--inner">
      <div class="walkthrough-section--item first-step">
        <div class="walkthrough-section--item--info">
          <div class="walkthrough-section--item--info__number">01.</div>
          <h5 class="walkthrough-section--item--info__title">
            {{ $t("message.home.walkthrough.1.title") }}
          </h5>
          <div
            class="walkthrough-section--item--info__text"
            v-html="this.$t('message.home.walkthrough.1.description')"
          ></div>
        </div>
        <div class="walkthrough-section--item--content">
          <div
            ref="swiper2"
            class="walkthrough-section--item--inner__swiper swiper"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <project-card-movil
                v-for="project in projects"
                :key="project.id"
                :title="project.titleCastellano"
                :titleCat="project.titleValencia"
                :author="
                  project.authors[0].nameCastellano
                    ? project.authors[0].nameCastellano
                    : project.authors[0].nameValencia
                "
                :projectId="project.id"
                :authorCat="
                  project.authors[0].nameValencia
                    ? project.authors[0].nameValencia
                    : project.authors[0].nameCastellano
                "
                :cat="
                  $getProjectCategory(project.type)
                    ? $getProjectCategory(project.type)
                    : $getProjectCategory('SOCIAL')
                "
                :stars="project.starsNumber"
                :likes="project.favoritesNumber"
                :views="project.views"
                :img="
                  project.projectMainImages[0]
                    ? project.projectMainImages[0].imageUrl
                    : project.projectMainImages[0]
                "
                class="swiper-slide"
              ></project-card-movil>
            </div>
            <!-- If we need pagination -->
            <!-- <div class="swiper-pagination"></div> -->

            <!-- If we need navigation buttons -->
            <div class="swiper-navigation-wrapper">
              <div class="swiper-button-custom-prev swiper-button">
                <img src="@/assets/icons/swiper-chevron-left.svg" alt="" />
              </div>
              <div class="swiper-button-custom-next swiper-button">
                <img src="@/assets/icons/swiper-chevron-right.svg" alt="" />
              </div>
            </div>

            <!-- If we need scrollbar
          <div class="swiper-scrollbar"></div> -->
          </div>
        </div>
        <div class="button-centered">
          <button
            class="walkthrough-section--item--button"
            @click="goTo('ArchiveCug')"
          >
            {{ $t("message.home.walkthrough.1.button") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center w-fit gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="walkthrough-section--item second-step">
        <div class="walkthrough-section--item--info">
          <div class="walkthrough-section--item--info__number">02.</div>
          <h5 class="walkthrough-section--item--info__title">
            {{ $t("message.home.walkthrough.2.title") }}
          </h5>
          <div
            class="walkthrough-section--item--info__text"
            v-html="this.$t('message.home.walkthrough.2.description')"
          ></div>
        </div>
        <div class="walkthrough-section--item--content">
          <div class="walkthrough-section--item--content__map">
            <img src="@/assets/images/map-mockup-movil.svg" alt="" />
          </div>
        </div>
        <div class="button-centered">
          <button
            class="walkthrough-section--item--button"
            @click="goTo('MapCug')"
          >
            {{ $t("message.home.walkthrough.2.button") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center w-fit gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="walkthrough-section--item third-step">
        <div class="walkthrough-section--item--info">
          <div class="walkthrough-section--item--info__number">03.</div>
          <h5 class="walkthrough-section--item--info__title">
            {{ $t("message.home.walkthrough.3.title") }}
          </h5>
          <div
            class="walkthrough-section--item--info__text"
            v-html="this.$t('message.home.walkthrough.3.description')"
          ></div>
        </div>
        <div class="button-centered">
          <button
            class="walkthrough-section--item--button"
            @click="goTo('RegisterForm')"
          >
            {{ $t("message.home.walkthrough.3.button1") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <button
            class="guide__buttons--button flex justify-center w-fit items-center gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProjectService from "@/services/project.services";
import Swiper, { Navigation, Pagination } from "swiper";
import ProjectCardMovil from "@/components/Cards/ProjectCardMovil.vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "WalkthroughSectionMovil",
  components: {
    ProjectCardMovil,
  },
  mounted() {
    this.getLastProjects();
  },
  data() {
    return {
      projects: null,
      canDownload: true,
      downloadCooldown: 5000, // 5 segundos de cooldown
      page: 0,
      size: 20,
    };
  },
  methods: {
    getLastProjects() {
      ProjectService.getLastProjects(this.page, this.size)
        .then((response) => {
          this.projects = response.content;
          this.loading = false;
          this.initSwiper1();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goTo(view) {
      this.$router.push({ name: view });
    },
    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper2, {
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 16,
        initialSlide: 2,
        // loop: true,

        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-custom-next",
          prevEl: ".swiper-button-custom-prev",
        },

        // // And if we need scrollbar
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });
    },
    downloadClick() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
    downloadEnglish() {
      window.open(
        "https://politicaterritorial.gva.es/documents/20551182/174929742/4.+GUIA+PERSPECTIVA+DE+GENERO+EN+ACTUACIONES+URBANAS_Ingles.pdf/ebcc94b7-706a-3b53-1611-735459548c9b?t=1675064927039",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.walkthrough-section {
  background-color: rgba(var(--bodyBg), 0.6);
  backdrop-filter: blur(4px);
  .walkthrough-section--item {
    margin-bottom: calc(var(--base) * 5);
    .walkthrough-section--item--info {
      margin-bottom: calc(var(--base) * 4);
      padding: 0 calc(var(--base) * 2);
      &__number {
        font-size: calc(var(--base) * 4);
        line-height: 1;
        font-weight: 600;
        margin-bottom: calc(var(--base) * 1);
      }
      &__title {
        font-size: calc(var(--base) * 3);
        line-height: 1;
        font-weight: 600;
        margin: 0;
        margin-bottom: calc(var(--base) * 2);
      }
      &__text {
        font-size: calc(var(--base) * 2.0625);
        font-weight: 400;
      }
    }
    .walkthrough-section--item--content {
      padding: 0 calc(var(--base) * 2);
    }
  }
}
.walkthrough-section--item.first-step {
  .walkthrough-section--item--content {
    overflow: hidden;
    padding: 0;
    //swiper
    .walkthrough-section--item--inner__swiper {
      margin-bottom: calc(var(--base) * 4);
      padding: 0 calc(var(--base) * 2);
      position: relative;
      padding-bottom: calc(var(--base) * 2);
      .swiper-slide-active {
        transition: all ease-out 0.2s;
      }
      .project-card {
        max-width: calc(var(--base) * 24.5);
        max-height: calc(var(--base) * 33.125);
      }
      .swiper-navigation-wrapper {
        padding: 0 calc(var(--base) * 2);
        width: 100%;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        .swiper-button {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }
}
.walkthrough-section--item.second-step {
  .walkthrough-section--item--content {
    padding: 0 calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 4);
    &__map {
      border-radius: calc(var(--base) * 3.75);
      border: solid 1px rgba(var(--bodyColor), 1);
      box-shadow: -4px -1px 20px 0px rgba(var(--bodyColor), 0.25);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.button-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: calc(var(--base) * 1.25);
  .walkthrough-section--item--button {
    font-size: calc(var(--base) * 3);
    line-height: normal;
    font-weight: 400;
    background-color: rgb(var(--bodyColor));
    color: rgb(var(--bodyBg));
    padding: calc(var(--base) * 1) calc(var(--base) * 2);
    border-radius: calc(var(--base) * 1.25);
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: calc(var(--base) * 1.25);
  }
}
</style>
