import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index';

// CUG Under Construction
import UnderConstruction from '../views/UnderConstruction.vue';
// Cug Home
import HomeCug from '../views/HomeCug.vue';
// Cug Archive
import ArchiveCug from '../views/ArchiveCug.vue';
// Cug About
import AboutCug from '../views/AboutCug.vue';
// Cug Map
import MapCug from '../views/MapCug.vue';
// Cug Contact
import ContactCug from '../views/ContactCug.vue';
// Cug Project View
import ProjectSingleCug from '../views/ProjectSingleCug.vue';
// Cug Form page test
import LoginCug from '../views/LoginCug.vue';
// Cug Register Form
import RegisterPage from '../views/RegisterCug.vue'
// Cug Confirm Password
import ConfirmPassword from '../views/ConfirmPassword.vue'
// Cug Confirm New Password
import ConfirmNewPassword from '../views/ConfirmNewPassword.vue'
// Cug Confirm Password Greetings
import ConfirmPasswordGreetings from '../views/ConfirmPasswordGreetings.vue'
// Cug Confirm New Password Greetings
import ConfirmNewPasswordGreetings from '../views/ConfirmNewPasswordGreetings.vue'
// Cug Recover Password
import RecoverPassword from '../views/RecoverPassword.vue'
// Cug Recover Password Greetings
import RecoverPasswordGreetings from '../views/RecoverPasswordGreetings.vue'
// Cug Form Creator Step 1
import FormCreatorStep1 from '@/components/Forms/Creator/FormCreatorStep1.vue';
// Cug UserPanel - Inicio
import UserPanelInicio from '../views/UserPanel/PanelInicio.vue';
// Cug UserPanel - Proyectos
import UserPanelProyectos from '../views/UserPanel/PanelProyectos.vue';
// Cug AdminPanel - Proyectos
import AdminPanelProyectos from '../views/UserPanel/PanelProyectosAdmin.vue';
// Cug UserPanel - Mensajes
import UserPanelMensajes from '../views/UserPanel/PanelMensajes.vue';
// Cug UserPanel - Insights
import UserPanelInsights from '../views/UserPanel/PanelInsights.vue';
// Cug UserPanel - Users
import UserPanelUsers from '../views/UserPanel/PanelUsuariosAdmin.vue';

// Cug Upload Edit
import EditCug from '../views/EditCug.vue';
import EditAdminCug from '../views/EditAdminCug.vue';
import UploadCug from '../views/UploadCug.vue';

// CUG Back
//import MapView from '../views/_CUG_back/MapView.vue';

Vue.use(VueRouter);

const routes = [

  // Cug UnderConstruction
  {
    path: '/en-proces',
    name: 'under-construction',
    label: 'web en procés...',
    component: UnderConstruction,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug UnderConstruction

  // Cug Home
  {
    path: '/',
    name: 'HomeCug',
    label: 'Inicio',
    component: HomeCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },
  // END Cug Home

  // Cug Archive
  {
    path: '/arxiu',
    name: 'ArchiveCug',
    label: 'Archivo',
    component: ArchiveCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },

  {
    path: '/arxiu/:id/:slug',
    name: 'ArchiveCugFiltered',
    label: 'Archivo',
    component: ArchiveCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },
  // END Cug Archive

  // Cug About
  {
    path: '/cug',
    name: 'AboutCug',
    label: 'About',
    component: AboutCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },
  // END Cug About

  // Cug Map
  {
    path: '/geolocalitzacio',
    name: 'MapCug',
    label: 'Geolocalització',
    component: MapCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug Map

  // Cug Contact
  {
    path: '/contacte',
    name: 'ContactCug',
    label: 'Contact Cug',
    component: ContactCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },

  {
    path: '/geolocalitzacio/:id/:slug',
    name: 'MapCugProject',
    label: 'Geolocalització',
    component: MapCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: true,
      langSelector: true,
    },
  },
  // END Cug Map

   // Cug Project View Sample
   {
    path: '/project-single',
    name: 'ProjectSingleTemplate',
    label: 'Project Single',
    component: ProjectSingleCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },

  {
    path: '/projecte/:id/:slug',
    name: 'ProjectSingle',
    label: 'Projecte',
    component: ProjectSingleCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug Project View Sample

   // Cug Login page
   {
    path: '/login',
    name: 'LoginCug',
    label: 'Login Cug',
    component: LoginCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug Form page sample

   // Cug Register Page
   {
    path: '/register',
    name: 'RegisterForm',
    label: 'Register ',
    component: RegisterPage,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug Form page sample

   // Cug Register Page
   {
    path: '/register-step-1',
    name: 'RegisterFormStep1',
    label: 'Register ',
    component: FormCreatorStep1,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: true,
    },
  },
  // END Cug Register Page

  // Cug Validation Page
    {
      path: '/validacion',
      name: 'ConfirmPassword',
      label: 'Confirm Password ',
      component: ConfirmPassword,
      meta: {
        auth: false,
        children: [],
        parent: null,
        footer: false,
        langSelector: true,
      },
    },

    {
      path: '/reset',
      name: 'ConfirmNewPassword',
      label: 'Confirm New Password ',
      component: ConfirmNewPassword,
      meta: {
        auth: false,
        children: [],
        parent: null,
        footer: false,
        langSelector: true,
      },
    },
    // END Cug Validation Page

    // Cug Validation Page
    {
      path: '/recover',
      name: 'RecoverPassword',
      label: 'Recover Password ',
      component: RecoverPassword,
      meta: {
        auth: false,
        children: [],
        parent: null,
        footer: false,
        langSelector: true,
      },
    },
    // END Cug Validation Page

   // Cug user panel-Inicio
   {
    path: '/user-panel-inicio',
    name: 'UserPanelInicio',
    label: 'User Panel Inicio',
    component: UserPanelInicio,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Cug user panel-Inicio

  // Cug user panel-Proyectos
  {
    path: '/user-panel-proyectos',
    name: 'UserPanelProyectos',
    label: 'User Panel Proyectos',
    component: UserPanelProyectos,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  {
    path: '/admin-panel-proyectos',
    name: 'AdminPanelProyectos',
    label: 'Admin Panel Proyectos',
    component: AdminPanelProyectos,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Cug user panel-Proyectos

  // Cug user panel-Mensajes
  {
    path: '/user-panel-mensajes',
    name: 'UserPanelMensajes',
    label: 'User Panel Mensajes',
    component: UserPanelMensajes,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Cug user panel-Mensajes

  // Cug user panel-Insights
  {
    path: '/user-panel-insights',
    name: 'UserPanelInsights',
    label: 'User Panel Insights',
    component: UserPanelInsights,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Cug user panel-Insights

  // Cug user panel-Users
  {
    path: '/admin-panel-users',
    name: 'UserPanelUsuarios',
    label: 'User Panel Users',
    component: UserPanelUsers,
    meta: {
      auth: true,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Cug user panel-Users



  // Edit project
  {
    path: '/edit/:id/:slug',
    name: 'EditCug',
    label: 'Edit Cug',
    component: EditCug,
    meta: {
      auth: false,
      children: [],
      parent: null,
      footer: false,
      langSelector: false,
    },
  },
  // END Edit project

 // Edit Admin project
 {
  path: '/edit-admin/:id/:slug',
  name: 'EditAdminCug',
  label: 'Edit Admin Cug',
  component: EditAdminCug,
  meta: {
    auth: false,
    children: [],
    parent: null,
    footer: false,
    langSelector: false,
  },
},
// END Edit Adminproject

// Upload project
{
  path: '/upload',
  name: 'UploadCug',
  label: 'Upload Cug',
  component: UploadCug,
  meta: {
    auth: false,
    children: [],
    parent: null,
    footer: false,
    langSelector: true,
  },
},
// END Upload project

// Confirm Password Greetings
{
  path: '/password-greetings',
  name: 'ConfirmPasswordGreetings',
  label: 'Confirm Password Greetings',
  component: ConfirmPasswordGreetings,
  meta: {
    auth: false,
    children: [],
    parent: null,
    footer: false,
    langSelector: true,
  },
},
// END Confirm Password Greetings
// Confirm Password Changed
{
  path: '/password-changed',
  name: 'ConfirmNewPasswordGreetings',
  label: 'Confirm New Password Greetings',
  component: ConfirmNewPasswordGreetings,
  meta: {
    auth: false,
    children: [],
    parent: null,
    footer: false,
    langSelector: true,
  },
},
// END Confirm Password Changed

// Recover Password greetings
{
  path: '/recover-password-greetings',
  name: 'RecoverPasswordGreetings',
  label: 'Recover Password Greetings',
  component: RecoverPasswordGreetings,
  meta: {
    auth: false,
    children: [],
    parent: null,
    footer: false,
    langSelector: true,
  },
},
// END Recover Password greetings


];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.auth.status.loggedIn) next({ name: 'LoginCug' })
  else {
    //start all routes on scroll top
    window.scrollTo(0, 0);
    next();
  }
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.label ? toRoute.label : 'CUG';
  next();
});

export default router;
