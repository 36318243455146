<template>
  <div class="page page-register">
    <section class="form-step--container">
      <h1 class="form-step--title" v-if="user.type === 1">
        {{ $t(`message.forms.questions.creator.${activeStep}.title`) }}
      </h1>
      <h1 class="form-step--title" v-else>
        {{ $t(`message.forms.questions.institution.${activeStep}.title`) }}
      </h1>
      <form-type-selector
        v-show="activeStep === 1"
        @updateData="updateTypeData"
        @updateValid="updateTypeValid"
      ></form-type-selector>
      <v-form v-if="user.type === 1">
        <v-stepper v-model="activeStep" flat outlined>
          <v-stepper-content step="2" class="p-0 m-0">
            <form-creator-step-2
              @updateData="updateJobData"
              @updateValid="updateJobValid"
              v-if="activeStep === 2"
            >
            </form-creator-step-2>
          </v-stepper-content>
          <v-stepper-content step="3" class="p-0 m-0">
            <form-creator-step-3
              @updateData="updateUserData"
              @updateValid="updateUserValid"
              :isCompany="user.job === 3 ? true : false"
            >
            </form-creator-step-3>
          </v-stepper-content>
          <v-stepper-content step="4" class="p-0 m-0">
            <form-creator-step-4></form-creator-step-4>
          </v-stepper-content>
        </v-stepper>
      </v-form>
      <v-form v-if="user.type === 2">
        <form-institution-step-2
          v-if="activeStep == 2"
          @updateData="updateProvinceData"
          @updateValid="updateProvinceValid"
        ></form-institution-step-2>
        <form-institution-step-3
          v-if="activeStep == 3"
          :provincia="user.province"
          @updateData="updateCityData"
          @updateValid="updateCityValid"
        ></form-institution-step-3>
        <form-institution-step-4
          v-if="activeStep == 4"
          @updateData="updateStep4Data"
          @updateValid="updateStep4Valid"
        ></form-institution-step-4>
        <form-institution-step-5
          v-if="activeStep == 5"
          @updateData="updateStep5Data"
          @updateValid="updateStep5Valid"
        ></form-institution-step-5>
        <form-institution-step-6
          v-if="activeStep == 6"
          @updateData="updateStep6Data"
          @updateValid="updateStep6Valid"
        ></form-institution-step-6>
        <form-institution-step-7
          v-if="activeStep == 7"
          @updateData="updateUserData"
          @updateValid="updateUserValid"
        ></form-institution-step-7>
        <form-institution-step-8
          v-if="activeStep == 8"
        ></form-institution-step-8>
      </v-form>
      <div class="form-nav-buttons" v-if="activeStep < maxSteps">
        <button
          class="form-nav-buttons--button"
          :disabled="isPreviousButtonDisabled"
          @click="previousStep()"
          :class="[isPreviousButtonDisabled ? 'disabled' : '']"
        >
          {{ $t("message.forms.previous") }}
        </button>
        |
        <button
          class="form-nav-buttons--button"
          :disabled="isNextButtonDisabled"
          @click="nextStep()"
          :class="[isNextButtonDisabled ? 'disabled' : '']"
        >
          {{ $t("message.forms.next") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import FormTypeSelector from "@/components/Forms/FormTypeSelector.vue";

import FormCreatorStep2 from "@/components/Forms/Creator/FormCreatorStep2.vue";
import FormCreatorStep3 from "@/components/Forms/Creator/FormCreatorStep3.vue";
import FormCreatorStep4 from "@/components/Forms/Creator/FormCreatorStep4.vue";

import FormInstitutionStep2 from "@/components/Forms/Institution/FormInstitutionStep2.vue";
import FormInstitutionStep3 from "@/components/Forms/Institution/FormInstitutionStep3.vue";
import FormInstitutionStep4 from "@/components/Forms/Institution/FormInstitutionStep4.vue";
import FormInstitutionStep5 from "@/components/Forms/Institution/FormInstitutionStep5-opt.vue";
import FormInstitutionStep6 from "@/components/Forms/Institution/FormInstitutionStep6.vue";
import FormInstitutionStep7 from "@/components/Forms/Institution/FormInstitutionStep7.vue";
import FormInstitutionStep8 from "@/components/Forms/Institution/FormInstitutionStep8.vue";

export default {
  name: "RegisterPage",
  components: {
    FormCreatorStep2,
    FormCreatorStep3,
    FormCreatorStep4,
    FormInstitutionStep2,
    FormInstitutionStep3,
    FormInstitutionStep4,
    FormInstitutionStep5,
    FormInstitutionStep6,
    FormInstitutionStep7,
    FormInstitutionStep8,
    FormTypeSelector,
  },
  data() {
    return {
      activeStep: 1,

      //register

      user: {
        type: null,
        //creator
        job: null,
        name: null,
        email: null,
        companyName: null,
        pronouns: null,
        //institution
        province: null,
        city: null,
        step4: null,
        step5: null,
        step6: null,
      },
      valid: {
        type: false,
        //creator
        job: false,
        userInfo: false,
        //institution
        province: false,
        city: false,
        step4: false,
        step5: false,
        step6: false,
      },
    }
  },
  methods: {
    previousStep() {
      if (this.activeStep > 1) {
        this.activeStep -= 1
      } else {
        this.activeStep = 1
      }
    },
    nextStep() {
      if (this.activeStep >= this.maxSteps) {
        this.activeStep = 1
      } else {
        this.activeStep += 1;
      }
      if (this.activeStep == 4 && this.user.type == 1 || this.activeStep == 8 && this.user.type == 2) {
        this.registerUser();
      }
    },
    doubleStep() {
      if (this.activeStep >= this.maxSteps) {
        this.activeStep = 1
      } else {
        this.activeStep += 2;
      }
    },
    updateTypeData(data) {
      this.user.type = data;
      this.nextStep();
    },
    updateTypeValid(valid) {
      this.valid.type = valid;
    },
    updateJobData(data) {
      this.user.job = data;
      this.nextStep();
    },
    updateJobValid(valid) {
      this.valid.job = valid;
    },
    updateUserData(data) {
      this.user.name = data.name;
      this.user.email = data.email;
      this.user.companyName = data.companyName;
      this.user.pronouns = data.pronouns;
    },
    updateUserValid(valid) {
      this.valid.userInfo = valid;
    },
    updateProvinceData(data) {
      this.user.province = data;
      this.nextStep();
    },
    updateProvinceValid(valid) {
      this.valid.province = valid;
    },
    updateCityData(data) {
      this.user.city = data;
    },
    updateCityValid(valid) {
      this.valid.city = valid;
    },
    updateStep4Data(data) {
      this.user.step4 = data;
      if (data === 'other') {
        this.nextStep();
      } else {
        this.doubleStep();
      }
    },
    updateStep4Valid(valid) {
      this.valid.step4 = valid;
    },
    updateStep5Data(data) {
      this.user.step5 = data;
    },
    updateStep5Valid(valid) {
      this.valid.step5 = valid;
    },
    updateStep6Data(data) {
      this.user.step6 = data;
    },
    updateStep6Valid(valid) {
      this.valid.step6 = valid;
    },
    registerUser() {
      if (this.allValid) {
        userService.userRegister(this.user).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      }
    },
  },
  computed: {
    allValid() {
      if (this.user.type == 1) {
        return !!(!this.valid.type.$invalid
          && !this.valid.job.$invalid && !this.valid.userInfo.$invalid);
      }
      else {
        return !!(!this.valid.type.$invalid && !this.valid.province.$invalid && !this.valid.city.$invalid
          && !this.valid.step4.$invalid && !this.valid.step5.$invalid && !this.valid.step6.$invalid && !this.valid.userInfo.$invalid);
      }
    },
    maxSteps() {
      if (this.user.type === 2) {
        return 8
      } else {
        return 4
      }
    },
    isNextButtonDisabled() {
      if (this.activeStep === 1) {
        return !(this.user.type);
      } else {

        if (this.user.type === 1) {
          //switch case for each step
          switch (this.activeStep) {
            case 1:
              return this.valid.type.$invalid
            case 2:
              return this.valid.job.$invalid
            case 3:
              return this.valid.userInfo.$invalid
            default:
              return false
          }

        } else if(this.user.type === 2) {
          //switch case for each step
          switch (this.activeStep) {
            case 1:
              return this.valid.type.$invalid
            case 2:
              return this.valid.province.$invalid
            case 3:
              return this.valid.city.$invalid
            case 4:
              return this.valid.step4.$invalid
            case 5:
              return this.valid.step5.$invalid
            case 6:
              return this.valid.step6.$invalid
            case 7:
              return this.valid.userInfo.$invalid
            default:
              return false
          }
        }else{
          return false
        }

      }
    },
    isPreviousButtonDisabled() {
      return this.activeStep === 1
    },
  },
}

</script>

<style lang="scss" scoped>
.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.08095952024);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;

  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.2474541752);
  }

  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;
    pointer-events: all;
    cursor: pointer;
    opacity: 1;

    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}

.form-step--title {
  position: absolute;
  top: calc(var(--app-height) * 0.1709145427);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: calc(var(--base) * 3);
  font-weight: 700;
  text-align: center;
  padding: 0 calc(var(--base) * 2);

  // margin-bottom: calc(var(--app-height) * 0.06446776612);
  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2657841141);
    font-size: calc(var(--base) * 4);
    // margin-bottom: calc(var(--app-height) * 0.08350305499);
  }
}

::v-deep .v-stepper {
  position: inherit;
}
</style>
<style lang="scss">
.form--content {
  padding: 0 calc(var(--base) * 2);
  @media (min-width: 1024px) {
    padding: 0;
  }
}
</style>