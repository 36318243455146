import { render, staticRenderFns } from "./UserCard.vue?vue&type=template&id=4738e300&scoped=true"
import script from "./UserCard.vue?vue&type=script&lang=js"
export * from "./UserCard.vue?vue&type=script&lang=js"
import style0 from "./UserCard.vue?vue&type=style&index=0&id=4738e300&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4738e300",
  null
  
)

export default component.exports