<template>
  <router-link
    class="main-logo"
    :to="{name: 'HomeCug'}"
    :class="[this.$vuetify.breakpoint.width < 1024 ? 'open' : '']"
  >
    <div class="main-logo--inner">
      <div class="main-logo--inner__content">
        <div class="main-logo--title">CUG</div>
        <div class="main-logo--description" v-if="lang === 'ca'" v-html="ca.description" />
        <div class="main-logo--description" v-else v-html="es.description" />
      </div>
      <div class="main-logo--inner__tail">
        <svg
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4995 0C15.8249 1.03268 16.3163 2.08602 16.713 2.98676L16.7131 2.98678C16.8995 3.41002 17.082 3.82425 17.2493 4.23466C17.5156 4.88777 17.7574 5.57025 17.8268 6.20512C17.8993 6.86812 17.7949 7.6174 17.2054 8.20684C16.375 9.0373 15.4258 9.26272 14.5752 9.12424C13.8375 9.00416 13.2368 8.62383 12.865 8.27798L0.792969 0H15.4995Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'MainLogo',
  data() {
    return {
      lang: 'ca',
      ca: {
        name: 'CUG',
        description: 'càtedra urbanisme i <span>gènere</span>',
      },
      es: {
        name: 'CUG',
        description: 'cátedra de urbanismo y <span>género</span>',
      },
    };
  },
  methods: {
    homeLink() {
      // add route to home page if page differ from home
      if (this.$route.name !== 'HomeCug') {
        this.$router.push({ name: 'HomeCug', params: { lang: this.lang } });
      }
    },

  },
};
</script>

<style lang="scss">
// * {
//   border: solid 1px red;
// }
.main-logo {
  position: fixed;
  left: calc(var(--base) * 2.25);
  top: calc(var(--base) * 3);
  z-index: 200;
  transform-origin: left center;
  transform: scale(0.8);
  @media (min-width: 768px) {
    position: fixed;
    left: calc(var(--base) * 11);
    top: calc(var(--base) * 8);
    top: calc(var(--app-height) * 0.06517311609);

    transform: scale(1);
  }
  .main-logo--inner {
    position: relative;
    &__content {
      max-width: calc(var(--base) * 9.625);
      max-height: calc(var(--base) * 8.125);
      width: 100%;
      height: 100%;
      background-color: rgb(var(--bodyColor));
      border-radius: calc(var(--base) * 0.5);
      overflow: hidden;
      color: rgb(var(--bodyBg));
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: calc(var(--base) * 2);
      padding: calc(var(--base) * 1.25) calc(var(--base) * 0.9);
      transition: all ease-out 0.5s;
      .main-logo--title {
        font-size: calc(var(--base) * 4.5);
        letter-spacing: -0.03em;
        text-transform: lowercase;
        margin-bottom: calc(var(--base) * 0.6);
        font-weight: bold;
      }
      .main-logo--description {
        font-size: calc(var(--base) * 1.5);
        line-height: 1;
        text-align: calc(var(--base) * 0.625);
        text-align: left;
        margin: 0;
        min-width: calc(var(--base) * 9);
        max-width: calc(var(--base) * 9);
        width: 100%;

        & span {
          display: flex;
          justify-content: flex-end;
          margin-left: auto;
          text-align: right;
          height: 100%;
          width: 100%;
        }
      }
    }
    &__tail {
      position: absolute;
      bottom: -15%;
      right: calc(var(--base) * 0.3125);
      height:calc(var(--base) * 1.25);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        path {
          fill: rgb(var(--bodyColor));
        }
      }
    }
  }
  &:hover,
  &.open {
    .main-logo--inner {
      &__content {
        max-width: calc(var(--base) * 21.38);
      }
    }
  }
}
</style>
