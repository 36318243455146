<template>
  <div class="form-step--wrapper">
    <div class="form--content form-institution--step-4--content">
      <form-icon-selector v-on:click.native="setValue('rrss')"
        :title="this.$t('message.forms.meetAnswer1')"
        icon="icon-selector-redes-sociales.svg"></form-icon-selector>
      <form-icon-selector v-on:click.native="setValue('job')"
        :title="this.$t('message.forms.meetAnswer2')"
        icon="icon-selector-recomendado.svg"></form-icon-selector>
      <form-icon-selector v-on:click.native="setValue('recommendation')"
        :title="this.$t('message.forms.meetAnswer3')"
        icon="icon-selector-trabajo.svg"></form-icon-selector>
      <form-icon-selector v-on:click.native="setValue('other')"
        :title="this.$t('message.forms.meetAnswer4')"
        icon="icon-selector-otros.svg"></form-icon-selector>
    </div>
  </div>
</template>

<script>
import FormIconSelector from "@/components/Forms/Buttons/FormIconSelector.vue";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default {
  name: "FormInstitutionStep4",
  mixins: [validationMixin],
  components: {
    FormIconSelector,
  },
  data() {
    return {
      value: null,
    };
  },
  validations() {
    const valObj = {
      value: { required },
    };
    return valObj;
  },
  methods: {
    setValue(data) {
      this.value = data;
      this.updateData();
    },
    updateData() {
      this.$emit('updateData', this.value);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
  },
  mounted: function () {
    this.updateValid();
  },
};
</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: calc(var(--base) * 1.25);

    @media (min-width: 1024px) {
      align-items: flex-start;
      justify-content: center;
      gap: 5.291005291vw;
    }
  }
}
</style>