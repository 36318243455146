<template>
  <a href="#" class="user-status-card" :style="[
    isEnabled
      ? {
        backgroundColor:
          'rgb(' + this.$getUserColor(this.color).light + ')',
      }
      : {
        backgroundColor:
          'rgb(' + this.$getUserColor(this.color).light + ')',
      },
  ]" @click="toggleConversation($event)">
    <div class="user-status-card--inner">
      <div class="user-status-card--inner__avatar" :style="{
        backgroundColor: 'rgb(' + this.$getUserColor(this.color).dark + ')',
      }">
        <img :src="avatar" alt="" v-if="avatar" />
        <span v-else>{{ getInitials(user) }}</span>
      </div>
      <div class="user-status-card--inner__name">{{ user }}</div>
      <div class="user-status-card--inner__rol" v-if="this.role == 'ROLE_ADMIN'">Rol: Admin</div>
      <div class="user-status-card--inner__rol"  v-if="this.role == 'ROLE_CREATOR'">Rol: Creador</div>
      <div class="user-status-card--inner__rol"  v-if="this.role == 'ROLE_INSTITUTION'">Rol: Institucion</div>
      <div class="user-status-card--inner__checkbox">
        <v-switch v-model="isEnabled" light color="white" inset hide-details
          class="user-status-card--inner__checkbox__input" :style="cssVars"></v-switch>
        <!-- vuetify toggle input -->
      </div>
      <div class="user-status-card--inner__status" v-html="status ? '<strong>Habilitado</strong>' : 'Bloqueado'"></div>
    </div>
  </a>
</template>

<script>

export default {
  props: {
    user: {
      type: String,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
    avatar: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isSelected: false,
      isEnabled: false,
    };
  },
  mounted() {
    this.isEnabled = this.status;
    this.isSelected = this.selected;
  },


  watch: {
    status() {
      this.isSelected = this.status;
    },
  },
  computed: {
    cssVars() {
      return {
        '--color': 'rgb(' + this.$getUserColor(this.color).dark + ')',
      };
    },
  },
  methods: {
    getInitials(value) {
      if (value) {
        return value.match(/\b(\w)/g).join('');
      } else {
        return 'XX';
      }
    },
    toggleConversation() {
      this.isSelected = !this.isSelected;
      this.$emit('toggle', this.id, this.status);
    },
  },

}
</script>

<style lang="scss" scoped>
.user-status-card {
  width: 100%;
  height: calc(var(--base) * 8.5);

  & .user-status-card--inner {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--base) * 1.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 1.375);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

    &__avatar {
      width: calc(var(--base) * 6.125);
      height: calc(var(--base) * 6.125);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      margin-right: auto;
      font-size: calc(var(--base) * 2);
      font-weight: 500;
    }
    &__rol{
      margin-left: auto;
      font-size: calc(var(--base) * 1.5);
      font-weight: 500;
      font-style: italic;
    }

    &__status {
      font-size: calc(var(--base) * 2);
      font-weight: 400;

      strong {
        font-weight: 700;
      }
    }
  }
}
</style>
<style>
.v-input.user-status-card--inner__checkbox__input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--switch.v-input--switch--inset {
  margin-top: 0;
}

.v-input--switch:not(.v-input--switch--flat) .v-input--switch__thumb {
  color: var(--color);
}

.theme--light.v-input--switch .v-input--switch__track {
  color: white;
}
</style>