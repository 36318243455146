<template>
  <button
    class="tipology-card"
    :style="{ 'background-color': 'rgba(' + cat.color + ',0.6)' }"
  >
    <div class="tipology-card--icon">
      <img :src="cat.icon.color" alt="" />
    </div>
    <div class="tipology-card--text">
      <div class="tipology-card--text__number">0{{ cat.number }}</div>
      <h5 class="tipology-card--text__name">
        {{ $t(`message.categories.${cat.id}.title`) }}
      </h5>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    cat: {
      type: Object,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.tipology-card {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
  min-height: calc(var(--base) * 54.88);
  max-height: calc(var(--base) * 54.88);
  backdrop-filter: blur(20px);
  // .tipology-card--icon {
  //   width: 100%;
  //   height: 54%;
  //   aspect-ratio: 1/1;
  //   display:flex;
  //   flex-shrink: 0;
  //   border-radius: 0 0 calc(var(--base) * 700) calc(var(--base) * 700);
  //   padding: calc(var(--base) * 2.25);
  //   &__inner {
  //     width: 100%;
  //     height: 100%;
  //     aspect-ratio: 1/1;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     flex-shrink: 0;
  //     background-color: rgb(var(--bodyBg));
  //     border-radius: calc(var(--base) * 700);
  //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  //     transition: all linear 0.3s;

  //     img {
  //       width: calc(var(--base) * 13.13);
  //     }
  //   }
  // }
  .tipology-card--icon {
    
    margin-top:calc(var(--base) * 2.2);
    width: calc(100% - calc(var(--base) * 2));
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(var(--bodyBg));
    border-radius:calc(var(--base) * 700);
    padding:calc(var(--base) * 5);
    img{
      width:100%;
      height:100%;
      object-fit:contain;
      transition:all ease-out 0.2s;
    }
  }
  &:hover{
    .tipology-card--icon{
     img{
        transform:scale(1.1);
     }
    }
  }
  .tipology-card--text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: calc(var(--base) * 2.63);
    padding: calc(var(--base) * 2.25);
    padding-top: 0;
    &__number {
      width: 100%;
      font-size: calc(var(--base) * 4.5);
      font-weight: 600;
      text-align: center;
      color: rgb(var(--bodyColor));
      margin-bottom: calc(var(--base) * 1.25);
    }
    &__name {
      font-size: calc(var(--base) * 3);
      line-height: calc(var(--base) * 3.8);
      text-align: center;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
</style>
