var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"user-status-card",style:([
  _vm.isEnabled
    ? {
      backgroundColor:
        'rgb(' + this.$getUserColor(this.color).light + ')',
    }
    : {
      backgroundColor:
        'rgb(' + this.$getUserColor(this.color).light + ')',
    },
]),attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleConversation($event)}}},[_c('div',{staticClass:"user-status-card--inner"},[_c('div',{staticClass:"user-status-card--inner__avatar",style:({
      backgroundColor: 'rgb(' + this.$getUserColor(this.color).dark + ')',
    })},[(_vm.avatar)?_c('img',{attrs:{"src":_vm.avatar,"alt":""}}):_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.user)))])]),_c('div',{staticClass:"user-status-card--inner__name"},[_vm._v(_vm._s(_vm.user))]),(this.role == 'ROLE_ADMIN')?_c('div',{staticClass:"user-status-card--inner__rol"},[_vm._v("Rol: Admin")]):_vm._e(),(this.role == 'ROLE_CREATOR')?_c('div',{staticClass:"user-status-card--inner__rol"},[_vm._v("Rol: Creador")]):_vm._e(),(this.role == 'ROLE_INSTITUTION')?_c('div',{staticClass:"user-status-card--inner__rol"},[_vm._v("Rol: Institucion")]):_vm._e(),_c('div',{staticClass:"user-status-card--inner__checkbox"},[_c('v-switch',{staticClass:"user-status-card--inner__checkbox__input",style:(_vm.cssVars),attrs:{"light":"","color":"white","inset":"","hide-details":""},model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1),_c('div',{staticClass:"user-status-card--inner__status",domProps:{"innerHTML":_vm._s(_vm.status ? '<strong>Habilitado</strong>' : 'Bloqueado')}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }