<template>
  <div class="map-standard">
    <div id="map-container"></div>
    <div class="loader-overlay" v-if="loading && count == 0">
      <v-progress-circular
        class="circular-progress"
        size="50"
        width="5"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'NewsMap',
  data() {
    return {
      chartLoaded: false,
      svg: null,
      projection: null,
      zoom: null,
      lastZoom: null,
      features: [],
      count: 0,
      loading: true,
      clickedData: null,
      cancelTokenSource: null,
      width:null,
      height:null,
      mapBox:null,
    };
  },

  mounted() {
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-underscore-dangle */
    this.$nextTick(function () {
      this.onResize();
    });
    this.mapBox = document.querySelector('.map-box');
    this.width = this.mapBox.offsetWidth;
    this.height = this.mapBox.offsetHeight;
    window.addEventListener("resize", this.onResize);

    this.svg = d3
      .select('#map-container')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('cursor', 'grab')
      .on('mousedown', () => {
        this.svg.style('cursor', 'grabbing');
      });

    Promise.all([
      d3.json('/json/cv_comarcas.geojson'),

    ]).then((initialize) => {
      const dataGeo = initialize[0];

      this.projection = d3
        .geoMercator()
        .fitSize([this.width * 1, this.height * 1], dataGeo);

      this.svg
        .selectAll('path')
        .data(dataGeo.features)
        .join('path')
        .attr('fill', 'white')
        .attr('type', 'mapLine')
        .attr('d', d3.geoPath().projection(this.projection))
        .style('stroke', 'black')
        .style('stroke-width', 0.25)
        .style('opacity', 1);

      this.zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on('zoom', (event) => {
          this.lastZoom = event;
          // this.svg.selectAll('path').attr('transform', event.transform);
          this.svg.selectAll('path[type=mapLine]').attr('transform', event.transform);
          this.svg.selectAll('circle').attr('transform', event.transform);
          // select all paths with attribute 'type' = 'triangle' and apply the current transform of the path multiplied by the current transform of the zoom event
          // eslint-disable-next-line func-names
          this.svg.selectAll('path[type=triangle]').attr('transform', function (d) {
            return `${event.transform} ${d3.select(this).attr('initialTransform')}`;
          });
        })
        .on('start', () => {
          this.svg.style('cursor', 'grabbing');
        })
        .on('end', () => {
          this.svg.style('cursor', 'grab');
        });
      this.svg.call(this.zoom);
      this.svg.on('wheel.zoom', null);
      this.chartLoaded = true;
      this.$emit('updateChartLoaded', this.chartLoaded);

      // d3.select(window).on('resize', resize);
    });
  },
  methods: {
    onResize() {
      this.width = this.mapBox.offsetWidth;
      this.height = this.mapBox.offsetHeight;
      this.svg.attr('width', this.width).attr('height', this.height);
    },
    // calculate transform fo path with current transform and event transform
    updateFilter(val) {
      this.filter = val;
      this.updateMap();
    },
    updateData(val) {
      this.data = val;
      this.updateMap();
    },
    zoomIn(val) {
      this.zoom.scaleBy(this.svg.transition().duration(750), val);
    },

    updateMap() {
      if (this.chartLoaded) { 
        d3.selectAll('circle').remove();
        // select all paths with attribute 'type' = 'triangle'
        // eslint-disable-next-line func-names
        d3.selectAll('path').filter(function () {
          return d3.select(this).attr('type') === 'triangle'; // filter by single attribute
        }).remove();

        // Triángulos
        const arc = d3.symbol().type(d3.symbolTriangle);
        this.svg
          .selectAll('myCircles')
          .data(this.data)
          .enter()
          .append('path')
          .attr('id', (d) => d.id)
          .attr('d', arc)
          .attr('type', 'triangle')
          .attr('initialTransform', (d) => `translate(
              ${this.projection([+d.longitude, +d.latitude])[0]},
              ${this.projection([+d.longitude, +d.latitude])[1] - 10}) rotate(180) scale(1.75,1.5)`)
          .attr('fill', (d) => d.color)
          .attr('fill-opacity', 1)
          // round to 2 decimal places
          .attr(
            'transform',
            (d) => `translate(
              ${this.projection([+d.longitude, +d.latitude])[0]},
              ${this.projection([+d.longitude, +d.latitude])[1] - 10}) rotate(180) scale(1.75,1.5)`,
          );

        // Add circles (círculo exterior):
        this.svg
          .selectAll('myCircles')
          .data(this.data)
          .join('circle')
          .attr('id', (d) => d.id)
          .attr('cx', (d) => this.projection([+d.longitude, +d.latitude])[0])
          .attr('cy', (d) => this.projection([+d.longitude, +d.latitude])[1] - 25)
          // .attr('r', (d) => (1200 * d.value ** 5) / 85)
          .attr('r', 15)
          .style('fill', (d) => d.color)
          .style('cursor', 'pointer')
          .attr('stroke-width', 1)
          .attr('fill-opacity', 1)
          .on('mousemove', (d) => {
            this.$emit('selectedItem', d.srcElement.__data__);
            d3.select(d.target).attr('fill-opacity', 1);
          })
          .on('mouseout', (d) => {
            if (this.clickedData) this.$emit('selectedItem', this.clickedData.srcElement.__data__);
            d3.select(d.target).attr('fill-opacity', 1);
          })
          .on('click', (d) => {
            if (this.clickedData) {
              this.$emit('selectedItem', this.clickedData.srcElement.__data__);
              d3.select(d.target).attr('fill-opacity', 1);
              // d3.select(this.clickedData.target).style('stroke', 'transparent');
            }
            this.clickedData = d;
            // d3.select(d.target).style('stroke', '#F4796B').style('stroke-width', 2);
          });

        // Círculo interior
        this.svg
          .selectAll('myCircles')
          .data(this.data)
          .join('circle')
          .attr('id', (d) => d.id)
          .attr('cx', (d) => this.projection([+d.longitude, +d.latitude])[0])
          .attr('cy', (d) => this.projection([+d.longitude, +d.latitude])[1] - 25)
          .attr('r', 9)
          .style('fill', (d) => '#ffffff')
          .style('cursor', 'pointer')
          .attr('stroke-width', 1)
          .attr('fill-opacity', 1);

        // Circulo pequeño (punto exacto)
        this.svg
          .selectAll('myCircles')
          .data(this.data)
          .join('circle')
          .attr('id', (d) => d.id)
          .attr('cx', (d) => this.projection([+d.longitude, +d.latitude])[0])
          .attr('cy', (d) => this.projection([+d.longitude, +d.latitude])[1])
          .attr('r', 1)
          .style('fill', (d) => d.color)
          .style('cursor', 'pointer')
          .attr('stroke-width', 1)
          .attr('fill-opacity', 0);

        if (this.lastZoom) {
          this.svg.selectAll('circle').attr('transform', this.lastZoom.transform);
          // eslint-disable-next-line func-names
          this.svg.selectAll('path[type=triangle]').attr('transform', (d) => this.setTransform(d));
        }

        //select all circles and paths[type=triangle] and reorder the elements by id
        this.svg
          .selectAll('circle, path[type=triangle]')
          .sort((a, b) => d3.ascending(a.id, b.id));

      }
      this.loading = false;
    },
    setTransform(d) {
      return `${this.lastZoom.transform} translate(
              ${this.projection([+d.longitude, +d.latitude])[0]},
              ${this.projection([+d.longitude, +d.latitude])[1] - 12}) rotate(180) scale(2,1.5)`;
    },

  },
};
</script>

<style lang="scss" scoped>
#svg {
  width: 100%;
  height: auto;
}
</style>
<style lang="scss">
.tooltip {
  position: absolute;
  left: 1em;
  bottom: 1em;
  // background-color: red !important;
  border: none;
  border-radius: 9px 1px 9px 1px;
  width: 250px;
}
.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.linear-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.loader-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2000;
}
</style>
