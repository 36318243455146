<template>
  <div class="user-collapsable-card" :class="[isCollapsed ? '' : 'open']">
    <div
      class="user-collapsable-card--inner"
      :style="{
        backgroundColor: 'rgb(' + this.$getUserColor(this.color).light + ')',
      }"
    >
      <a
        href="#"
        class="user-collapsable-card--label"
        @click="toggleCollapsable()"
      >
        <div class="user-collapsable-card--avatar" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.color).dark + ')',
            }">
          <img :src="avatar" alt="" v-if="avatar" />
          <span v-else>{{ getInitials(user) }}</span>
        </div>
        <div class="user-collapsable-card--name">
          {{ trimString(user, 40) }}
        </div>
        <div
          class="user-collapsable-card--icon"
          :class="[isCollapsed ? '' : 'open']"
        >
          <img src="@/assets/icons/icon-chevron-down.svg" alt="" />
        </div>
      </a>
      <div
        class="user-collapsable-card--content"
        :class="[isCollapsed ? '' : 'open']"
        :style="{
          backgroundColor: 'rgb(' + this.$getUserColor(this.color).medium + ')',
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        user: {
            type: String,
            required: true,
        },
        avatar: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: true,
        },

    },
    data() {
        return {
            isCollapsed: true,
        };
    },
    methods: {
        toggleCollapsable() {
            this.isCollapsed = !this.isCollapsed;
        },
        getInitials(name) {
            let initials = name.match(/\b\w/g) || [];
            initials = (
                (initials.shift() || "") + (initials.pop() || "")
            ).toUpperCase();
            return initials;
        },
        trimString(string, length) {
            return string.length > length
                ? string.substring(0, length - 3) + "..."
                : string;
        },
    },

}

</script>

<style lang="scss" scoped>
.user-collapsable-card {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(var(--base) * 12);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &.open {
    max-height: 100%;
  }

  .user-collapsable-card--inner {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    width: 100%;
    height: 100%;
    padding: calc(var(--base) * 1.375);
    border-radius: calc(var(--base) * 1);

    .user-collapsable-card--label {
      display: flex;
      flex-wrap: nowrap;
      gap: calc(var(--base) * 1.375);
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: calc(var(--base) * 9.125);
      max-height: calc(var(--base) * 9.125);

      .user-collapsable-card--avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--base) * 100);
        width: calc(var(--base) * 6.125);
        height: calc(var(--base) * 6.125);
        min-width: calc(var(--base) * 6.125);
        min-height: calc(var(--base) * 6.125);
        max-width: calc(var(--base) * 6.125);
        max-height: calc(var(--base) * 6.125);
        background: orange;
        font-size: calc(var(--base) * 1.375);
        line-height: 1;
        text-align: center;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .user-collapsable-card--name {
      font-weight: 500;
    }

    .user-collapsable-card--icon {
      margin-left: auto;
      transition: all 0.3s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }

    .user-collapsable-card--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: calc(var(--base) * 1);
      height: 100%;
      max-height: 0%;
      transition: all 0.3s ease-in-out;
      padding: calc(var(--base) * 1);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      border-radius: calc(var(--base) * 1);
      &::-webkit-scrollbar {
        display: none;
      }

      &.open {
        transition: all 0.3s ease-in-out;
        max-height: 100%;
      }
    }
  }
}
</style>