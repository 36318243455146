import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    // eslint-disable-next-line no-shadow
    login({ commit }, user) {
      return AuthService.login(user).then(
        // eslint-disable-next-line no-shadow
        (user) => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        },
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },

    recoverPassword({ commit }, email) {
      return AuthService.recoverPassword(email).then(
        () => {
          commit('recoverPasswordSuccess');
          return Promise.resolve();
        },
        (error) => {
          commit('recoverPasswordFailure');
          return Promise.reject(error);
        },
      );
    },

    // eslint-disable-next-line no-shadow
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerFailure');
          return Promise.reject(error);
        },
      );
    },
  },
  getters: {
    hasRole: (state) => (roleList) => {
      if (state.user) {
        const userRole = AuthService.getDecodedJwtToken(state.user).rol;
        return roleList.includes(userRole);
      }
      return false;
    },
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
