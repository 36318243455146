<template>
  <button class="filter-cat-selector-item" @click="toggleCat()">
    <div class="filter-cat-selector--inner">
      <div class="filter-cat-selector--icon">
        <div
          class="filter-cat-selector--icon__index"
          :style="{ backgroundColor: `rgba(${cat.color}, 1)` }"
        >
          {{ cat.number }}
        </div>
        <div
          class="filter-cat-selector--icon__image"
          :style="{ backgroundColor: `rgba(${cat.colorLight}, 1)` }"
          v-if="isSelected"
        >
          <img :src="isRouteUp ? '/../../' + cat.icon.black : cat.icon.black" alt="" />
        </div>
        <div
          class="filter-cat-selector--icon__image"
          :style="{ backgroundColor: `rgba(var(--bodyBg), 1)` }"
          v-else
        >
          <img :src="isRouteUp ? '/../../' + cat.icon.black : cat.icon.black" alt="" />
        </div>
      </div>
      <div
        class="filter-cat-selector--info"
        :style="{ backgroundColor: `rgba(${cat.color}, 1)` }"
        :class="[isSelected ? 'selected' : '']"
      >
        <div class="filter-cat-selector--info__text">
          {{ $t(`message.categories.${cat.id}.title`) }}
        </div>
      </div>
    </div>
  </button>
</template>

<script lang="js">
export default {
  name: 'FilterCatSelectorItem',
  props: {
    cat: {
      type: Object,
      required: true,
    },
    isRouteUp: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    toggleCat() {
      this.isSelected = !this.isSelected;
      this.updateFilter();
    },
    updateFilter() {
      this.$emit('updateFilter', this.cat.key);
    },
    updateIsSelected(val) {
      this.isSelected = val;
    },
  },

}
</script>

<style lang="scss" scoped>
.filter-cat-selector-item {
  min-width: calc(var(--base) * 6.625);
  max-width: calc(var(--base) * 6.625);
  width: 100%;
  height: 100%;
  @media (min-width: 1280px) {
    min-width: calc(var(--base) * 10.375);
    max-width: calc(var(--base) * 10.375);
  }
  .filter-cat-selector--inner {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    min-height: calc(var(--base) * 6.75);
    @media (min-width: 1280px) {
      min-height: calc(var(--base) * 9);
    }
    .filter-cat-selector--icon {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      &__index {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(var(--base) * 2.75);
        height: calc(var(--base) * 2.75);
        border: solid 1px rgba(var(--bodyColor), 1);
        border-radius: calc(var(--base) * 100);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(var(--base) * 1.75);
        font-weight: 400;
        z-index: 10;
        @media (min-width: 1280px) {
          width: calc(var(--base) * 3.875);
          height: calc(var(--base) * 3.875);
          font-size: calc(var(--base) * 2);
        }
      }
      &__image {
        position: absolute;
        left: calc(var(--base) * 0.25);
        top: calc(var(--base) * 0.375);
        width: calc(var(--base) * 6.375);
        height: calc(var(--base) * 6.375);
        border: solid 1px rgba(var(--bodyColor), 1);
        border-radius: calc(var(--base) * 100);
        padding: calc(var(--base) * 1);
        transition: all 0.3s ease;
        background: rgba(var(--bodyBg), 1);
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
        @media (min-width: 1280px) {
          top: 0;
          left: calc(var(--base) * 1.375);
          width: calc(var(--base) * 9);
          height: calc(var(--base) * 9);
          padding: calc(var(--base) * 1.5);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
    .filter-cat-selector--info {
      z-index: 0;
      position: absolute;
      left: calc(var(--base) * 3.5);
      height: auto;
      bottom: 0;
      padding: calc(var(--base) * 0.25) calc(var(--base) * 1.5);
      padding-left: calc(var(--base) * 7);
      border: solid 1px rgba(var(--bodyColor), 1);
      border-radius: calc(var(--base) * 1.25);
      text-align: left;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all ease-out 0.3s;
      max-width: calc(var(--base) * 25.75);
      min-width: calc(var(--base) * 25.75);
      display: none;
      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
      @media (min-width: 1280px) {
        display: block;
      }
    }
  }
  &:hover {
    .filter-cat-selector--info {
      left: calc(var(--base) * 4.5);
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>