<template>
  <div>
    <div class="user-panel" v-if="this.$vuetify.breakpoint.width >= 1024">
      <!-- Inicio de drawbar -->
      <aside
        class="user-panel--drawbar"
        :style="{
          backgroundColor: 'rgb(' + this.$getUserColor(userColor).light + ')',
        }"
      >
        <div class="drawbar--profile">
          <div class="profile--circle">
            <div
              class="profile--circle__avatar"
              :style="{
                backgroundColor:
                  'rgb(' + this.$getUserColor(userColor).dark + ')',
              }"
            >
              <span>{{ userChar }}</span>
            </div>

            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="profile--circle__button"
                  v-on="on"
                  v-bind="attrs"
                >
                  <img src="@/assets/icons/show-more-circle-white.svg" alt="" />
                </button>
              </template>
              <!-- v-card from vuetify with a color picker and accept and cancel buttons -->
              <v-card>
                <v-card-title class="headline">{{
                  $t("message.admin.pickColor")
                }}</v-card-title>
                <v-card-text>
                  <v-color-picker
                    v-model="newColor"
                    flat
                    hide-sliders
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    :swatches="swatches"
                    show-swatches
                  ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialog = false">
                    {{ $t("message.admin.cancel") }}
                  </v-btn>
                  <v-btn text @click="updateUserColor()">
                    {{ $t("message.admin.accept") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <h3 class="profile--name" v-if="user.name">{{ user.name }}</h3>
          <h3 class="profile--name" v-else>Cargando</h3>
          <div class="profile--mail">{{ userEmail }}</div>
          <button class="drawbar--logout-button" @click="logout()">
            <span
              ><img src="@/assets/icons/icon-logout-black.svg" alt=""
            /></span>
            Log Out
          </button>
        </div>
        <nav class="drawbar--menu">
          <ul>
            <li>
              <router-link
                :to="{ name: 'UserPanelInicio' }"
                @click.native="selectedActive = 'home'"
                :class="{ active: selectedActive === 'home' }"
                :style="[
                  selectedActive === 'home'
                    ? {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).dark + ')',
                      }
                    : {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).normal + ')',
                      },
                ]"
                >{{ $t("message.admin.menu.items[0]") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'UserPanelProyectos' }"
                @click.native="selectedActive = 'projects'"
                :class="{ active: selectedActive === 'projects' }"
                :style="[
                  selectedActive === 'projects'
                    ? {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).dark + ')',
                      }
                    : {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).normal + ')',
                      },
                ]"
                >{{ $t("message.admin.menu.items[1]") }}</router-link
              >
            </li>
            <!-- <li>
              <router-link
                :to="{ name: 'UserPanelMensajes' }"
                @click.native="selectedActive = 'messages'"
                :class="{ active: selectedActive === 'messages' }"
                :style="[
                  selectedActive === 'messages'
                    ? {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).dark + ')',
                      }
                    : {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).normal + ')',
                      },
                ]"
                >{{ $t("message.admin.menu.items[2]") }}</router-link
              >
            </li> -->
            <li>
              <router-link
                :to="{ name: 'UserPanelInsights' }"
                @click.native="selectedActive = 'insights'"
                :class="{ active: selectedActive === 'insights' }"
                :style="[
                  selectedActive === 'insights'
                    ? {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).dark + ')',
                      }
                    : {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).normal + ')',
                      },
                ]"
                >{{ $t("message.admin.menu.items[3]") }}</router-link
              >
            </li>
            <li>
              <router-link
                v-if="isSuperAdmin"
                :to="{ name: 'UserPanelUsuarios' }"
                @click.native="selectedActive = 'users'"
                :class="{ active: selectedActive === 'users' }"
                :style="[
                  selectedActive === 'users'
                    ? {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).dark + ')',
                      }
                    : {
                        backgroundColor:
                          'rgb(' + this.$getUserColor(userColor).normal + ')',
                      },
                ]"
                >{{ $t("message.admin.menu.items[4]") }}</router-link
              >
            </li>
          </ul>
        </nav>
        <button
          v-if="isCreator || isSuperAdmin"
          class="drawbar--upload-button"
          :style="{
            backgroundColor: 'rgb(' + this.$getUserColor(userColor).dark + ')',
          }"
          @click="newProject()"
        >
          <span
            ><img src="@/assets/icons/icon-plus-circle-black.svg" alt=""
          /></span>
          {{ $t("message.admin.uploadNewProject") }}
        </button>
        <!-- <button class="drawbar--logout-button" @click="logout()">
          <span><img src="@/assets/icons/icon-logout-black.svg" alt="" /></span>
          Log Out
        </button> -->
      </aside>
      <!-- Fin de drawbar -->

      <!-- Inicio de main panel -->
      <main
        class="user-panel--main"
        :style="{
          backgroundColor: 'rgb(' + this.$getUserColor(userColor).dark + ')',
          gridTemplateRows: rows > 0 ? `repeat(${rows}, 1fr)` : 'none',
        }"
        :class="[this.isMessagePage ? 'no-profile' : '']"
      >
        <slot></slot>
      </main>
      <!-- Fin de main panel -->
    </div>
    <!-- Inicio de Layout Movil -->
    <div
      class="user-panel-movil"
      v-else
      :style="{
        backgroundColor: 'rgb(' + this.$getUserColor(userColor).dark + ')',
      }"
    >
      <div class="user-panel--inner">
        <nav class="user-panel--nav">
          <ul>
            <li>
              <router-link :to="{ name: 'UserPanelInsights' }">
                <img
                  src="@/assets/icons/icon-nav-insights.svg"
                  alt="Insights"
                />
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'UserPanelProyectos' }">
                <img
                  src="@/assets/icons/icon-nav-projects.svg"
                  alt="Projects"
                />
              </router-link>
            </li>
            <li class="upload-button">
              <router-link :to="{ name: 'UserPanelInicio' }">
                <img src="@/assets/icons/icon-nav-home.svg" alt="Home" />
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'UserPanelMensajes' }">
                <img
                  src="@/assets/icons/icon-nav-messages.svg"
                  alt="Messages"
                />
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'UserPanelInicio' }">
                <img
                  src="@/assets/icons/icon-nav-notifications.svg"
                  alt="Notifications"
                />
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="user-panel--profile" v-if="!this.isMessagePage">
          <div class="profile--circle">
            <div
              class="profile--circle__avatar"
              :style="{
                backgroundColor:
                  'rgb(' + this.$getUserColor(userColor).medium + ')',
              }"
            >
              <span>{{ userChar }}</span>
            </div>
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="profile--circle__button"
                  v-on="on"
                  v-bind="attrs"
                >
                  <img src="@/assets/icons/show-more-circle-white.svg" alt="" />
                </button>
              </template>
              <!-- v-card from vuetify with a color picker and accept and cancel buttons -->
              <v-card>
                <v-card-title class="headline">{{
                  $t("message.admin.pickColor")
                }}</v-card-title>
                <v-card-text>
                  <v-color-picker
                    v-model="newColor"
                    flat
                    hide-sliders
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    :swatches="swatches"
                    show-swatches
                  ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialog = false">
                    {{ $t("message.admin.cancel") }}
                  </v-btn>
                  <v-btn text @click="updateUserColor()">
                    {{ $t("message.admin.accept") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <h3 class="profile--name" v-if="user.name">{{ user.name }}</h3>
          <h3 class="profile--name" v-else>Cargando</h3>
          <div class="profile--mail">{{ userEmail }}</div>
          <button class="drawbar--logout-button" @click="logout()">
            <span
              ><img src="@/assets/icons/icon-logout-black.svg" alt=""
            /></span>
            Log Out
          </button>
        </div>

        <main
          class="user-panel--main movil"
          :class="[this.isMessagePage ? 'no-profile' : '']"
        >
          <slot></slot>
        </main>
      </div>
    </div>
    <!-- Fin de Layout Movil -->
  </div>
</template>

<script>
import authService from "@/services/auth.service";
import userService from "@/services/user.service";

export default {
  props: {
    active: {
      type: String,
      default: "home",
    },
    rows: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data() {
    return {
      isSuperAdmin: this.$store.getters["auth/hasRole"](["ROLE_ADMIN"]),
      isCreator: this.$store.getters["auth/hasRole"](["ROLE_CREATOR"]),
      isInstitution: this.$store.getters["auth/hasRole"](["ROLE_INSTITUTION"]),
      isAdmin: true,
      selectedActive: "home",
      selectedColor: "purple",
      newColor: null,
      dialog: false,
      swatches: userService.getSwatches(),
    };
  },
  mounted() {
    this.selectedActive = this.active;
    this.$store.dispatch("users/getLoggedUser");
  },
  computed: {
    isMessagePage() {
      return this.$route.name === "UserPanelMensajes";
    },

    userChar() {
      if (this.user && this.user.name) {
        //if user.name has a space, split it and get the first letter of each word
        if (this.user.name.includes(" ")) {
          const name = this.user.name.split(" ");
          return name[0].charAt(0) + name[1].charAt(0);
        }
        //if user.name has no space, get the two first letters
        return this.user.name.substring(0, 2);
      }
      return "XX";
    },

    userEmail() {
      if (authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        const email = authService.getDecodedJwtToken(
          this.$store.state.auth.user
        ).sub;
        return this.trimString(email, 23);
      }
      return "No disponible";
    },

    userColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return "default";
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
  },
  methods: {
    updateUserColor() {
      userService.updateUserColor(this.newColor.hex).then(() => {
        this.dialog = false;
        //update view
        this.$store.dispatch("users/getLoggedUser");
      });
    },
    // a method that trims the string to a certain length
    trimString(string, length) {
      return string.length > length
        ? string.substring(0, length - 3) + "..."
        : string;
    },

    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "LoginCug" });
    },

    newProject() {
      this.$router.push({ name: "UploadCug" });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel {
  width: 100%;
  height: calc(var(--app-height) * 0.7454175153);
  max-width: 88.16137566vw;
  border-radius: calc(var(--base) * 4);
  position: absolute;
  top: calc(var(--app-height) * 0.1812627291);
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.25);

  .user-panel--drawbar {
    width: 16.8792198%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all ease-out 0.2s;

    .drawbar--profile {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: calc(var(--base) * 2);
      padding-right: calc(var(--base) * 1);
      padding-top: calc(var(--app-height) * 0.03906313646);
      padding-bottom: calc(var(--app-height) * 0.01906313646);

      .profile--circle {
        position: relative;
        width: calc(var(--base) * 6);
        height: calc(var(--base) * 6);
        margin-bottom: calc(var(--app-height) * 0.03073319756);

        &__avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: calc(var(--base) * 2);
          box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.18);
          transition: all ease-out 0.2s;
        }

        &__button {
          position: absolute;
          width: calc(var(--base) * 3);
          height: calc(var(--base) * 3);
          border-radius: 50%;
          overflow: hidden;
          right: calc(var(--base) * -1);
          bottom: calc(var(--base) * -1);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }

      .profile--name {
        width: 100%;
        margin: 0;
        font-size: calc(var(--base) * 2);
        font-weight: 800;
      }

      .profile--mail {
        font-size: calc(var(--base) * 1.75);
        font-weight: 400;
        overflow: hidden;
      }
    }

    .drawbar--menu {
      display: flex;
      flex-direction: column;
      padding-top: calc(var(--base) * 1);
      margin-bottom: calc(var(--base) * 3);
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 0 calc(var(--base) * 1.25);
        display: flex;
        flex-direction: column;
        gap: var(--base);

        li {
          a {
            font-size: calc(var(--base) * 2.25);
            font-weight: 500;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 100%;
            text-decoration: none;
            height: 100%;
            min-height: calc(var(--app-height) * 0.05397148676);
            max-height: calc(var(--app-height) * 0.05397148676);
            padding: calc(var(--base) * 2.125) calc(var(--base) * 1.75);
            border-radius: calc(var(--base) * 1.875);
            transition: all ease-out 0.2s;

            &.active {
              width: calc(var(--base) * 3.5 + 100%);
              max-width: calc(var(--base) * 3.5 + 100%);
              margin-left: calc(var(--base) * -1.75);
              padding-left: calc(var(--base) * 3.5);
              min-height: calc(var(--app-height) * 0.0600814664);
              max-height: calc(var(--app-height) * 0.0600814664);
              border-radius: calc(var(--base) * 1.875) 0 0
                calc(var(--base) * 1.875);
              font-weight: 700;
            }
          }
        }
      }
    }

    .drawbar--upload-button {
      width: 100%;
      max-width: calc(100% - calc(var(--base) * 3.75));
      margin: 0 auto;
      border-radius: calc(var(--base) * 1.875);
      display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: center;
      gap: calc(var(--base) * 1);
      padding: calc(var(--base) * 1) calc(var(--base) * 1);
      font-size: calc(var(--base) * 1.5);
      line-height: calc(var(--base) * 1.625);
      text-align: center;
      transition: all ease-out 0.2s;
      margin-bottom: calc(var(--base) * 2);

      span {
        display: block;
        width: 100%;
        height: 100%;
        max-width: calc(var(--base) * 5.125);
        max-height: calc(var(--base) * 5.125);
        // margin-bottom: calc(var(--base) * 1.25);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .drawbar--logout-button {
      // position: absolute;
      // bottom: calc(var(--base) * 3);
      // left: calc(var(--base) * 3.125);
      display: flex;
      width: 100%;
      // justify-content: center;
      margin-top: calc(var(--base) * 2);
      align-items: center;
    }
  }

  .user-panel--main {
    width: 100%;
    height: 100%;
    padding: calc(var(--base) * 3) calc(var(--base) * 2);
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    // grid-auto-rows: calc(var(--app-height) * 0.1026422764);

    gap: calc(var(--base) * 2);
    padding: calc(var(--base) * 2) calc(var(--base) * 2);
    padding-right: calc(var(--base) * 2);
    overflow-y: scroll;
    transition: all ease-out 0.2s;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1440px) {
      padding: calc(var(--base) * 3) calc(var(--base) * 2);
      padding-right: calc(var(--base) * 3);
    }
  }
}

// Versión Movil
.user-panel-movil {
  .user-panel--inner {
    .user-panel--nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(var(--base) * 7);
      z-index: 200;
      background-color: rgba(var(--bodyColor), 0.8);
      backdrop-filter: blur(4px);

      ul {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
        list-style: none;
        padding: calc(var(--base) * 1) calc(var(--base) * 3.75);
        gap: 9.866666667vw;
        height: 100%;

        li {
          &:nth-of-type(2) {
            margin-right: auto;
          }
          &:nth-of-type(3) {
            margin-left: auto;
          }
          &.upload-button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: calc(var(--base) * -2.25);
            border: solid 1px rgb(var(--bodyBg));
            border-radius: 50%;
            min-width: calc(var(--base) * 6.5);
            min-height: calc(var(--base) * 6.5);
            max-width: calc(var(--base) * 6.5);
            max-height: calc(var(--base) * 6.5);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(var(--bodyColor), 0.8);
            backdrop-filter: blur(4px);

            a {
              width: calc(var(--base) * 4);
              height: calc(var(--base) * 4);

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          a {
            display: block;
            width: calc(var(--base) * 4);
            height: calc(var(--base) * 4);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .user-panel--profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: calc(var(--app-height) * 0.1304347826);
      margin-bottom: calc(var(--base) * 2);

      .profile--circle {
        position: relative;
        width: calc(var(--base) * 12);
        height: calc(var(--base) * 12);
        margin-bottom: calc(var(--app-height) * 0.03073319756);
        @media (min-width: 1024px) {
          width: calc(var(--base) * 11);
          height: calc(var(--base) * 11);
        }

        &__avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: calc(var(--base) * 4);
          box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.18);
          transition: all ease-out 0.2s;
        }

        &__button {
          position: absolute;
          width: calc(var(--base) * 3);
          height: calc(var(--base) * 3);
          border-radius: 50%;
          overflow: hidden;
          right: 0;
          bottom: 0;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }

      .profile--name {
        width: 100%;
        margin: 0;
        font-size: calc(var(--base) * 2);
        font-weight: 800;
        text-align: center;
      }

      .profile--mail {
        font-size: calc(var(--base) * 1.75);
        font-weight: 400;
        overflow: hidden;
      }
      .drawbar--logout-button {
        display: flex;
        margin-top: calc(var(--base) * 2);
      }
    }
    // .admin-switch--wrapper {
    //   display: flex;
    //   justify-content: center;
    //   margin-bottom: calc(var(--base) * 2);
    //   .user-panel--button {
    //     display: flex;
    //     flex-wrap: nowrap;
    //     align-items: center;
    //     gap: calc(var(--base) * 0.5);
    //     border-radius: calc(var(--base) * 1);
    //     padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
    //     height: 100%;
    //     min-height: calc(var(--base) * 4);
    //     max-height: calc(var(--base) * 4);
    //     button {
    //       display: block;
    //       width: auto;
    //       white-space: nowrap;
    //       font-size: calc(var(--base) * 1.75);
    //       line-height: calc(var(--base) * 3);
    //       &.selected {
    //         font-weight: 600;
    //       }
    //     }
    //   }
    // }
    .user-panel--main.movil {
      position: relative;
      width: 100%;
      height: 100%;
      // min-height: var(--app-height);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: calc(var(--base) * 2);
      overflow: hidden;
      padding: 0 calc(var(--base) * 2);
      padding-bottom: calc(var(--base) * 11);
      padding-top: 0;
      transform: translateX(0%);
      transition: transform 0.3s ease-out;
      &.hide-left {
        transform: translateX(-100%);
      }

      &.no-profile {
        padding-top: calc(var(--app-height) * 0.1304347826);
      }
    }
  }
}
</style>
