import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth.module';
import { user } from '@/store/user.module';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
  },
  modules: {
    auth,
    users: user,
  },
});
