var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.$vuetify.breakpoint.width >= 1024)?_c('div',{staticClass:"user-panel"},[_c('aside',{staticClass:"user-panel--drawbar",style:({
        backgroundColor: 'rgb(' + this.$getUserColor(_vm.userColor).light + ')',
      })},[_c('div',{staticClass:"drawbar--profile"},[_c('div',{staticClass:"profile--circle"},[_c('div',{staticClass:"profile--circle__avatar",style:({
              backgroundColor:
                'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
            })},[_c('span',[_vm._v(_vm._s(_vm.userChar))])]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"profile--circle__button"},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/show-more-circle-white.svg"),"alt":""}})])]}}],null,false,1873935281),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("message.admin.pickColor")))]),_c('v-card-text',[_c('v-color-picker',{attrs:{"flat":"","hide-sliders":"","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","swatches":_vm.swatches,"show-swatches":""},model:{value:(_vm.newColor),callback:function ($$v) {_vm.newColor=$$v},expression:"newColor"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.cancel"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.updateUserColor()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.accept"))+" ")])],1)],1)],1)],1),(_vm.user.name)?_c('h3',{staticClass:"profile--name"},[_vm._v(_vm._s(_vm.user.name))]):_c('h3',{staticClass:"profile--name"},[_vm._v("Cargando")]),_c('div',{staticClass:"profile--mail"},[_vm._v(_vm._s(_vm.userEmail))]),_c('button',{staticClass:"drawbar--logout-button",on:{"click":function($event){return _vm.logout()}}},[_vm._m(0),_vm._v(" Log Out ")])]),_c('nav',{staticClass:"drawbar--menu"},[_c('ul',[_c('li',[_c('router-link',{class:{ active: _vm.selectedActive === 'home' },style:([
                _vm.selectedActive === 'home'
                  ? {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
                    }
                  : {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).normal + ')',
                    },
              ]),attrs:{"to":{ name: 'UserPanelInicio' }},nativeOn:{"click":function($event){_vm.selectedActive = 'home'}}},[_vm._v(_vm._s(_vm.$t("message.admin.menu.items[0]")))])],1),_c('li',[_c('router-link',{class:{ active: _vm.selectedActive === 'projects' },style:([
                _vm.selectedActive === 'projects'
                  ? {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
                    }
                  : {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).normal + ')',
                    },
              ]),attrs:{"to":{ name: 'UserPanelProyectos' }},nativeOn:{"click":function($event){_vm.selectedActive = 'projects'}}},[_vm._v(_vm._s(_vm.$t("message.admin.menu.items[1]")))])],1),_c('li',[_c('router-link',{class:{ active: _vm.selectedActive === 'insights' },style:([
                _vm.selectedActive === 'insights'
                  ? {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
                    }
                  : {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).normal + ')',
                    },
              ]),attrs:{"to":{ name: 'UserPanelInsights' }},nativeOn:{"click":function($event){_vm.selectedActive = 'insights'}}},[_vm._v(_vm._s(_vm.$t("message.admin.menu.items[3]")))])],1),_c('li',[(_vm.isSuperAdmin)?_c('router-link',{class:{ active: _vm.selectedActive === 'users' },style:([
                _vm.selectedActive === 'users'
                  ? {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
                    }
                  : {
                      backgroundColor:
                        'rgb(' + this.$getUserColor(_vm.userColor).normal + ')',
                    },
              ]),attrs:{"to":{ name: 'UserPanelUsuarios' }},nativeOn:{"click":function($event){_vm.selectedActive = 'users'}}},[_vm._v(_vm._s(_vm.$t("message.admin.menu.items[4]")))]):_vm._e()],1)])]),(_vm.isCreator || _vm.isSuperAdmin)?_c('button',{staticClass:"drawbar--upload-button",style:({
          backgroundColor: 'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
        }),on:{"click":function($event){return _vm.newProject()}}},[_vm._m(1),_vm._v(" "+_vm._s(_vm.$t("message.admin.uploadNewProject"))+" ")]):_vm._e()]),_c('main',{staticClass:"user-panel--main",class:[this.isMessagePage ? 'no-profile' : ''],style:({
        backgroundColor: 'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
        gridTemplateRows: _vm.rows > 0 ? `repeat(${_vm.rows}, 1fr)` : 'none',
      })},[_vm._t("default")],2)]):_c('div',{staticClass:"user-panel-movil",style:({
      backgroundColor: 'rgb(' + this.$getUserColor(_vm.userColor).dark + ')',
    })},[_c('div',{staticClass:"user-panel--inner"},[_c('nav',{staticClass:"user-panel--nav"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'UserPanelInsights' }}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-nav-insights.svg"),"alt":"Insights"}})])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'UserPanelProyectos' }}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-nav-projects.svg"),"alt":"Projects"}})])],1),_c('li',{staticClass:"upload-button"},[_c('router-link',{attrs:{"to":{ name: 'UserPanelInicio' }}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-nav-home.svg"),"alt":"Home"}})])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'UserPanelMensajes' }}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-nav-messages.svg"),"alt":"Messages"}})])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'UserPanelInicio' }}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-nav-notifications.svg"),"alt":"Notifications"}})])],1)])]),(!this.isMessagePage)?_c('div',{staticClass:"user-panel--profile"},[_c('div',{staticClass:"profile--circle"},[_c('div',{staticClass:"profile--circle__avatar",style:({
              backgroundColor:
                'rgb(' + this.$getUserColor(_vm.userColor).medium + ')',
            })},[_c('span',[_vm._v(_vm._s(_vm.userChar))])]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"profile--circle__button"},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/show-more-circle-white.svg"),"alt":""}})])]}}],null,false,1873935281),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("message.admin.pickColor")))]),_c('v-card-text',[_c('v-color-picker',{attrs:{"flat":"","hide-sliders":"","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","swatches":_vm.swatches,"show-swatches":""},model:{value:(_vm.newColor),callback:function ($$v) {_vm.newColor=$$v},expression:"newColor"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.cancel"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.updateUserColor()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.accept"))+" ")])],1)],1)],1)],1),(_vm.user.name)?_c('h3',{staticClass:"profile--name"},[_vm._v(_vm._s(_vm.user.name))]):_c('h3',{staticClass:"profile--name"},[_vm._v("Cargando")]),_c('div',{staticClass:"profile--mail"},[_vm._v(_vm._s(_vm.userEmail))]),_c('button',{staticClass:"drawbar--logout-button",on:{"click":function($event){return _vm.logout()}}},[_vm._m(2),_vm._v(" Log Out ")])]):_vm._e(),_c('main',{staticClass:"user-panel--main movil",class:[this.isMessagePage ? 'no-profile' : '']},[_vm._t("default")],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/icons/icon-logout-black.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/icons/icon-plus-circle-black.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/icons/icon-logout-black.svg"),"alt":""}})])
}]

export { render, staticRenderFns }