<template>
  <div class="page page-validation">
    <section class="form-step--container">
      <h1 class="form-step--title">
        {{ $t(`message.forms.generatePassword`) }}
      </h1>
        <div class="form-step--wrapper">
          <div class="form--content">
            <!-- Two v-text-fields for password confirmation -->
            <v-text-field v-model="password" :label="$t('message.forms.newPassword')"
              :placeholder="$t('message.forms.newPassword')" :rules="passwordRules" required
              outlined :dense="this.$vuetify.breakpoint.width < 1024"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"></v-text-field>
            <v-text-field v-model="passwordConfirm" :label="$t('message.forms.repeatPassword')"
              :placeholder="$t('message.forms.repeatPassword')" :rules="passwordConfirmRules"
              required outlined :dense="this.$vuetify.breakpoint.width < 1024"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"></v-text-field>
          </div>
        </div>
        <div class="form-nav-buttons">
          <button class="form-nav-buttons--button disabled" :disabled="true">
            {{ $t("message.forms.previous") }}
          </button>
          |
          <button class="form-nav-buttons--button" :disabled="!isNextButtonEnabled" type="submit"
            @click="validateUser" :class="[!isNextButtonEnabled ? 'disabled' : '']">
            {{ $t("message.forms.next") }}
          </button>
        </div>
    </section>
  </div>
</template>
<script>
import userService from '@/services/user.service';
import { validationMixin } from 'vuelidate';
export default {
  name: 'ConfirmPassword',
  mixins: [validationMixin],
  methods: {
    validateUser() {
      if (this.password === this.passwordConfirm && this.password.length >= 8) {
        userService.validateUser(this.password, this.$route.query.cod, this.$route.query.id).then(
          response => {
            console.log(response);
            this.$router.push({ name: 'ConfirmPasswordGreetings' });
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    
  },

  data() {
    return {
      isPreviousButtonEnabled: true,
      password: '',
      passwordConfirm: '',
      showPassword: false,
      passwordRules: [
        v => !!v || this.$t('message.forms.passwordRequired'),
        v => (v && v.length >= 8) || this.$t('message.forms.passwordMinLength'),
        v => (v && v.length <= 20) || this.$t('message.forms.passwordMaxLength'),
        v => (v && /[A-Z]/.test(v)) || this.$t('message.forms.passwordUppercase'),
        v => (v && /[a-z]/.test(v)) || this.$t('message.forms.passwordLowercase'),
        v => (v && /[0-9]/.test(v)) || this.$t('message.forms.passwordNumber'),
        v => (v && /[^A-Za-z0-9]/.test(v)) || this.$t('message.forms.passwordSpecialCharacter'),
      ],
      passwordConfirmRules: [
        v => !!v || this.$t('message.forms.passwordConfirmRequired'),
        v => (v && v.length >= 8) || this.$t('message.forms.passwordMinLength'),
        v => (v && v.length <= 20) || this.$t('message.forms.passwordMaxLength'),
        v => (v && /[A-Z]/.test(v)) || this.$t('message.forms.passwordUppercase'),
        v => (v && /[a-z]/.test(v)) || this.$t('message.forms.passwordLowercase'),
        v => (v && /[0-9]/.test(v)) || this.$t('message.forms.passwordNumber'),
        v => (v && /[^A-Za-z0-9]/.test(v)) || this.$t('message.forms.passwordSpecialCharacter'),
        v => (v && v === this.password) || this.$t('message.forms.passwordConfirmMatch'),
      ],
    }
  },
  computed: {
    
    checkPasswordRulesWithPassword() {
      //check all rules with checkPasswordRules
      for (let i = 0; i < this.passwordRules.length; i++) {
       if(this.passwordRules[i](this.password) != true){
          return false;
       }
      }
      return true;
    },
    checkPasswordConfirmRulesWithPassword() {
      //check all rules with checkPasswordRules
      for (let i = 0; i < this.passwordConfirmRules.length; i++) {
       if(this.passwordConfirmRules[i](this.passwordConfirm) != true){
          return false;
       }
      }
      return true;
    },
    isNextButtonEnabled() {
      //if checkPasswordRulesWithPassword is true and checkPasswordConfirmRulesWithPassword is true
      if(this.checkPasswordRulesWithPassword && this.checkPasswordConfirmRulesWithPassword){
        return true;
      }
      return false;
    },
    
  },

}
</script>

<style lang="scss" scoped>
.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.08095952024);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;

  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.2474541752);
  }

  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}

.form-step--title {
  position: absolute;
  top: calc(var(--app-height) * 0.1709145427);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: calc(var(--base) * 3);
  font-weight: 700;
  text-align: center;
  padding: 0 calc(var(--base) * 2);

  // margin-bottom: calc(var(--app-height) * 0.06446776612);
  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2657841141);
    font-size: calc(var(--base) * 4);
    // margin-bottom: calc(var(--app-height) * 0.08350305499);
  }
}

.form-step--wrapper {
  height: 100%;
  min-height: calc(var(--app-height) * 0.5247376312);
  max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    min-height: calc(var(--app-height) * 0.2871690428);
    max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }
  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 0;
    row-gap: calc(var(--base) * 2);
    .v-input {
      width: 100%;
      max-width: 86.93333333vw;
    }
    @media (min-width: 1024px) {
      .v-input {
        max-width: 39.48412698vw;
      }
    }
  }
}
</style>
