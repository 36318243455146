<template>
  <a
    href="javascript:;"
    class="user-card--admin"
    :class="[isRead ? 'read' : '']"
    :style="[
      isRead
        ? {
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).medium + ')',
          }
        : {
            backgroundColor:
              'rgb(' + this.$getUserColor(this.color).light + ')',
          },
    ]"
    @click="updateConversation()"
  >
    <div class="user-card--inner">
      <div class="user-card--avatars">
        <div class="user-card--avatars__avatar" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.color).dark + ')',
            }">
          <img :src="avatar" alt="" v-if="avatar" />
          <span v-else>{{ getInitials(user) }}</span>
        </div>
        <div class="user-card--avatars__avatar" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.color).dark + ')',
            }">
          <img :src="avatar" alt="" v-if="avatar2" />
          <span v-else>{{ getInitials(user2) }}</span>
        </div>
      </div>
      <div class="user-card--content">
        <div
          class="user-card--content__names"
          v-if="this.$vuetify.breakpoint.width < 1024"
        >
          {{ trimString(user, 15) }} - {{ trimString(user2, 15) }}
        </div>
        <div class="user-card--content__names" v-else>
          {{ trimString(user, 20) }} - {{ trimString(user2, 20) }}
        </div>
        <div class="user-card--content__message">
          {{ trimString(message, 30) }}
        </div>
        <div class="user-card--content__timestamp">
          {{ timeAgo(timestamp) }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    user: {
      type: String,
      required: true,
    },
    user2: {
      type: String,
      required: false,
    },
    avatar: {
      type: String,
      required: false,
    },
    avatar2: {
      type: String,
      required: false,
    },
    timestamp: {
      type: Number,
      required: true,
    },
    read: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isRead: false,

    };
  },
  mounted() {
    this.isRead = this.read;
    console.log(this.color);
  },
  methods: {
    timeAgo(timestamp) {
      let date = new Date(timestamp);
      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.years');
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.months');
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.days');
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.hours');
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.minutes');
      }
      return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.seconds');
    },
    // a method that returns the initials of the user prop
    getInitials(name) {
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    updateConversation() {
      this.isRead = true;
    },
    // a method that trims the string to a certain length
    trimString(string, length) {
      return string.length > length
        ? string.substring(0, length - 3) + ""
        : string;
    },
  },
}

</script>

<style lang="scss" scoped>
.user-card--admin {
  position: relative;
  width: 100%;
  height: calc(var(--base) * 12);
  min-height: calc(var(--base) * 12);
  border-radius: calc(var(--base) * 1);
  padding: calc(var(--base) * 1.375);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .user-card--inner {
    display: flex;
    flex-wrap: nowrap;
    gap: calc(var(--base) * 2);
    width: 100%;
    height: 100%;

    .user-card--avatars {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--base) * 100);
        width: calc(var(--base) * 5);
        height: calc(var(--base) * 5);
        min-width: calc(var(--base) * 5);
        min-height: calc(var(--base) * 5);
        max-width: calc(var(--base) * 5);
        max-height: calc(var(--base) * 5);
        background: orange;
        font-size: calc(var(--base) * 1.375);
        line-height: 1;
        text-align: center;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:last-of-type{
          margin-top: calc(var(--base) * -2);
        }
      }
    }

    .user-card--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;

      &__name {
        font-size: calc(var(--base) * 2);
        line-height: calc(var(--base) * 3);
        color: rgba(var(--bodyColor), 1);
        font-weight: 500;
        text-align: left;
        margin: 0;
      }

      &__message {
        font-size: calc(var(--base) * 2);
        line-height: calc(var(--base) * 3);
      }

      &__timestamp {
        font-size: calc(var(--base) * 1.75);
        line-height: calc(var(--base) * 2);
        color: rgba(var(--bodyColor), 0.6);
        font-weight: 300;
        text-align: left;
        margin-top: auto;
      }
    }
  }

  &.read .user-card--icon {
    opacity: 0.4;
  }
}
</style>